import { ChangeEventHandler, useState } from 'react';
import { FieldInputProps } from 'react-final-form';
import styles from './OutlinedInput.module.scss';
import { AiOutlineExclamationCircle as ExclamationIcon } from 'react-icons/ai';
import { VscEye as ShowPasswordIcon, VscEyeClosed as HidePasswordIcon } from 'react-icons/vsc';


type Props = {
    value: string;
    onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    placeholder?: string;
    type?: string;
    error?: string;
    name?: string;
    style?: React.CSSProperties,
    disabled?: boolean,
    maxLength?: number;
    [x: string]: any;
}

const OutlinedInput = ({ value, onChange, placeholder, type, error, name, style, disabled = false, maxLength, ...restProps }: Props) => {
    const [showPassword, setShowPassword] = useState(false);


    return (
        <>
            <div className={styles.inputContainer}>
                <div className={`${styles.inputBox} ${error ? styles.error : ''} ${disabled ? styles.disabled : ''}`}>
                    <input {...restProps} maxLength={maxLength} disabled={disabled} style={style} name={name} value={value} type={type == "password" ? showPassword ? "text" : "password" : type} placeholder={placeholder ? placeholder : ""} onChange={onChange} />
                    {type == "password" ? <div className={styles.showPasswordIcon} onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
                    </div> : null}
                    {/* {error ? <div className={styles.errorIcon}><ExclamationIcon /></div> : null} */}
                </div>
                {error ? <span className={styles.errorMessage}>{error}</span> : null}
            </div>
        </>

    );
}

export default OutlinedInput;