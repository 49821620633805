import styles from './steps.module.scss'
import { BsPencil } from "react-icons/bs";
import { RestS } from '../../../rest/rest-service';
import { IPWorkspace } from '../../../interfaces/interfaces';
import { useNavigate } from 'react-router-dom';
import useAnalyticsEventTracker from '../../../components/Layout/useAnalyticsEventTracker';
import { useEffect, useState } from 'react';

const ReviewNSubmit = ({ dataSet, register, getValues, next, back, edit }: any) => {
    const navigate = useNavigate()
    const gaEventTracker = useAnalyticsEventTracker('Workspace step 5');
    const [ submit, setSubmit ] = useState(false)
    const [ style1, setStyle1 ] = useState('none')
    const [ style2, setStyle2 ] = useState('none')
    const [ style3, setStyle3 ] = useState('none')
    const [ style4, setStyle4 ] = useState('none')

    const handleSubmit = async() => {
        localStorage.setItem('submit', 'true')
        setSubmit(true)
    }

    useEffect(()=>{
        if(submit) {
            // console.log("submit", submit)
            let { files, ...workspace } = getValues()
            // console.log(workspace)
            const formData = new FormData()
            for (let i in files) {
                formData.append("attachments", files[i])
            }
            RestS.updateDataSet(dataSet._id, formData)

            workspace = {
                ...workspace,
                dataSet: dataSet._id
            }
            RestS.addIPWorkspace(workspace)
            .then(()=>{
                // console.log("Asdasdgasd")
                gaEventTracker("complete Create workspace - Step5", "step5");
                navigate('/', { state: { showPopup: true } })
            })
        }
    }, [submit])

    return (
        <div className={styles.formContInputData}>
            <div style={{ height: '395px' }}>
                <span className={styles.title}>Review and Submit</span>
                <div className={styles.reviewNSubmitCont}>
                    <div className={styles.reviewInner}>
                        <div className={styles.reviewDataCont}
                            onMouseEnter={e => {
                                setStyle1('flex')
                            }}
                            onMouseLeave={e => {
                                setStyle1('none')
                            }}>
                            <div className={styles.ReviewtitleCont}>
                                <p className={styles.reviewTitle}>Workspace Name</p>
                                <div style={{display: style1}} onClick={() => edit(0)} className={styles.reviewPencilIcon}><BsPencil /></div>
                            </div>
                            <p className={styles.reviewValue}>{getValues().workspaceName}</p>
                        </div>
                        <div className={styles.reviewDataCont}
                            onMouseEnter={e => {
                                setStyle2('flex')
                            }}
                            onMouseLeave={e => {
                                setStyle2('none')
                            }}>
                            <div className={styles.ReviewtitleCont}>
                                <p className={styles.reviewTitle}>Input Data</p>
                                <div style={{display: style2}} onClick={() => edit(1)} className={styles.reviewPencilIcon}><BsPencil /></div>
                            </div>
                            <label>Dataset Name</label>
                            <p className={styles.reviewValue}>{dataSet.dataSetName}</p>
                            <label>Product Name</label>
                            <p className={styles.reviewValue}>{getValues().productName}</p>
                            <label>Frequency of Data Refresh</label>
                            <p className={styles.reviewValue}>{getValues().refreshInterval}{getValues().refreshFrequency != '' ? `, ${getValues().refreshFrequency}` : ''}</p>
                            <label>Uploaded files count</label>
                            <p className={styles.reviewValue}>{getValues().files ? getValues().files.length : 0} {getValues().files?.length == 1 ? 'file' : 'files'} uploaded</p>
                        </div>
                    </div>
                    <div className={styles.reviewInner}
                            onMouseEnter={e => {
                                setStyle3('flex')
                            }}
                            onMouseLeave={e => {
                                setStyle3('none')
                            }}>
                        <div className={styles.ReviewtitleCont}>
                            <p className={styles.reviewTitle}>Customer Context</p>
                            <div style={{display: style3}} onClick={() => edit(2)} className={styles.reviewPencilIcon}><BsPencil /></div>
                        </div>
                        {
                            getValues().customerContext?.map((cust: any) => {
                                return (
                                    <>
                                        <label>{cust.question}</label>
                                        <p className={styles.reviewValue}>{cust.answer}</p>
                                    </>
                                )
                            })
                        }
                    </div>
                    <div className={styles.reviewInner}
                            onMouseEnter={e => {
                                setStyle4('flex')
                            }}
                            onMouseLeave={e => {
                                setStyle4('none')
                            }}>
                        <div className={styles.ReviewtitleCont}>
                            <p className={styles.reviewTitle}>Data Context</p>
                            <div style={{display: style4}} onClick={() => edit(3)} className={styles.reviewPencilIcon}><BsPencil /></div>
                        </div>
                        <label>List metrics that you would like to include in the workspace</label>
                        <div id={styles.dropScroll} className={styles.reviewMetricsOuter}>
                            {
                                getValues().metrics?.map((context: any, index: number) => {
                                    return (
                                        <div style={{ background: 'white', padding: '10px 12px 3px' }} className={styles.displayMetrics}>
                                            <div>
                                                <p>{context.name}</p>
                                                {/* <BsX onClick={()=>{removeMetrics(index)}}/> */}
                                            </div>
                                            <p>{context.formula}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <label>{getValues()?.primaryPurpose.question}</label>
                        <p className={styles.reviewValue}>{getValues()?.primaryPurpose.answer}</p>
                    </div>
                </div>
            </div>
            <div className={styles.buttonContSteps}>

                <p onClick={() => { gaEventTracker("cancel Create workspace - Step5", "step5"); navigate('/') }}>Cancel</p>
                <p id={styles.reviewBtn} onClick={() => { gaEventTracker("back Create workspace - Step5", "step5"); back() }}>Back</p>
                <p className={styles.next} id={styles.reviewBtn} onClick={() => handleSubmit()}>Submit</p>
            </div>
        </div>
    )
}

export default ReviewNSubmit