import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileContext } from "../../context/ProfileContext";
import { BiChevronLeft as BackButton } from 'react-icons/bi'
import styles from './EditProfile.module.scss'
import { Field, Form } from "react-final-form";
import ProfileImage from "../profileImage/ProfileImage";
import OutlinedInput from "../../../../components/common/OutlinedInput/OutlineInput";
import { mobileNumberValidation, reqiredValidation } from "../../../../components/common/functions/formValidation";
import { RestS } from "../../../../rest/rest-service";
import { toast } from "react-toastify";
import useModal from "../../../../components/Hooks/useModal";
import ConfirmDialog from "./../../../../components/common/confirmDialog/ConfirmDialog";
import confirmImage from '../../../../assets/images/ConfirmIcon.svg';
import { CommonError } from "../../../../interfaces/interfaces";
import { FORM_ERROR } from "final-form";

type Message = {
    isSuccess: boolean,
    message: string
}

const EditProfile = () => {
    const { integrationPartner, updateIntegrationPartner } = useContext(ProfileContext);
    const navigate = useNavigate();
    const { toggle: modalToggle, visible: modalVisible } = useModal();
    const [message, setMessage] = useState<Message>({ isSuccess: true, message: null });
    const [loading, setloading] = useState(false);


    const onSubmit = async (values) => {
        // console.log(values);

        let errors = {
            value: false,
            message: {},
        }
        let formData = new FormData();
        let isProfileFile = values['profileImage'] instanceof File
        if (isProfileFile) {
            // console.log("profile pic changed");
            formData.append("profileImage", values['profileImage']);
        }
        // console.log(values['profileImage']);
        const updatePayload = JSON.parse(JSON.stringify({
            name: values['name'],
            contactNumber: values['contactNumber'],
            companyName: values['companyName'],
            removeImage: values['profileImage'] == null && values['profileImage'] !== "" ? true : false,
        }));
        formData.append("data", JSON.stringify(updatePayload));
        setloading(true);
        try {
            const data = await RestS.updateProfile(formData);
            if (data) {
                // toast.success("Profile updated successfully");
                updateIntegrationPartner(data);
                setMessage({ isSuccess: true, message: "Profile updated successfully" })
                modalToggle();
            }
            setloading(false);
        } catch (error) {
            // console.log(error);
            setloading(false);
            const errorData = error.errors as CommonError[]
            if (errorData && errorData.length > 0) {
                const parseError = errorData.reduce((prev, currVal) => {
                    prev[currVal.field] = currVal.message
                    return prev;
                }, {});

                errors = {
                    value: true,
                    message: parseError
                }
            } else {
                errors = {
                    value: true,
                    message: {
                        [FORM_ERROR]: "Something went wrong"
                    }
                }
            }
        }
        if (errors.value == true) {
            // console.log(errors);
            return errors.message
        }
    }

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.backButton} onClick={() => navigate('/profile')}>
                    <BackButton />
                </div>
                <div className={styles.title}>
                    Back to View Profile
                </div>
            </div>
            <Form initialValues={integrationPartner} onSubmit={onSubmit} render={({ handleSubmit, pristine, hasValidationErrors, submitError }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <div className={styles.formSection}>
                            <Field name="profileImage">
                                {props => {
                                    // return <div className={styles.profileImageContainer}>
                                    return <ProfileImage edit={true} profileImage={props.input.value} fallback={integrationPartner.name} onChange={props.input.onChange} />
                                    // </div>
                                }}
                            </Field>

                            <div className={styles.fieldSection}>
                                <Field name="name" validate={(values) => reqiredValidation(values, "Name")}>
                                    {props => {
                                        return <OutlinedInput {...props.input} maxLength={25} style={{ fontSize: "1.3rem" }}
                                            error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null}
                                            value={props.input.value}
                                            onChange={props.input.onChange}
                                            placeholder="Name"
                                        />
                                    }}
                                </Field>

                                <Field name="companyName" validate={(values) => reqiredValidation(values, "Company Name")}>
                                    {props => {
                                        return <OutlinedInput {...props.input} maxLength={25} style={{ fontSize: "1.3rem" }}
                                            error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null}
                                            value={props.input.value}
                                            onChange={props.input.onChange}
                                            placeholder="Name"
                                        />
                                    }}
                                </Field>
                                <Field name="email">
                                    {props => {
                                        return <OutlinedInput
                                            {...props.input}
                                            disabled={true}
                                            style={{ fontSize: "1.3rem" }}
                                            error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null}
                                            type='email' value={props.input.value}
                                            onChange={props.input.onChange}
                                            placeholder="Email"
                                        />
                                    }}
                                </Field>
                                <Field name="contactNumber" validate={mobileNumberValidation}>
                                    {props => {
                                        return <OutlinedInput
                                            {...props.input}
                                            maxLength={10}
                                            style={{ fontSize: "1.3rem" }}
                                            error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null}
                                            value={props.input.value}
                                            onChange={props.input.onChange}
                                            placeholder="Contact Number"
                                        />
                                    }}
                                </Field>
                            </div>
                            {submitError && <div className={styles.formError}>
                                {submitError}
                            </div>}

                            {/* <div className={styles.btnContainer}> */}
                            <button
                                type="submit"
                                disabled={pristine || loading || hasValidationErrors}
                                className={`${styles.filled} ${loading ? styles.loading : ''}`} onClick={handleSubmit}>
                                <div className={styles.buttonText}>
                                    Save
                                </div>
                            </button>
                            {/* </div> */}
                        </div>
                    </form>
                )
            }} />

            <ConfirmDialog bodyStyle={{ padding: "2rem 0" }} showButton={false} visible={modalVisible} toggle={() => {
                modalToggle();
                navigate('/profile');
            }} title="Profile updated Successfully">

                <div className={`confirmIcon rounded-circle`}>
                    <img src={confirmImage} />
                </div>

            </ConfirmDialog>
        </div>

    );

}

export default EditProfile;