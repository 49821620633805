import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import { ScatterChart } from '../../interfaces/interfaces';
import { Colors } from "./_constants";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

export const options = {
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        padding: 0,
      }
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        padding: 0,
      }
    },
  },
};


type Props = {
    chart?: ScatterChart
}

export function ScatterChartWrap({ chart }: Props) {

  const datasets = chart.datasets.map((data, i) => {
    data['backgroundColor'] = Colors[i]
    return data
  })
  const data = {
    datasets: datasets
  };
  

  return <Scatter options={options} data={data} />;
}
