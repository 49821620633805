import { BsPlusSquare } from 'react-icons/bs';
import { FiMinusSquare } from 'react-icons/fi';
import { formatNumber } from './../../../functions/utilities'
import useModal from './../../../Hooks/useModal';

import styles from './PivotBodyRow.module.scss'

function PivotBodyRow({ rowData, level }: any) {
    let hasSubrow = rowData.child.length > 0;

    const { toggle, visible } = useModal(false);
    return (
        <>
            <tr className={`${hasSubrow ? styles.hasSubrow : ''}`}>
                {rowData.parent.map((ele: any, i: any) => (
                    <td key={i}>
                        {i == 0 ?
                            <>
                                <span style={{ paddingLeft: `${level * 10}px` }}>
                                    {hasSubrow ?
                                        (visible ?
                                            <FiMinusSquare className='me-1' onClick={toggle} /> :
                                            <BsPlusSquare className='me-1' onClick={toggle} />) :
                                        <></>}
                                    {ele}
                                </span>
                            </> :
                            typeof ele == "string" ? ele : formatNumber(ele)}
                    </td>
                ))}
            </tr>
            {(hasSubrow && visible) && rowData.child.map((ele: any, i: any) => <PivotBodyRow key={i} rowData={ele} level={level + 1} />)}
        </>
    )
}

// export default PivotBodyRowOld
export default PivotBodyRow