import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Colors } from "./_constants";
import { PieChart } from "../../interfaces/interfaces";
import { useEffect, useRef } from "react";
import { htmlLegendPlugin } from "./ChartPlugins/HtmlLegendPlugin";
import styles from './pivot.module.scss'

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
    chart: PieChart,
}


function PieChartWrap({ chart }: Props) {
    const legendRef = useRef(null);
    let isPercentageMode: boolean = false
    let totalSum: number = 0
    chart.datasets[0].data.forEach((dp) => {
        totalSum += Number.parseFloat(dp.toString())
    })
    if (totalSum === 1 || (totalSum < 1 && totalSum > 0.99)) {
        isPercentageMode = true
    }
    // let isPercentageMode: boolean = false
    // let totalSum: number = 0
    // chart.points.forEach((dp) => {
    //     totalSum += Number.parseFloat(dp.value.toString())
    // })
    // if (totalSum === 1 || (totalSum < 1 && totalSum > 0.99)) {
    //     isPercentageMode = true
    // }

    // chart.points.forEach((dp) => {
    //     data.labels.push(dp.key)
    //     if (!isPercentageMode) {
    //         data.datasets[0].data.push(Number.parseFloat(dp.value.toString()).toFixed(2))
    //     } else {
    //         data.datasets[0].data.push((Math.round(Number.parseFloat(dp.value.toString()) * 10000) / 100).toString())
    //     }
    // })

    let showDatalabels = chart.label.length < 5;

    const options = {
        responsive: true,
        // maintainAspectRatio: false,
        aspectRatio: 2,
        // devicePixelRatio: 2,        
        plugins: {
            legend: {
                display: false,
                position: showDatalabels ? "right" as const : "right" as const,
                labels: {
                    render: "percentage",
                    boxWidth: 20,
                    usePointStyle: true,
                    pointStyle: "rectRounded",
                    padding: 10,
                },
                maxWidth: 500,
            },
            htmlLegend: {
                // ID of the container to put the legend in
                containerID: legendRef,
                showLegend: true,
            },
            tooltip: {
                callbacks: {
                    label: function (context:any) {
                        let label = '';
                        if (isPercentageMode) {
                            label += (context.raw * 100).toFixed(2);
                            label += " %";
                        } else {
                            label += context.raw;
                        }
                        return label;
                    }
                }
            },
            title: {
                display: false,
                text: "Total Volume across Centers : Bangalore",
                align: 'start' as const,
                color: "#00000080",
                font: {
                    weight: "400" as const,
                    family: "Poppins",
                    size: 15
                }
            },
            datalabels: {
                display: showDatalabels,
                font: {
                    weight: "bold" as const,
                    Size: 14
                },
                color: "#FFFFFF",
                clip: true,
                formatter: (value:any, ctx:any) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map((data:any) => {
                        sum += parseFloat(data);
                    });
                    let percentage = Math.round(value * 100 / sum) + "%";
                    return percentage;
                },

            },


        },

    };

    let datasets = chart.datasets.map((e, index) => {
        // let i = index % 10;
        return {
            ...e,
            borderWidth: 0,
            weight: 2,
            backgroundColor: Colors,
        }
    })

    let data = {
        labels: chart.label,
        datasets: datasets
    };

    // useEffect(() => {
    //     ChartJS.register({
    //         id: "custom_canvas_background_color",
    //         beforeDraw: (chart) => {
    //             const ctx = chart.canvas.getContext("2d");
    //             ctx.save();
    //             ctx.fillStyle = "White";
    //             ctx.fillRect(0, 0, chart.width, chart.height);
    //             ctx.restore();
    //         }
    //     });
    // }, []);

    return <>
        <div className="d-flex" style={{
            maxWidth: "100%", justifyContent: "center", alignItems: "center"
        }}>
            <div className="" style={{ width: "65%" }}>
                <Pie data={data}
                    plugins={[ChartDataLabels, htmlLegendPlugin]}
                    options={options} />
            </div>
            <div 
                className={styles.chartStyle}
                ref={legendRef}
                style={{ marginRight: '3%', width: "35%", maxHeight: "180px", overflow: "auto", paddingRight: "10px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            </div>
        </div>
    </>
}

export default PieChartWrap;