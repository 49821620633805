import { Chart } from "../../interfaces/interfaces"
import BarChartWrap from "../chart/Bar";
import LineChartWrap from "../chart/Line";
import PieChartWrap from "../chart/pie";
import DoughnutChartWrap from "../chart/Doughnut";
import { useEffect } from "react";
import { ScatterChartWrap } from "../chart/Scatter";

type Props = {
    chart: Chart,
    lengendLineStyle?: any,
    hoverLabel?:any,
    dates?:any,
    multi?: boolean
}

function GetChart({ chart, lengendLineStyle = {}, hoverLabel={}, dates= [], multi=false }: Props) {
    // useEffect(()=>{
    //     console.log(chart)
    // }, [])

    let renderChart = () => {
        switch (chart.type) {
            case "bar":
            case "histogram":
            case "col":
            case "col3d":
            case "bar stacked":
            case "bar 100 per stacked":
            case "col stacked":
            case "col 100 per stacked":
                return <BarChartWrap chart={chart} />
            case "doughnut":
                return <DoughnutChartWrap chart={chart} />
            case "pie":
            case "pie3d":
                return <PieChartWrap chart={chart} />
            case "line":
            case "line with markers":
            case "line 100 per stacked":
            case "line stacked with markers":
            case "line stacked":
                return <LineChartWrap multi={multi} dates={dates} hoverLabel={hoverLabel} chart={chart} lengendLineStyle={lengendLineStyle} />
            case "scatter":
                return <ScatterChartWrap chart={chart}/>
            default:
                return <></>
        }
    }

    return renderChart();
}

export default GetChart; 