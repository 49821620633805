import { useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import ChartDataPiece from '../../components/DataPeice/chartDataPiece';
import ExpandView from '../../components/ExpandView/ExpandView';
import useModal from '../../components/Hooks/useModal';
import { Chart, IPWorkspace } from '../../interfaces/interfaces';
import { RestS } from '../../rest/rest-service';
import styles from './ReviewPage.module.scss';
import noDataImage from '../../assets/images/noData.svg';
import Loader from '../../components/common/Loader';
import ReportHeader from './ReportHeader/ReportHeader';
import ConfirmDialog from '../../components/common/confirmDialog/ConfirmDialog';
import confirmImage from './../../assets/images/ConfirmIcon.svg';

const ReviewPage = () => {
    const [sideBarCompo, setSideBarCompo, metricsSearchValue]: any = useOutletContext();
    const [charts, setCharts] = useState<Chart[]>()
    const [expandedChartData, setExpandedChartData] = useState<Chart>()
    const [classification, setClassification] = useState('All')
    const [metrics, setMetrics] = useState<string[]>([])
    let { toggle: expandToggle, visible: expandVisible } = useModal();
    const [status, setStatus] = useState<string>('')
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [popupDisplay, setPopupDisplay] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [searchParams] = useSearchParams()
    const [workspace, setWorkspace] = useState<IPWorkspace>()
    const [finalizeBtn, setFinalizeBtn] = useState(false)
    // const handleMetrics = (name) => {
    //     setMetrics((prev) => {
    //         const temp = prev
    //         const index = temp.indexOf(name)
    //         if (index > -1) {
    //             temp.splice(index, 1);
    //             // setSideBarCompo(metricsCompo(temp))
    //             return temp
    //         } else {
    //             // setSideBarCompo(metricsCompo([...prev, name]))
    //             return [...prev, name];
    //         }
    //     })
    // }

    const setChartStatus = (index, value, field) => {
        setCharts(prev => {
            prev[index][field] = value
            // console.log("prec", prev)
            return prev
        })
        let flag = true
        charts?.forEach(chart => {
            if (chart.status == "Accepted")
                flag = flag && true
            else
                flag = flag && false
        })
        setFinalizeBtn(flag)
    }

    // const metricsCompo = (tempMetrics) => {
    //     return ([
    //         <>
    //             <div className={styles.reviewSideBar}>
    //                 <div className={styles.metrics}>
    //                     <h2>Filter By Metrics</h2>
    //                     {/* <input type='text' placeholder="Search Metrics" ref={searchRef} className={styles.metricsSearch} /> */}
    //                     {/* <div className={styles.metricsElement}>
    //                         {
    //                             charts?.length > 0 ?
    //                                 location.state.workspace?.metrics.map(data => {
    //                                     return (
    //                                         <div onClick={() => handleMetrics(data.name)}><div id={tempMetrics.includes(data.name) ? styles.selected : null} className={styles.boxInput}></div><p>{data.name}</p></div>
    //                                     )
    //                                 })
    //                                 : null
    //                         }
    //                     </div> */}
    //                 </div>

    //                 <hr />
    //                 {
    //                     location.state?.workspace ?
    //                         <div className={styles.workspaceDetail}>
    //                             <h2>Workspace:  <b>{location.state.workspace?.workspaceName}</b></h2>
    //                             <h2>Product:  <b>{location.state.workspace?.productName}</b></h2>
    //                             <h2>Refresh Interval:  <b>{location.state.workspace.refreshInterval}</b></h2>
    //                             <h2>Dataset:  <b>{location.state.workspace?.dataSet.dataSetName}</b></h2>
    //                             {/* <h2>Metrics:  <b>
    //                                 {
    //                                     charts?.map((chart, index) => {
    //                                         if (index == 0)
    //                                             return chart.title
    //                                         else
    //                                             return ', ' + chart.title
    //                                     })
    //                                 }
    //                                 {
    //                                     location.state.workspace ? location.state.workspace.metrics.map((metric, index) => {
    //                                         if (index == 0)
    //                                             return metric.name
    //                                         else
    //                                             return ', ' + metric.name
    //                                     }) : null
    //                                 }
    //                             </b></h2> */}
    //                         </div>
    //                         : null
    //                 }
    //             </div>
    //         </>]
    //     )
    // }

    const handleShare = () => {
        if (workspace) workspace.status = "Changes Shared"
        setStatus("Changes Shared")
        setPopupDisplay(true)
        setTimeout(() => {
            setPopupDisplay(false)
        }, 1500)
        const emailBody = charts.map(chart => {
            if (chart.status == 'Rejected')
                return [`<div><b>${chart.title}</b></div><div>Status: Rejected <div><div><b>Reason: </b>${chart.suggestion}</div> <br>`]
        }).filter(chart => {
            return chart != undefined
        })

        // console.log(emailBody)
        RestS.updateProposalCharts(charts)
        RestS.updateIpWorkspace(workspace, emailBody)
    }

    useEffect(() => {
        RestS.getIPWorkspaceById(searchParams.get('id'))
            .then(response => {
                setWorkspace(response)
                const reviewPageCont: any = document.getElementById('pageBody')
                reviewPageCont.style.padding = 0
                setSideBarCompo({ workspace: response, searchValue: searchValue, setSearchValue: setSearchValue, collapsed: true })
                RestS.getProposalCharts(response._id)
                    .then(res => {
                        // console.log(res);
                        setCharts(res);
                        let flag = true
                        res?.forEach(chart => {
                            if (chart.status == "Accepted")
                                flag = flag && true
                            else
                                flag = flag && false
                        })
                        setFinalizeBtn(flag)
                        setLoading(false);
                        setStatus(response.status)
                    }).catch((error) => {
                        // console.log(error);
                        setLoading(false);

                    })
            })

        return (() => {
            const reviewPageCont: any = document.getElementById('pageBody')
            if (reviewPageCont != undefined)
                reviewPageCont.style.padding = "2.2rem 2.2rem 0rem"
        })
    }, [])

    // useEffect(() => {
    //     setSideBarCompo(metricsCompo([]))
    // }, [charts]);

    const getFilteredCharts = () => {
        return charts;
        // return charts.filter(chart => {
        //     return (chart.title?.toLowerCase().includes(metricsSearchValue))
        // })
        // return charts.filter(chart => {
        //     const flagMetrics = metrics.length == 0, flagClass = classification == 'All'
        //     if (flagMetrics && flagClass)
        //         return true
        //     else if (flagClass) {
        //         return metrics.includes(chart.metrics)
        //     } else if (flagMetrics) {
        //         return chart.classification == classification
        //     } else
        //         return (chart.classification == classification && metrics.includes(chart.metrics))
        // })
    }

    const NoChartsContainer = () => {
        return (
            <div className={styles.noDataContainer}>
                <div className={styles.imgContainer}>
                    <img src={noDataImage} />
                </div>
                <div className={styles.textContainer}>
                    <div className={styles.title}>
                        No charts to show
                    </div>


                </div>

            </div>
        )
    }

    const finalizeWs = () => {
        // console.log("finalizing")
        workspace.status = "active"
        setStatus("active")
        RestS.updateIpWorkspace(workspace)
            .then(() => navigate('/'))
    }

    if (!searchParams.get('id')) {
        return <Navigate to='/' />
    }

    return (
        <>
            {
                workspace ?
                    <>
                        <div className={styles.reviewBody}>
                            <ReportHeader workspaceName={workspace.workspaceName} shareChanges={handleShare} finalizeWs={finalizeWs} showActionBtn={workspace.status != "Changes Shared"} status={status} showFinalizeButton={finalizeBtn} />
                            {/* <div className={styles.reviewHeader}>
                            <div className={styles.reviewTabs}>
                                <h1 id={classification == 'All' ? styles.activeTab : ''} onClick={() => setClassification('All')}>All</h1>
                                <h1 id={classification == 'relationship' ? styles.activeTab : ''} onClick={() => setClassification('relationship')}>Relationship</h1>
                                <h1 id={classification == 'comparison' ? styles.activeTab : ''} onClick={() => setClassification('comparison')}>Comparison</h1>
                                <h1 id={classification == 'composition' ? styles.activeTab : ''} onClick={() => setClassification('composition')}>Composition</h1>
                                <h1 id={classification == 'distribution' ? styles.activeTab : ''} onClick={() => setClassification('distribution')}>Distribution</h1>
                            </div>
                        </div> */}
                            <div className={styles.chartsCont}>
                                {
                                    charts?.length > 0 ? getFilteredCharts().length > 0 ? getFilteredCharts().map((chart, index) => {

                                        // console.log(getFilteredCharts().length)
                                        // console.log("-*-*-*-*-*-*-*-*-*")
                                        return (
                                            <ChartDataPiece workspaceStatus={status} setChartStatus={setChartStatus} index={index} chart={chart} setExpandedChartData={setExpandedChartData} toggleView={expandToggle} />
                                        )
                                    }) : NoChartsContainer() : loading ? <Loader color={"#fff"} /> : NoChartsContainer()
                                }
                            </div>
                        </div>
                        {/* <div className={styles.reviewHeader}>
                        <div className={styles.reviewTabs}>
                            <h1 id={classification == 'All' ? styles.activeTab : ''} onClick={() => setClassification('All')}>All</h1>
                            <h1 id={classification == 'Relationship' ? styles.activeTab : ''} onClick={() => setClassification('Relationship')}>Relationship</h1>
                            <h1 id={classification == 'Comparision' ? styles.activeTab : ''} onClick={() => setClassification('Comparision')}>Comparision</h1>
                            <h1 id={classification == 'Composition' ? styles.activeTab : ''} onClick={() => setClassification('Composition')}>Composition</h1>
                            <h1 id={classification == 'Distribution' ? styles.activeTab : ''} onClick={() => setClassification('Distribution')}>Distribution</h1>
                        </div>
                        {
                            !location.state?.showActionBtn || status == "Changes Shared" || status == "active" ?
                                null :
                                <div className={styles.reviewBtns}>
                                    <button onClick={() => { handleShare() }} className={styles.addWsBtn}>Share Changes</button>
                                    <button onClick={() => { finalizeWs() }} className={`${styles.addWsBtn} ${styles.share}`}>Finalize Workspace</button>
                                </div>
                        }
                    </div> */}

                        <ExpandView expandedChartData={expandedChartData} isChartExpand={expandVisible} toggleView={expandToggle} />
                        <ConfirmDialog bodyStyle={{ padding: "2rem 0" }} showButton={false} visible={popupDisplay} toggle={() => {
                            setPopupDisplay(prev => !prev)
                        }} title="Changes Shared" subTitle={<p>We'll update you as soon as your <br />workspace is ready</p>}>

                            <div className={`confirmIcon rounded-circle`}>
                                <img src={confirmImage} />
                            </div>

                        </ConfirmDialog>
                    </>
                    :
                    <Loader />
            }
        </>
    )
}

export default ReviewPage