import styles from './PageNotFound.module.scss'
import image from './../../assets/images/404.svg'
import { useNavigate } from 'react-router'

const PageNotFound = () => {
    const navigate = useNavigate()

    return (
        <div className={styles.cont}>
            <img src={image} />
            <h1>Oh snap! This is awkward</h1>
            <p>You are looking for something that doesn't actually exist</p>
            <button onClick={()=>navigate('/')}>Go to Dashboard</button>
        </div>
    )
}

export default PageNotFound