import React, { useEffect } from 'react';
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
// import Header from './components/layout/Header';
import MainCreateWS from './pages/createWorkspace/MainCreateWS/MainCreateWS';

import LandingPage from './pages/LandingPage/LandingPage';
import SignIn from './pages/SignIn/SignIn';
import SignUp from './pages/SignUp/SignUp';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import ResetPasswordRequest from './pages/ResetPassword/ResetPasswordRequest/ResetPasswordRequest';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ProtectedRoute from './components/Layout/ProtectRoute';
import LayoutIndex from './components/Layout';
import Home from './pages/Home/Home';
import Profile from './pages/profile/Profile';
import ProfileProvider from './pages/profile/context/ProfileContext';
import ProfileView from './pages/profile/components/ProfileView/ProfileView';
import EditProfile from './pages/profile/components/EditProfile/EditProfile';
import ProfilePasswordUpdate from './pages/profile/components/ProfilePassword/ProfilePasswordUpdate';
import IC from './pages/IntegrationComplete/IC';
import DevInProgress from './pages/DevInProgress/DevInProgress';
import ProfileIndex from './pages/profile/ProfileIndex';
import WelcomePage from './pages/Home/WelcomeScreen/WelcomePage';
import ReviewPage from './pages/ReviewPage/ReviewPage';
import ReactGA from 'react-ga';
import ResendVerificationEmail from './pages/ResendVerificationEmail/ResendVerificationEmail';
import PageNotFound from './pages/PageNotFound/PageNotFound';

// const Router = [
//   {
//     path: "/home",
//     component: <div>Home</div>,
//     index: true,
//   },
//   {
//     path: "/Workspace",
//     component: <MainCreateWS />,
//   },
// ]

const TRACKING_ID = `${process.env.REACT_APP_GA_TRACKING_ID}`;

ReactGA.initialize(TRACKING_ID, { gaOptions: { siteSpeedSampleRate: 100 } });

function App() {
  let location = useLocation();
  useEffect(() => {
    ReactGA.pageview('/' + location.pathname.split('/')[1]);
  }, [location]);

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />

      <Routes>
        <Route element={
          <ProtectedRoute />
        }>
          <Route path='/' element={<LayoutIndex />} >
            {/* <Route index element={<Home title='Dashboard' />} />
            <Route path='/devinprogress' element={<DevInProgress />} />
            <Route path='/ic' element={<IC />} /> */}
            <Route path='/review' element={<ReviewPage />} />

            <Route index element={<Home title='Dashboard' />} />
            <Route path='/createWorkspace' element={<WelcomePage title='Create Workspace' />} />
            <Route path='/devinprogress' element={<DevInProgress />} />
            <Route path='/ic' element={<IC />} />
          </Route>
          <Route path='/workspace' element={<LayoutIndex sideBar={false} />} >
            <Route path='' element={<MainCreateWS title='Workspace' />} />
          </Route>
          <Route path='/profile' element={<LayoutIndex sideBar={false} />} >
            <Route path="/profile" element={<ProfileIndex />}>
              <Route index element={<ProfileView />} />
              <Route path='/profile/edit' element={<EditProfile />} />
              <Route path='/profile/password' element={<ProfilePasswordUpdate />} />
            </Route>
          </Route>
          <Route path="*" element={<LayoutIndex sideBar={false} />} >
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Route>
        <Route path='/signin' element={<SignIn title={"Sign In"} />} />
        <Route path='/getstarted' element={<LandingPage title={"Merito"} />} />
        <Route path='/verifyemail' element={<ResendVerificationEmail title={"Verify Email"} />} />
        <Route path="/resetpasswordrequest" element={<ResetPasswordRequest title={"Reset Password"} />} />
        <Route path='/resetpassword/:userId/:token' element={<ResetPassword title={"Reset Password"} />} />
        <Route path="/signup" element={<SignUp title='Sign Up' />} />
        <Route path="/signupByMerito" element={<SignUp title='Sign Up' isByMerito={true} />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div >
  );
}

export default App;
