import axios from "axios";
import {
  clearLocalStoageOnLogout,
  getLocalJwt,
} from "../components/common/functions/utilites";
import { history } from "../Router/History";

let API = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  responseType: "json",
});

API.interceptors.request.use((req) => {
  let token = getLocalJwt();
  if (token) req.headers.Authorization = "Bearer " + token;
  return req;
});

API.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (error.response.status === 401) {
      clearLocalStoageOnLogout();
      history.replace("/signin");
    }
    // console.log(error);
    return Promise.reject(error);
  }
);

export default API;
