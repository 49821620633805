import HeroSection from '../LandingPage/heroSection/HeroSection';
import styles from './ConfirmPage.module.scss';
import { ReactComponent as ConfirmIcon } from '../../assets/images/ConfirmIcon.svg'

type Props = {
    title: string,
    subtitle: React.ReactNode,
}

const ConfirmPage = ({ title, subtitle }: Props) => {


    return (
        // <div className={styles.main}>
        //     <HeroSection />

        <div className={styles.rightPart}>
            <div className={styles.confirmSection}>
                <div className={styles.confirmIcon}>
                    <ConfirmIcon />
                </div>
                <div className={styles.textSection}>
                    <div className={styles.title}>
                        {title}
                    </div>
                    <div className={styles.subtitle}>
                        {subtitle}
                    </div>
                </div>
            </div>

        </div>
        // </div>
    )
}

export default ConfirmPage;