import React, { useContext, useEffect } from "react";
import { RestS } from "../../rest/rest-service";
import { ProfileContext } from "./context/ProfileContext";
import styles from './Profile.module.scss';
import ProfileView from "./components/ProfileView/ProfileView";
import { Outlet } from "react-router-dom";

type Props = {
    title: string
}

const Profile = ({ title }: Props) => {
    const { updateIntegrationPartner, integrationPartner } = useContext(ProfileContext);

    useEffect(() => {
        document.title = title || "Merito";
        (async () => {
            try {
                const data = await RestS.getProfile();
                updateIntegrationPartner(data);
            } catch (error) {
                // console.log(error);
            }
        })();
    }, [])

    return (
        <div className={styles.main}>
            <div className={styles.body}>
                {integrationPartner && <Outlet />}
            </div>
        </div>
    );
}

export default Profile;