import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import HeroSection from '../../LandingPage/heroSection/HeroSection';
import styles from './ResetPassword.module.scss';
import { HiArrowLeft as LeftArrow } from 'react-icons/hi';
import { Form, Field } from 'react-final-form';
import OutlinedInput from '../../../components/common/OutlinedInput/OutlineInput';
import { cutomEmailValidation } from '../../../components/common/functions/formValidation';
import CustomButton from '../../SignUp/buttons/ConfirmButton';
import ConfirmPage from '../../ConfirmPages/ConfirmPage';
import axios from 'axios';
import { toast } from 'react-toastify';
import useAuth from '../../../components/Hooks/useAuth';
import { RestS } from '../../../rest/rest-service';
import useModal from '../../../components/Hooks/useModal';
import { Modal } from 'react-bootstrap';
import MainLayout from '../../LandingPage/MainLayout';
import { CommonError } from '../../../interfaces/interfaces';
import { FORM_ERROR } from 'final-form';

type Props = {
    title: string;
}

const ResetPasswordRequest = ({ title }: Props) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const navigate = useNavigate();
    const { toggle: modalToggle, visible: modalVisible } = useModal();
    const [errorMessage, setErrorMessage] = useState(null);

    const [auth, setAuth] = useState(useAuth());



    useEffect(() => {
        document.title = title || "Merito"
        if (auth) {
            RestS.verifyJwt().then((res) => {
                setAuth(res);
            }).catch(error => {
                // console.log(error);
            });
        }
    }, []);

    const onSubmit = async (values) => {
        let errors = {
            value: false,
            message: {},
        };

        try {
            const data = await axios.post(`${process.env.REACT_APP_API_URL}/api/integrationpartner/resetpasswordrequest`, values).then((res) => res.data);
            if (data) {
                // console.log(data);
                setShowConfirm(true);
            }
        } catch (error) {
            // console.log(error);
            const errorData = error.response?.data?.errors as CommonError[];
            if (errorData && errorData.length > 0) {
                const parseError = errorData.reduce((prev, currVal) => {
                    let curField = currVal.field ? currVal.field : FORM_ERROR;
                    prev[curField] = currVal.message
                    return prev;
                }, {});

                errors = {
                    value: true,
                    message: parseError
                }
            } else {
                errors = {
                    value: true,
                    message: {
                        [FORM_ERROR]: "Something went wrong"
                    }
                }
            }
        }

        if (errors.value == true) {
            // console.log(errors);
            return errors.message
        }
    }

    const RenderSubtitle = () => {
        return (
            <>

                <div>
                    Check your email for the reset link
                </div>

                <div style={{ color: "#596DE7", cursor: "pointer" }} onClick={() => {
                    navigate('/signin')
                }}>
                    Sign in here
                </div>
            </>

        )
    }

    return (
        <>
            {auth ?
                <Navigate to="/" replace />
                :
                <MainLayout>
                    <HeroSection />
                    {showConfirm ?
                        <ConfirmPage title="Reset link is on it's way" subtitle={<RenderSubtitle />} />
                        :

                        <div className={styles.rightPart}>
                            <div className={styles.backArrow} onClick={() => {
                                navigate('/signin')
                            }}>
                                <LeftArrow />
                            </div>
                            <div className={styles.resetPasswordSection}>
                                <div className={styles.title} >Reset Password</div>
                                <Form onSubmit={(values) => onSubmit(values)} render={({ handleSubmit, pristine }) => (
                                    <form onSubmit={handleSubmit}>
                                        <div className={styles.formSection}>
                                            <Field name='email' validate={cutomEmailValidation}>
                                                {props => {
                                                    return <OutlinedInput
                                                        error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null} type='email' value={props.input.value} onChange={props.input.onChange} placeholder="Email Address" />
                                                }}
                                            </Field>

                                            <CustomButton type="submit" disabled={pristine} onClick={handleSubmit} >Reset Password</CustomButton>

                                        </div>

                                    </form>
                                )} />
                            </div>
                        </div>}

                </MainLayout>
            }
        </>

    )
}

export default ResetPasswordRequest;