let getOrCreateLegendList = (chart:any, id:any) => {
  const legendContainer = id.current;
  let listContainer = legendContainer ? legendContainer.querySelector("ul") : null;

  if (!listContainer) {
    listContainer = document.createElement("ul");
    listContainer.style.margin = "0";
    listContainer.style.padding = "0";

    if(legendContainer) legendContainer.appendChild(listContainer);
  }
  return listContainer;
};

let htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate(chart:any, args:any, options:any) {
    if (!options.showLegend) {
      return;
    }
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items from div
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item:any) => {
      const li = document.createElement("li");
      li.style.alignItems = "flex-start";
      li.style.cursor = "default";
      li.style.display = "flex";
      li.style.flexDirection = "row";
      li.style.paddingBottom = "8px";

      // Color box
      const boxSpan = document.createElement("span");
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + "px";
      boxSpan.style.borderRadius = "5px";
      boxSpan.style.display = "inline-block";
      boxSpan.style.height = "15px";
      boxSpan.style.marginRight = "10px";
      boxSpan.style.minWidth = "15px";

      // Text
      const textContainer = document.createElement("span");
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = "0";
      textContainer.style.padding = "0";
      textContainer.style.textOverflow = "ellipsis";
      textContainer.style.overflow = "visible";
      textContainer.style.whiteSpace = "";
      textContainer.style.maxWidth = "100%";
      textContainer.style.textDecoration = item.hidden ? "line-through" : "";
      textContainer.style.font = "Poppins";
      textContainer.style.fontSize = "10px";
      // textContainer.style.li = "#696B6F";
      // textContainer.style.webkitLineClamp = "2";
      // textContainer.style.webkitBoxOrient = "vertical";
      // textContainer.style.display = "-webkit-box";

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};

export { htmlLegendPlugin };
