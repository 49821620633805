import ReactDOM from "react-dom";
import { Button, Modal, ModalBody } from "reactstrap";
import styles from "./ConfirmDialog.module.scss";
import { AiOutlineClose } from 'react-icons/ai';


function DialogBody({ visible, toggle, activeBtnClick, bodyStyle = {}, showButton = true, ...props }) {
    return <div className={styles.ModalDialog}>
        <Modal
            toggle={toggle}
            isOpen={visible}
            size="sm"
            centered
            modalClassName={styles.confirmBox}
            wrapClassName={styles.confirmBox}
            contentClassName={styles.contentBox}
            backdrop="static"
        >
            <ModalBody className={styles.body}>
                <div className={styles.closeButton} onClick={() => toggle()}>
                    <AiOutlineClose />
                </div>
                <div style={bodyStyle}>
                    {props.children}

                    <div className={`${styles.title} ${props.error ? 'error' : ''}`}>
                        {props.title}
                    </div>
                    <div className={`${styles.subTitle} ${props.error ? 'error' : ''}`}>
                        {props.subTitle}
                    </div>
                    {showButton && <div className={styles.buttonBlock}>
                        <Button className={`${styles.button}`} onClick={(e) => {
                            toggle()
                        }} >Cancel</Button>
                        <Button className={`${styles.button} ${styles.btnOk}`} onClick={(e) => {
                            activeBtnClick();
                            toggle()
                        }} >{props.activeBtn ? props.activeBtn : "Yes"}</Button>
                    </div>}
                </div>

            </ModalBody>
        </Modal>
    </div>
}

const ConfirmDialog = (props) => props.visible ? ReactDOM.createPortal(DialogBody(props), document.body) : null;

export default ConfirmDialog;