import { useState } from 'react'
import styles from './ChangePassword.module.scss';
import { BiChevronLeft as BackButton } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import ProfileImage from '../../profileImage/ProfileImage';
import OutlinedInput from '../../../../../components/common/OutlinedInput/OutlineInput';
import { useContext } from 'react';
import { ProfileContext } from '../../../context/ProfileContext';
import { reqiredValidation } from '../../../../../components/common/functions/formValidation';
import { RestS } from '../../../../../rest/rest-service';
import { toast } from 'react-toastify';
import useModal from '../../../../../components/Hooks/useModal';
import ConfirmDialog from '../../../../../components/common/confirmDialog/ConfirmDialog';
import confirmImage from './../../../../../assets/images/ConfirmIcon.svg';
import { CommonError } from '../../../../../interfaces/interfaces';
import { FORM_ERROR } from 'final-form';



type Props = {
    currentPassword: string;
    setCurrentPassword: Function;
}
type Message = {
    isSuccess: boolean,
    message: string
}



const ChangePassword = ({ currentPassword, setCurrentPassword }: Props) => {
    const navigate = useNavigate();
    const { integrationPartner } = useContext(ProfileContext);
    const { toggle: modalToggle, visible: modalVisible } = useModal();
    const [message, setMessage] = useState<Message>({ isSuccess: true, message: null });
    const [loading, setLoading] = useState(false);

    const onSubmit = async (values) => {
        // console.log(values, currentPassword);
        let errors = {
            value: false,
            message: {},
        }
        setLoading(true);
        try {
            const data = await RestS.changePassword({ ...values, oldPassword: currentPassword });
            if (data) {
                setMessage({ isSuccess: true, message: "Password updated successfully" })
                modalToggle();
            }
            setLoading(false);

        } catch (error) {
            // console.log(error);
            setLoading(false);

            const errorData = error.errors as CommonError[]
            if (errorData && errorData.length > 0) {
                const parseError = errorData.reduce((prev, currVal) => {
                    prev[currVal.field] = currVal.message
                    return prev;
                }, {});

                errors = {
                    value: true,
                    message: parseError
                }
            } else {
                errors = {
                    value: true,
                    message: {
                        [FORM_ERROR]: "Something went wrong"
                    }
                }
            }
        }


        if (errors.value == true) {
            // console.log(errors);
            return errors.message
        }
    }

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.backButton} onClick={() => navigate('/profile')}>
                    <BackButton />
                </div>
                <div className={styles.title}>
                    Back to View Profile
                </div>
            </div>
            <Form
                validate={values => {
                    const errors = {};
                    if (values.password && (values.password.length < 4 || values.password.length > 30)) {
                        errors['password'] = 'Password should consist of minimum 4 characters and maximum 30 characters';
                        errors['confirmPassword'] = 'Password should consist of minimum 4 characters and maximum 30 characters';
                    }
                    if (values.password && values.password == currentPassword) {
                        errors['password'] = 'Password cannot be same as old password'
                    }
                    if (values.password && values.confirmPassword && values.password !== values.confirmPassword) {
                        errors['confirmPassword'] = 'Password do not match'
                    }
                    return errors;
                }}
                onSubmit={onSubmit} render={({ handleSubmit, pristine, hasValidationErrors, submitError }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className={styles.formSection}>
                                <ProfileImage profileImage={integrationPartner.profileImage} edit={false} fallback={integrationPartner.name} />
                                <div className={styles.fieldSection}>
                                    <Field name='password' validate={(values) => reqiredValidation(values, "New Password")}>
                                        {props => {
                                            return (
                                                <OutlinedInput
                                                    {...props.input}
                                                    name={props.input.name}
                                                    error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null}
                                                    type='password' value={props.input.value} onChange={props.input.onChange} placeholder="Enter New Password" />
                                            )
                                        }}
                                    </Field>
                                    <Field name='confirmPassword' validate={(values) => reqiredValidation(values, "Confirm Password")}>
                                        {props => {
                                            return (
                                                <OutlinedInput
                                                    {...props.input}
                                                    name={props.input.name}
                                                    error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null}
                                                    type='password' value={props.input.value} onChange={props.input.onChange} placeholder="Enter Confirm Password" />
                                            )
                                        }}
                                    </Field>
                                </div>
                                {submitError && <div className={styles.formError}>
                                    {submitError}
                                </div>}


                                <button type='submit' disabled={pristine || loading || hasValidationErrors}
                                    className={`${styles.filled} ${loading ? styles.loading : ''}`}
                                    onClick={handleSubmit}>
                                    <div className={styles.buttonText}>
                                        Change Password
                                    </div>
                                </button>
                            </div>
                        </form>
                    )
                }} />

            <ConfirmDialog bodyStyle={{ padding: "2rem 0" }} showButton={false} visible={modalVisible} toggle={() => {
                modalToggle();
                setMessage({ message: null, isSuccess: true });
                navigate('/profile');
            }} title={message.message}>

                <div className={`confirmIcon rounded-circle`}>
                    <img src={confirmImage} />
                </div>

            </ConfirmDialog>
        </div>
    );
}

export default ChangePassword;