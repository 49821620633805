import { Audio } from "react-loader-spinner";

type Props = {
    color?: string,
    height?: number,
    width?: number,
    message?: string
};

export default function Loader({ color, height, width, message }: Props) {
    return (
        <div style={{ flexGrow: '1', width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <Audio
                height={height || 150}
                width={height || 150}
                color={color ? color : 'white'}
                ariaLabel='loading'
            />
            {message &&
                <div style={{ padding: "10px" }}>
                    {message}
                </div>
            }
        </div>
    );
}