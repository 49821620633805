import React, { useState, useEffect, useRef } from 'react'
import HeroSection from '../LandingPage/heroSection/HeroSection';
import styles from './SignUp.module.scss';
import { Form, Field } from 'react-final-form';
import OutlinedInput from '../../components/common/OutlinedInput/OutlineInput';
import ConfirmButton from './buttons/ConfirmButton';
import CustomButton from './buttons/ConfirmButton';
import { HiArrowLeft as LeftArrow } from 'react-icons/hi';
import { cutomEmailValidation, mobileNumberValidation, reqiredValidation } from '../../components/common/functions/formValidation';
import { useNavigate } from 'react-router-dom';
import ConfirmPage from '../ConfirmPages/ConfirmPage';
import axios from 'axios';
import MainLayout from '../LandingPage/MainLayout';
import { CommonError } from '../../interfaces/interfaces';
import { FORM_ERROR } from 'final-form';
import useAnalyticsEventTracker from '../../components/Layout/useAnalyticsEventTracker';
import ProfileImage from '../profile/components/profileImage/ProfileImage';
import IconUpload from './IconUpload';



type SignUpPayload = {
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    contactNumber: string;
    companyName: string;
}

type Props = {
    title: string;
    isByMerito?: boolean;
}

const SignUp = ({ title, isByMerito }: Props) => {
    const navigate = useNavigate();
    const [showConfirm, setShowConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const gaEventTracker = useAnalyticsEventTracker('signup');
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        document.title = title || "Merito"
    }, [])

    const onSubmit = async (values) => {
        console.log(values);
        const form = new FormData()
        if (values.icon)
            form.append("icon", values.icon)
        // setShowConfirm(true);
        let errors = {
            value: false,
            message: {},
        };
        let signUpPayload: SignUpPayload = {
            name: `${values['firstName']} ${values['lastName']}`,
            email: values.email,
            password: values.password,
            confirmPassword: values.password,
            contactNumber: values.contactNumber,
            companyName: values.companyName
        }
        form.append('data', JSON.stringify(signUpPayload))

        setLoading(true);
        try {
            let url = `${process.env.REACT_APP_API_URL}/api/integrationpartner/signup`;

            if (isByMerito) {
                url += `?isByMerito=${isByMerito}`
            }

            const data = await axios.post(url, form);
            gaEventTracker("signup success")
            if (data) {
                // console.log(data);
                setShowConfirm(true);
            }
            setLoading(false);
        } catch (error) {
            gaEventTracker("signup error")
            // console.log(error);
            setLoading(false);
            const errorData = error.response?.data?.errors as CommonError[];
            if (errorData && errorData.length > 0) {
                const parseError = errorData.reduce((prev, currVal) => {
                    prev[currVal.field] = currVal.message
                    return prev;
                }, {});
                errors = {
                    value: true,
                    message: parseError
                }
            } else {
                errors = {
                    value: true,
                    message: {
                        [FORM_ERROR]: "Something went wrong"
                    }
                }
            }
        }


        if (errors.value == true) {
            // console.log(errors);
            return errors.message
        }
    }


    const RenderSubtitle = () => {
        return (
            <>

                <div>
                    Check your inbox
                </div>

                <div style={{ color: "#596DE7", cursor: "pointer" }} onClick={() => {
                    navigate('/signin')
                }}>
                    Sign in here
                </div>
            </>

        )
    }

    return (
        <MainLayout>

            <HeroSection />
            {showConfirm ?
                <ConfirmPage title='Verify your email ID to sign in' subtitle={<RenderSubtitle />} />
                : <div className={styles.rightPart}>
                    <div className={styles.backArrow} onClick={() => {
                        navigate('/signin')
                    }}>
                        <LeftArrow />
                    </div>
                    <div className={styles.signUpSection}>
                        <div className={styles.title} >Sign up</div>

                        <Form
                            validateOnBlur={true}
                            validate={values => {
                                const errors = {};
                                if (!values.password) {
                                    errors['password'] = 'Required Password';
                                }
                                if (values.password && (values.password.length < 4 || values.password.length > 30)) {
                                    errors['password'] = 'Password should consist of minimum 4 characters and maximum 30 characters';
                                }
                                if (!values.confirmPassword) {
                                    errors['confirmPassword'] = 'Required Confirm Password';
                                } else if (values.confirmPassword !== values.password) {
                                    // console.log(values.confirmPassword, values.password)
                                    errors['confirmPassword'] = 'Password confirm does not match'
                                }

                                return errors;
                            }}
                            onSubmit={(values) => onSubmit(values)} render={({ handleSubmit, pristine, invalid, visited, hasValidationErrors, submitError }) => (
                                <>
                                    <form onSubmit={handleSubmit}>

                                        <div className={styles.formSection}>
                                            <Field name='companyName' validate={(values) => reqiredValidation(values, "Company Name")}>
                                                {props => {
                                                    return <OutlinedInput {...props.input} maxLength={25}
                                                        error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null}
                                                        value={props.input.value} onChange={props.input.onChange}
                                                        placeholder="Company/Brand name" />
                                                }}
                                            </Field>

                                            <div className={styles.flexField}>

                                                <Field name='firstName' validate={(values) => reqiredValidation(values, "First Name")} >
                                                    {props => {
                                                        return <OutlinedInput {...props.input} maxLength={15}
                                                            error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null}
                                                            value={props.input.value} onChange={props.input.onChange} placeholder="First Name" />
                                                    }}
                                                </Field>
                                                <Field name='lastName' validate={(values) => reqiredValidation(values, "Last Name")}>
                                                    {props => {
                                                        return <OutlinedInput {...props.input} maxLength={15}
                                                            error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null}
                                                            value={props.input.value} onChange={props.input.onChange} placeholder="Last Name" />
                                                    }}
                                                </Field>
                                            </div>

                                            <Field name='email' validate={cutomEmailValidation}>
                                                {props => {
                                                    return <OutlinedInput {...props.input}
                                                        error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null}
                                                        type='email' value={props.input.value}
                                                        onChange={props.input.onChange} placeholder="Email Address" />
                                                }}
                                            </Field>

                                            <Field name='password' >

                                                {props => {
                                                    return (
                                                        <OutlinedInput {...props.input} name={props.input.name}
                                                            error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null}
                                                            type='password' value={props.input.value}
                                                            onChange={props.input.onChange} placeholder="Password" />
                                                    )
                                                }}
                                            </Field>

                                            <Field name='confirmPassword'>
                                                {props => {
                                                    return <OutlinedInput {...props.input}
                                                        error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null}
                                                        type='password' value={props.input.value} onChange={props.input.onChange} placeholder="Confirm Password" />
                                                }}
                                            </Field>

                                            <Field name='contactNumber' validate={mobileNumberValidation}>
                                                {props => {
                                                    return <OutlinedInput {...props.input} maxLength={10}
                                                        error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null}
                                                        value={props.input.value} onChange={props.input.onChange} placeholder="Contact Number" />
                                                }}
                                            </Field>
                                            {/* <Field name='icon'>
                                                {
                                                    props => {
                                                        return <IconUpload onChange={props.input.onChange} value={props.input.value} />
                                                    }
                                                }
                                            </Field> */}
                                            {submitError && <div className={styles.formError}>
                                                {submitError}
                                            </div>}
                                            <CustomButton loading={loading} type="submit" disabled={pristine || hasValidationErrors} onClick={handleSubmit} >Create Account</CustomButton>
                                        </div>


                                    </form>
                                </>

                            )} />
                    </div>

                    <div className={styles.bottomTitle} onClick={() => {
                        navigate('/signin')
                    }}>
                        Already have an account? <span>Sign In</span>
                    </div>
                </div>}

        </MainLayout>

    );
}

export default SignUp;