import styles from "./pivot.module.scss";
import { CaretDownFill, Filter } from "react-bootstrap-icons";
import { useEffect, useRef, useState } from "react";
import { PivotTable } from "../../interfaces/interfaces";
import PivotBodyRow from "./components/PivotBodyRow/PivotBodyRow";
import { formatNumber } from "../functions/utilities";


function PivotTableComponent({ tableData, isLarge }: { tableData: PivotTable, isLarge?: boolean }) {

    const [tableDisplay, setTableDisplay] = useState<PivotTable>(tableData)
    const [filter, setFilter] = useState<(string | number)[]>([])
    const [filterType, setFilterType] = useState<any>(-1)
    const [isPercentageMode, setPercentageMode] = useState<boolean>(false)
    const inputDropdown = useRef(null);

    const tableRef = useRef();


    const shortData = (col: any) => {
        let filterData = []
        if (filterType < 0) {
            filterData = tableData.body.sort((a: any, b: any) => (b[col] < a[col] ? -1 : 1))
        } else {
            filterData = tableData.body.sort((a: any, b: any) => (a[col] > b[col] ? 1 : -1))
        }
        let table: PivotTable = JSON.parse(JSON.stringify(tableData))
        table.body = [...filterData]
        setTableDisplay(table)
    }

    // const changeFilter = (item) => {
    //     let filterArr: (string | number)[] = [...filter]
    //     if (filter.includes(item[0])) {
    //         filterArr = filter.filter((i) => (i != item[0]))
    //     } else {
    //         filterArr = [...filter, item[0]]
    //     }
    //     let data = [...tableData.body]
    //     if (filterArr.length > 0) {
    //         data = data.filter((i) => {
    //             if (filterArr.includes(i[0].toString())) return i
    //         })
    //     }
    //     let table: PivotTable = JSON.parse(JSON.stringify(tableData))
    //     table.body = [...data]
    //     setFilter(filterArr)
    //     setTableDisplay(table)
    // };

    const changeFilter = (item: any) => {
        let filterArr: (string | number)[] = [...filter]
        // console.log(filterArr, item)
        if (filter.includes(item[0])) {
            filterArr = filter.filter((i) => (i != item[0]))
        } else {
            filterArr = [...filter, item[0]]
        }

        let data = [...tableData.body]
        if (filterArr.length > 0) {
            data = data.filter((i) => {
                if (filterArr.includes(i.parent[0].toString())) return i
            })
        }
        // console.log(tableData);
        let table: PivotTable = JSON.parse(JSON.stringify(tableData))
        table.body = [...data]
        setFilter(filterArr)
        setTableDisplay(table)
    };

    const cancleFilter = () => {
        let table: PivotTable = JSON.parse(JSON.stringify(tableData))
        setFilter([])
        setTableDisplay(table)
    }

    let dropdownTimeOut: any;

    // let [matrix, setMatrix] = useState([]);
    // let [header, setHeader] = useState([]);
    // useEffect(() => {
    //     let h = [];
    //     let m = [];
    //     tableData.header.forEach((data, index) => {
    //         let rowHeader = data.filter(e => e);
    //         let rowMatrix = []
    //         for (let i = 0; i < data.length; i++) {
    //             let e = data[i];
    //             let next = data[i + 1];
    //             if (!Boolean(e) && Boolean(next)) {
    //                 rowMatrix[rowMatrix.length - 1] += 1;
    //             } else {
    //                 rowMatrix.push(1)
    //             }
    //         }
    //         h.push(rowHeader)
    //         m.push(rowMatrix)
    //         console.log(data)
    //         console.log(h)
    //         console.log(m)
    //     })
    //     setMatrix(m);
    //     setHeader(h);
    // }, [])




    return (
        <>
            {/* <button onClick={toImage}>image</button> */}
            <div className={styles.pivot}>
                <div className={styles.dropdownBlock} >
                    <div className={styles.dropdownContent} ref={inputDropdown} onMouseOver={(e) => {
                        clearTimeout(dropdownTimeOut);
                    }} onMouseLeave={() => {
                        setTimeout(() => {
                            // inputDropdown.current.style.display = "none"
                        }, 100);
                    }}>
                        <div className={styles.body}>
                            {/* {tableData.body && tableData.body.map((i, index) => (
                                <a
                                    key={index}
                                    onClick={() => {
                                        changeFilter(i);
                                    }}
                                >
                                    <input
                                        className="form-check-input mr-2"
                                        type="checkbox"
                                        value=""
                                        id={i[0].toString()}
                                        checked={filter.includes(i[0])}
                                        onChange={() => {
                                            changeFilter(i);
                                        }}
                                    />
                                    {i[0]}
                                </a>
                            ))} */}
                            {tableData.body && tableData.body.map((i, index) => (
                                <a
                                    key={index}
                                    onClick={() => {
                                        changeFilter(i.parent);
                                    }}
                                >
                                    <input
                                        className="form-check-input mr-2"
                                        type="checkbox"
                                        value=""
                                        id={i.parent[0].toString()}
                                        checked={filter.includes(i.parent[0])}
                                        onChange={() => {
                                            changeFilter(i.parent);
                                        }}
                                    />
                                    {i.parent[0]}
                                </a>
                            ))}
                            <div className={styles.footer} onClick={() => cancleFilter()}>Cancel</div>
                        </div>
                    </div>
                </div>
                <div className={`${styles.tableFixHead} ${isLarge ? styles.large : styles.small}`}
                // ref={tableRef}
                >
                    <div>
                        <table>
                            <thead>
                                {tableData.header && tableData.header.map((data, i) => (
                                    <tr key={i}>
                                        {typeof data != 'string' && data?.map((ele, index) =>
                                            <th key={index} scope="col">
                                                <div className="d-flex align-items-center">
                                                    {i == (tableData.header.length - 1) && index === 0 ? <span className={styles.dropdown}>
                                                        <span className={`j-cursor ${styles.label}`} onMouseOver={(e) => {
                                                            // inputDropdown.current.style.display = "block"
                                                        }} onMouseLeave={() => {
                                                            dropdownTimeOut = setTimeout(() => {
                                                                // inputDropdown.current.style.display = "none"
                                                            }, 100);
                                                        }}>
                                                            <span dangerouslySetInnerHTML={{ __html: ele }}></span>
                                                            {" "}
                                                            {filter.length > 0 && `(${filter.length})`} <CaretDownFill />
                                                        </span>
                                                    </span> :
                                                        <span dangerouslySetInnerHTML={{ __html: ele }}></span>
                                                    }
                                                    {i == (tableData.header.length - 1) && <span className="p-2"
                                                        onClick={() => {
                                                            Math.abs(filterType) == index + 1 ?
                                                                setFilterType(-filterType) : setFilterType(index + 1);
                                                            shortData(index);
                                                        }}
                                                    >
                                                        <Filter className={`j-cursor ${Math.abs(filterType) == (index + 1) && filterType < 0 ? styles.rotate : ""}`} />
                                                    </span>}
                                                </div>
                                            </th>
                                        )}
                                    </tr>
                                ))}
                                {/* {header && header.map((data, i) => (
                                    <tr key={i}>
                                        {typeof data != 'string' && data?.map((ele, index) => {
                                            let span = matrix[i][index];
                                            console.log(span)
                                            return <th key={index} scope="col" colSpan={span}>
                                                <div className="d-flex align-items-center">
                                                    {i == (header.length - 1) && index === 0 ? <span className={styles.dropdown}>
                                                        <span className={`j-cursor ${styles.label}`} onMouseOver={(e) => {
                                                            inputDropdown.current.style.display = "block"
                                                        }} onMouseLeave={() => {
                                                            dropdownTimeOut = setTimeout(() => {
                                                                inputDropdown.current.style.display = "none"
                                                            }, 100);
                                                        }}>
                                                            {ele}{" "}
                                                            {filter.length > 0 && `(${filter.length})`} <CaretDownFill />
                                                        </span>
                                                    </span> :
                                                        <span>
                                                            {ele}
                                                        </span>
                                                    }
                                                    {i == (header.length - 1) && <span className="p-2"
                                                        onClick={() => {
                                                            Math.abs(filterType) == index + 1 ?
                                                                setFilterType(-filterType) : setFilterType(index + 1);
                                                            shortData(index);
                                                        }}
                                                    >
                                                        <Filter className={`j-cursor ${Math.abs(filterType) == (index + 1) && filterType < 0 ? styles.rotate : ""}`} />
                                                    </span>}
                                                </div>
                                            </th>
                                        }
                                        )}
                                    </tr>
                                ))} */}
                                <tr>

                                </tr>
                            </thead>
                            <tbody>
                                {tableDisplay.body && tableDisplay.body.map((i, index) => (
                                    <PivotBodyRow rowData={i} key={index} level={0} />
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    {tableData.footer && tableData.footer.map((ele, index) => (
                                        <td key={index}>
                                            {typeof ele == "string" ? ele : formatNumber(ele)}
                                        </td>
                                    ))}
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PivotTableComponent;
