import { useContext, useState } from "react";
import { Field, Form } from "react-final-form";
import { reqiredValidation } from "../../../../../components/common/functions/formValidation";
import OutlinedInput from "../../../../../components/common/OutlinedInput/OutlineInput";
import ProfileProvider, { ProfileContext } from "../../../context/ProfileContext";
import ProfileImage from "../../profileImage/ProfileImage";
import styles from './CurrentPassword.module.scss';
import { FORM_ERROR } from 'final-form'
import { RestS } from "../../../../../rest/rest-service";
import { toast } from "react-toastify";
import { BiChevronLeft as BackButton } from 'react-icons/bi'
import { useNavigate } from "react-router-dom";
import useModal from "../../../../../components/Hooks/useModal";
import { CommonError } from "../../../../../interfaces/interfaces";

type Props = {
    currentPassword: string;
    setCurrentPassword: Function;
    changeToConfirmScreen: Function
}


const CurrentPassword = ({ currentPassword, setCurrentPassword, changeToConfirmScreen }: Props) => {
    const { integrationPartner } = useContext(ProfileContext);
    const navigate = useNavigate();
    const { toggle: modalToggle, visible: modalVisible } = useModal();
    const [errorMessage, setErrorMessage] = useState(null);

    const onSubmit = async (values) => {
        let errors = {
            value: false,
            message: {},
        };
        try {
            const data = await RestS.checkPassword(values['password']);
            if (data) {
                // toast.success("password sucess");
                setCurrentPassword(values['password']);
                changeToConfirmScreen();
            }
        } catch (error) {
            // console.log(error);
            const errorData = error.errors as CommonError[]
            if (errorData && errorData.length > 0) {
                const parseError = errorData.reduce((prev, currVal) => {
                    prev[currVal.field] = currVal.message
                    return prev;
                }, {});

                errors = {
                    value: true,
                    message: parseError
                }
            } else {
                errors = {
                    value: true,
                    message: {
                        [FORM_ERROR]: "Something went wrong"
                    }
                }
            }
        }

        if (errors.value == true) {
            // console.log(errors);
            return errors.message
        }
    }

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.backButton} onClick={() => navigate('/profile')}>
                    <BackButton />
                </div>
                <div className={styles.title}>
                    Back to View Profile
                </div>

            </div>
            <Form onSubmit={onSubmit}
                validate={values => {
                    const errors = {};
                    if (!values.password) {
                        errors['password'] = 'Required Password'
                    }
                    return errors;
                }}
                render={({ handleSubmit, submitError, pristine }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className={styles.formSection}>
                                <ProfileImage profileImage={integrationPartner.profileImage} edit={false} fallback={integrationPartner.name} />
                                <div className={styles.fieldSection}>
                                    <Field name='password'>
                                        {props => {
                                            return (
                                                <OutlinedInput name={props.input.name}
                                                    error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null}
                                                    type='password' value={props.input.value} onChange={props.input.onChange} placeholder="Enter current Password" />
                                            )
                                        }}
                                    </Field>
                                </div>

                                {submitError && <div className="error">{submitError}</div>}
                                <button type="submit" disabled={pristine} className={styles.filled} onClick={handleSubmit}>Next</button>
                            </div>
                        </form>


                    );
                }} />
        </div>


    );
}

export default CurrentPassword;