import { useEffect, useState } from "react";
import Calendar from "react-calendar";
// import styles from "./calendar.module.scss";
import "./calendar.css";

function CalenderReact({setFrequencyTime}:any) {
  const [date, setDate] = useState(new Date());

  return (
    <>
      <Calendar
        // className={styles.calendarInner}
        showNavigation={false}
        // tileClassName={styles.calTile}
        view="month"
        value={date}
        onChange={(e: any) =>{setFrequencyTime(e.getDate())}}
      />
    </>
    
  );
}

export default CalenderReact;
