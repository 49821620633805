import { useEffect, useState } from 'react';
import { getLocalJwt, getLocalUser } from '../common/functions/utilites';

const useAuth = () => {
    let token = getLocalJwt();
    let user = getLocalUser();

    if (token != null && user != null) {
        return true
    }
    return false
};

export default useAuth;