// start from start
//rotate on y and start after label
// tring for multi category
const lineHeight = 18;
const barLineHeight = 18;

export let jkCategory = {
  id: "jkCategory",
  beforeTooltipDraw: function (chart: any) {
    const category = chart.config.options.plugins.jkCategory.category;
    if (!category) {
      return;
    }

    const area = chart.chartArea;
    const ctx = chart.ctx;
    const isBar = chart.config.options.plugins.jkCategory.isBar;
    const labelsCount = chart.data.labels.length;

    ctx.strokeStyle = "#b5b5b5";
    ctx.lineWidth = 1;

    if (isBar) {
      const dimensionXTick = (area.bottom - area.top) / labelsCount;
      let x = area.bottom;
      for (let index = 0; index < labelsCount + 1; index++) {
        this.addBarLabelLine(ctx, area.left, area.top + dimensionXTick * index);
      }

      Object.values(category).forEach((cat: any, index) => {
        let lableOffset = 0;
        let x1 = 0 + ((Object.values(category).length - index) * barLineHeight) - 10;
        cat.forEach((element: any) => {
          this.addBarCategoryLine(ctx, x1, area.top + dimensionXTick * lableOffset, area)
          lableOffset += element.value;
          this.addBarCategoryLine(ctx, x1, area.top + dimensionXTick * lableOffset, area)
        })
      });

      ctx.save();
      ctx.translate(chart.width / 2, chart.height / 2);
      ctx.rotate(Math.PI / -2);
      ctx.translate(-chart.height / 2, -chart.width / 2);

      Object.values(category).forEach((cat: any, index) => {
        let lableOffset = 0;
        let y = 0 + ((Object.values(category).length - index) * barLineHeight);
        cat.forEach((element: any) => {
          this.addBarText(ctx, element.name, x, y, element.value, dimensionXTick, lableOffset)
          lableOffset += element.value;
        })
      });

      ctx.restore();
    } else {
      let x = area.left;
      const dimensionXTick = (area.right - area.left) / labelsCount;

      for (let index = 0; index < labelsCount + 1; index++) {
        this.addColLabelLine(ctx, x + dimensionXTick * index, area.bottom);
      }

      Object.values(category).forEach((cat: any, index) => {
        let lableOffset = 0;

        let y = chart.height - ((Object.values(category).length - index) * lineHeight)
        cat.forEach((element: any) => {
          this.addColText(
            ctx,
            element.name,
            x,
            y,
            element.value,
            dimensionXTick,
            lableOffset,
            area
          );
          lableOffset += element.value;
        });
      });
    }
  },
  addColText: (ctx: any, label: any, positionX: any, positionY: any, value: any, dTick: any, lableOffset: any, area: any) => {
    let metrics = ctx.measureText(label);
    let x = positionX + dTick * lableOffset + (dTick * value - metrics.width) / 2;
    let y = positionY;
    ctx.textBaseline = "top";
    ctx.fillStyle = "#b5b5b5";
    ctx.fillText(label, x, y);

    ctx.beginPath();
    ctx.moveTo(positionX, area.bottom);
    ctx.lineTo(positionX, y + 10);
    ctx.closePath();
    ctx.stroke();

    let endXPoint = positionX + dTick * (lableOffset + value)
    ctx.beginPath();
    ctx.moveTo(endXPoint, area.bottom);
    ctx.lineTo(endXPoint, y + 10);
    ctx.closePath();
    ctx.stroke();
  },
  addColLabelLine: (ctx:any, x:any, y:any) => {
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x, y + 5);
    ctx.closePath();
    ctx.stroke();
  },
  addBarText: (ctx:any, label:any, positionX:any, positionY:any, value:any, dTick:any, lableOffset:any) => {
    let metrics = ctx.measureText(label);
    positionX = positionX;
    let x = positionX - (dTick * lableOffset) - ((dTick * value - metrics.width) / 2);
    let y = positionY;
    ctx.textBaseline = "bottom";
    ctx.textAlign = "center";
    ctx.fillStyle = "#b5b5b5";
    ctx.fillText(label, x, y);
  },
  addBarLabelLine: (ctx:any, x:any, y:any) => {
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x - 5, y);
    ctx.closePath();
    ctx.stroke();
  },
  addBarCategoryLine: (ctx:any, x:any, y:any, area:any) => {
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(area.left, y);
    ctx.closePath();
    ctx.stroke();
  }
};
