import React, { useState, useEffect } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { RestS } from '../../rest/rest-service';
import useAuth from '../Hooks/useAuth';

const ProtectedRoute = () => {
    const [auth, setAuth] = useState(useAuth());
    const navigate = useNavigate()

    useEffect(() => {
        if (auth) {
            RestS.verifyJwt().then((res) => {
                setAuth(res);
            }).catch(error => {
                navigate('/')
                // console.log(error);
            });
        }
    }, []);

    return (
        auth ? <Outlet />
            : <Navigate to={`/signin?redirect_uri=${window.location.href}`} replace />
    );
}

export default ProtectedRoute;