import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./header/header";
import styles from './layoutIndex.module.scss';
import SideBar from "./sidebar/Sidebar";

type Props = {
    // children: React.ReactNode,
    sideBar?: boolean
}

const LayoutIndex = ({ sideBar = true }: Props) => {
    const [sideBarCompo, setSideBarCompo] = useState<any>()
    const [metricsSearchValue, setMetricsSearchValue ] = useState<any>('')

    return (
        <div className={styles.mainCont}>
            <Header />
            <div className={styles.page}>
                {
                    sideBar ? <SideBar sideBarCompo={sideBarCompo} metricsSearchValue={metricsSearchValue} setMetricsSearchValue={setMetricsSearchValue} /> : null
                }
                <div id="pageBody" className={styles.pageBody}>
                    <Outlet context={[sideBarCompo, setSideBarCompo, metricsSearchValue]} />
                </div>
            </div>
        </div>
    );
}

export default LayoutIndex;