import moment from 'moment';
// import { User } from '../../interfaces/interfaces';

// export function getTime(time) {
//     return moment(time).format('DD/MM/YYYY');
// }

// export function ChaanelTime(time) {
//     return moment(time).format('Do MMMM, YYYY')
// }

// export function GetHoursMinute(time) {
//     return moment(time).format('hh:MM A');
// }

// export function getLocalUser(): User {
//     let user: User = JSON.parse(localStorage.getItem('user'));
//     return user;
// }

// export function getLocalJWT() {
//     return localStorage.getItem('jwt_token') || "1";
// }

// export function getLocalUserId() {
//     return localStorage.getItem('userId') || "1";
// }

// export function getLocalCompanyId() {
//     return localStorage.getItem('companyId') || "1";
// }

// export function logout() {
//     localStorage.clear()
//     return true;
// }

// export function login(token, user: User) {
//     localStorage.setItem('jwt_token', token)
//     localStorage.setItem('user', JSON.stringify(user));
//     localStorage.setItem('userId', user.id.toString());
//     localStorage.setItem('companyId', user.companyId.toString());
// }

// export function getCapital(str: string): String {
//     return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
// }

// export function isArrIncludes(arr: string[], str: string): boolean {
//     return arr.includes(str);
// }

// export function getNumericString(str: string): string {
//     return str.replace(/\D/g, '');
// }

export default function convertToInternationalCurrencySystem(labelValue) {

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9
  
      ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
      // Six Zeroes for Millions 
      : Math.abs(Number(labelValue)) >= 1.0e+6
  
        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "M"
        // Three Zeroes for Thousands
        : Math.abs(Number(labelValue)) >= 1.0e+4
  
          ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"
  
          : Math.round(Number(labelValue) * 10) / 10;
  }
  
export function formatNumber(val) { 
let format = new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});
return convertToInternationalCurrencySystem(val);
}

export function sumArrays(...arrays: any) {
    const n = arrays.reduce((max: any, xs: any) => Math.max(max, xs.length), 0);
    const result = Array.from({ length: n });
    return result.map((_, i) => arrays.map((xs: any) => xs[i] || 0).reduce((sum:any, x:any) => Number(sum) + Number(x), 0));
}

export function getApiUrl(str: string) {
    let isS3 = true ? 'S3' : '';
    return str + isS3;
}