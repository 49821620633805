import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Colors } from "./_constants";
import { DoughnutChart } from "../../interfaces/interfaces";
import { htmlLegendPlugin } from "./ChartPlugins/HtmlLegendPlugin";
import { useRef } from "react";

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
    chart: DoughnutChart
}

function DoughnutChartWrap({ chart }: Props) {
    const legendRef = useRef(null);
    let showDatalabels = chart.label.length < 5;
    // chart.points.forEach((dp) => {
    //     data.labels.push(dp.key)
    //     data.datasets[0].data.push(Number.parseFloat(String(dp.value)).toFixed(2))
    // })

    let isPercentageMode: boolean = false
    let totalSum: number = 0
    if(chart.datasets[0])
        chart.datasets[0].data.forEach((dp) => {
            totalSum += Number.parseFloat(dp.toString())
        })
    if (totalSum === 1 || (totalSum < 1 && totalSum > 0.99)) {
        isPercentageMode = true
    }

    let options = {
        responsive: true,
        aspectRatio: 2,
        plugins: {
            legend: {
                display: false,
                position: "right" as const,
                labels: {
                    boxWidth: 20,
                    usePointStyle: true,
                    pointStyle: "rectRounded",
                    padding: 10,
                },
                maxHeight: 1,
                maxWidth: 500,
            },
            datalabels: {
                display: showDatalabels,
                font: {
                    weight: "bold" as const,
                },
                color: "#FFFFFF",
                formatter: (value: any, ctx: any) => {
                    let sum = 0;
                    let dataArr = ctx.chart.data.datasets[0].data;
                    dataArr.map((data: any) => {
                        sum += parseFloat(data);
                    });
                    let percentage = Math.round(value * 100 / sum) + "%";
                    return percentage;


                },
            },
            htmlLegend: {
                containerID: legendRef,
                showLegend: true,
            },
            tooltip: {
                callbacks: {
                    label: function (context: any) {
                        let label = '';
                        if (isPercentageMode) {
                            label += (context.raw * 100).toFixed(2);
                            label += " %";
                        } else {
                            label += context.raw;
                        }
                        return label;
                    }
                }
            },
        },
    };

    let datasets = chart.datasets.map((e, index) => {
        let i = index % 10;
        return {
            ...e,
            borderWidth: 0,
            backgroundColor: Colors,
        }
    })

    let data = {
        labels: chart.label,
        datasets: datasets
    };


    return <>
        <div className="d-flex" style={{ maxWidth: "100%", justifyContent: "center", alignItems: "center" }}>
            <div className="" style={{ width: "73%" }}>
                <Doughnut data={data} plugins={[ChartDataLabels, htmlLegendPlugin]} options={options} />
            </div>
            <div
                ref={legendRef}
                style={{ width: "27%", maxHeight: "220px", overflow: "auto", paddingRight: "10px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            </div>
        </div>
    </>
}

export default DoughnutChartWrap