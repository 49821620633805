import { Bar } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle,
} from "chart.js";

import { Colors } from "./_constants";
import { BarChart } from "../../interfaces/interfaces";
import { useRef } from "react";
import { htmlLegendPlugin } from "./ChartPlugins/HtmlLegendPlugin";
import { jkCategory } from "./ChartPlugins/Category";
import convertToInternationalCurrencySystem, { sumArrays } from "../functions/utilities";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle
);

type Props = {
  chart: BarChart;
};

function BarChartWrap({ chart }: Props) {
  const legendRef = useRef(null);
  let showLegend = chart.datasets.length > 1;
  let isStacked = chart.type.toString().includes("stacked");
  // let is100per = false;
  let is100per = chart.type.toString().includes("100");
  let isHistogram = chart.type.toString().includes("histogram");
  let isBar = chart.type.toString().includes("bar");

  let categoryPadding = chart.config?.category ? 18 * Object.keys(chart.config?.category).length : 0;

  let stackDataSetSum = []
  if (is100per) {
    stackDataSetSum = sumArrays(...chart.datasets.map((data) => data.data))
  }

  let options = {
    barPercentage: isHistogram ? 1 : 0.9,
    categoryPercentage: isHistogram ? 1 : 0.8,
    borderColor: "white",
    borderWidth: isHistogram ? 1 : 0,
    responsive: true,
    maintainAspectRatio: true,
    devicePixelRatio: 2,
    indexAxis: isBar ? 'y' as const : 'x' as const,
    parsing: {
      yAxisKey: 'percentage',
      xAxisKey: 'percentage'
    },
    layout: {
      padding: {
        bottom: !isBar ? categoryPadding : 0,
        left: isBar ? categoryPadding : 0,
      }
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
        labels: {
          boxWidth: 20,
          usePointStyle: true,
          pointStyle: "rectRounded",
          padding: 10,
        },
      },
      backgroundColor: Colors[5],
      title: {
        display: false,
      },
      htmlLegend: {
        containerID: legendRef,
        showLegend,
      },

      tooltip: {
        callbacks: {
          title: function(title) {
            if(isStacked) 
              return title[0].dataset.label
            else {
              return title[0].dataset.label == chart.title ? title[0].label : title[0].dataset.label
            }
          },
          label: function (context) {
            let label = '';
            if (context.parsed.y !== null) {
              if (is100per) {
                label += context.raw.data;
                label += ` (${context.raw.percentage.toFixed(2)}%)`;
              } else {
                label += context.raw
              }
            }
            return label;
          }
        }
      },
      jkCategory: {
        isBar,
        category: chart.config?.category
        // category: {
        //   0: [
        //     { "name": "other", "value": 3 }, { "name": "male", "value": 3 }, { "name": "female", "value": 3 }
        //   ],
        //   1: [
        //     { "name": "female", "value": 3 }, { "name": "female", "value": 3 }, { "name": "female", "value": 3 }
        //   ],
        //   2: [
        //     { "name": "male", "value": 9 }
        //   ],
        // }
      },
    },
    scales: {
      x: {
        title: {
          display: chart.config?.xAxisLabel ? true : false,
          text: chart.config?.xAxisLabel,
          color: '#808080',
          font: {
            family: 'Poppins',
            size: 12,
            weight: 600,
            lineHeight: 1.2,
          },
          padding: {top: 10, left: 0, right: 0, bottom: 0}
        },
        grid: {
          display: false,
        },
        stacked: isStacked,
        ticks: {
          padding: 0,
          // maxRotation: 90,
          // minRotation: 90
          callback: function(val, index) {
            if(!isBar) {
              return this.getLabelForValue(val)
            } else {
              return convertToInternationalCurrencySystem(val)
            }
          },
        }
      },
      y: {
        title: {
          display: chart.config?.yAxisLabel ? true : false,
          // text: "This is the label of y axis, ther label of y axis",
          text: chart.config?.yAxisLabel,
          color: '#808080',
          font: {
            family: 'Poppins',
            size: 12,
            weight: 600,
            lineHeight: 1.2,
          },
          padding: {top: 0, left: 0, right: 0, bottom: 10}
        },
        grid: {
          display: false,
        },
        stacked: isStacked,
        ticks: {
          padding: 0,
          callback: function(val, index) {
            if(isBar) {
              return this.getLabelForValue(val)
            } else {
              return convertToInternationalCurrencySystem(val)
            }
          },
        }
      },
    },
  };

  let datasets = chart.datasets.map((e, index) => {
    let i = index % 10;

    let stackData = []
    if (is100per) {
      e.data.forEach((d, di) => {
        let map = {
          data: d,
          percentage: ((Number(d) * 100) / stackDataSetSum[di])
        }
        stackData.push(map)
      })
    }
    else {
      stackData = e.data
    }
    let sum1 = 0 as any
    e.data.forEach(d => {sum1 += d})
    return {
      sum: sum1,
      label: e.label,
      data: stackData,
      // borderColor: Colors[i],
      backgroundColor: Colors[i],
    }
  })

  function compare( a, b ) {
    if ( a.sum < b.sum  ){
      return -1;
    }
    if ( a.sum < b.sum  ){
      return 1;
    }
    return 0;
  }
  
  datasets.sort( compare );
  
  let data = {
    labels: chart.label,
    datasets: datasets
  };

  let data1 = {
    labels: ["2017201120112011", "2016", "2017", "2018", "2019", "2016", "2017", "2018", "2019"],
    datasets: [
      {
        label: 'Male',
        data: [1, 2, 3, 5, 6, 3, 9, 8, 2],
        backgroundColor: Colors[2],
      },
      {
        label: 'Female',
        data: [5, 3, 2, 1, 2, 8, 10, 3, 6],
        backgroundColor: 'rgba(67, 67, 72, 0.9)',
        borderColor: 'rgb(67, 67, 72)',
      },
    ]
  }

  return <>
    < div className="d-flex" style={{ maxWidth: "100%", justifyContent: "center", alignItems: "center" }
    }>
      <div className="" style={{ width: showLegend ? "73%" : "100%" }}>
        <Bar options={options as any} data={data} plugins={[htmlLegendPlugin, jkCategory]} />
      </div>
      {
        showLegend &&
        <div
          ref={legendRef}
          style={{ width: "27%", maxHeight: "220px", paddingLeft: "10px", display: "flex", flexDirection: "column", justifyContent: "center", paddingTop: "0.5rem" }}>
        </div>
      }
    </div >
  </>
}

export default BarChartWrap;
