import styles from './header.module.scss';
import BlueJigsawLogo from '../../../assets/images/Logo IP.png';
import MeritoDarkLogo from '../../../assets/images/MeritoDarkLogo.svg';
import { clearLocalStoageOnLogout, getLocalUser } from '../../common/functions/utilites';
import { useRef, useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { AiOutlineUser as ProfileIcon } from 'react-icons/ai';
import { MdLogout as LogoutIcon } from 'react-icons/md';
import { useOnClickOutside } from '../../Hooks/useOnClickOutsideRef';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const user = getLocalUser();
    const [menuState, setMenuState] = useState(false);
    const outSideClickRef = useRef();
    const parentRef = useRef();
    const navigate = useNavigate();
    useOnClickOutside(outSideClickRef, parentRef, () => setMenuState(false));



    const renderName = () => {
        let userNameSplit = user.name.split(" ");
        if (userNameSplit.length > 1) {
            return `${userNameSplit[0].charAt(0)}${userNameSplit[1].charAt(0)}`.toUpperCase();
        } else {
            return `${userNameSplit[0].charAt(0)}${userNameSplit[0].charAt(1)}`.toUpperCase();
        }
    };

    return (
        <nav>
            <div className={styles.main}>
                <div className={styles.brandLogo} onClick={() => navigate("/")}>
                    <img src={MeritoDarkLogo} alt="" />
                </div>

                <div className={styles.navbarItem}>
                    <div ref={parentRef} className={styles.userAvatar} onClick={() => setMenuState(!menuState)}>
                        {renderName()}
                    </div>


                    <div ref={outSideClickRef} className={`${styles.headerMenuPopover} ${menuState ? styles.show : ''}`}>
                        <div className={styles.menuItem} onClick={() => {
                            navigate('/profile');
                            setMenuState(false);
                        }
                        }>
                            <div className={styles.icon}>
                                <ProfileIcon />
                            </div>
                            <div className={styles.title}>
                                Profile
                            </div>
                        </div>
                        <div className={styles.menuItem} onClick={() => {
                            clearLocalStoageOnLogout();
                            setMenuState(false);
                            navigate('/signin', { replace: true })
                        }}>
                            <div className={styles.icon}>
                                <LogoutIcon />
                            </div>
                            <div className={styles.title}>
                                Logout
                            </div>
                        </div>
                    </div>
                </div>



                {/* </div> */}
            </div>
        </nav>
    );
}

export default Header;