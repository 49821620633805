import styles from './devinprogress.module.scss'
import  { BsPencil } from "react-icons/bs";
import reviewStyle from './../createWorkspace/Steps/steps.module.scss'
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { RestS } from '../../rest/rest-service';
import { IPWorkspace } from '../../interfaces/interfaces';

const DevInProgress=(props: any)=> {
    const [sideBarCompo, setSideBarCompo]:any = useOutletContext();
    const [ searchParams ] = useSearchParams()
    const navigate = useNavigate()
    const [ workspace, setWorkspace ] = useState<IPWorkspace>()
    // const [ dataSet, setDataSet ] = useState<IDataSet>()

    useEffect(()=>{
        RestS.getIPWorkspaceById(searchParams.get('id'))
        .then((res) => {
            console.log(res)
            setWorkspace(res)
            let temp = new Date(res?.updatedAt)
            const lastUpdatedDate = `${temp.toLocaleString('default', { month: 'short' })} ${temp.getDate()<10?'0':''}${temp.getDate()}, ${temp.getFullYear()}` 
            setSideBarCompo({
                lastUpdatedDate: lastUpdatedDate,
                collapsed: true
            })
        })
    }, [])

    if(!searchParams.get('id')) {
        return <Navigate to='/' />
    }
    return(
        <>
        {
            workspace ? 
                <div className={styles.devPgrsCont}>
                    <div className={styles.Header}>
                        <h1>{workspace.workspaceName}</h1>
                        {/* <p>{location.state?.workspace.shortDesc}</p>
                        <p>{location.state?.workspace.longDesc}</p> */}
                        {/* <div onClick={()=>{}} className={styles.reviewPencilIcon}><BsPencil /></div> */}
                    </div>
                    <div className={reviewStyle.reviewNSubmitCont}>
                        <div className={reviewStyle.reviewInner} id={styles.reviewInner}>
                            <div className={reviewStyle.reviewDataCont} id={styles.inputData}>
                                <div className={reviewStyle.ReviewtitleCont}>
                                    <p className={reviewStyle.reviewTitle}>Input Data</p>
                                </div>
                                <label>Product Name</label>
                                <p className={reviewStyle.reviewValue}>{workspace.productName}</p>
                                <label>Dataset Name</label>
                                <p className={reviewStyle.reviewValue}>{workspace.dataSet?.dataSetName}</p>
                                <label>Frequency of Data Refresh</label>
                                <p className={reviewStyle.reviewValue}>{workspace.refreshInterval}{workspace.refreshFrequency!=''?`, ${workspace.refreshFrequency}`:''}</p>
                                <label>Uploaded files count</label>
                                <p className={reviewStyle.reviewValue}>{workspace.dataSet?.attachments ? workspace.dataSet?.attachments.length : 0} {workspace.dataSet?.attachments?.length==1 ? 'file' : 'files'} uploaded</p>
                            </div>
                        </div>
                        <div className={reviewStyle.reviewInner} id={styles.reviewInner}>
                            <div className={reviewStyle.ReviewtitleCont}>
                                <p className={reviewStyle.reviewTitle}>Customer Context</p>
                            </div>
                            {
                                workspace?.customerContext?.map((cust: any) => {
                                    return(
                                        <>
                                            <label>{cust.question}</label>
                                            <p className={reviewStyle.reviewValue}>{cust.answer}</p>
                                        </>
                                    )
                                    })
                            }
                        </div>
                        <div className={reviewStyle.reviewInner} id={styles.reviewInner}>
                            <div className={reviewStyle.ReviewtitleCont}>
                                <p className={reviewStyle.reviewTitle}>Data Context</p>
                            </div>
                            <label>List metrics that you would like to include in the workspace</label>
                            <div id={reviewStyle.dropScroll} className={reviewStyle.reviewMetricsOuter}>
                                    {
                                        workspace?.metrics?.map((context: any, index: number) => {
                                            if(context != null)
                                            return (
                                                <div style={{background: 'white', padding: '10px 12px 3px'}} className={reviewStyle.displayMetrics}>
                                                    <div>
                                                        <p>{context?.name}</p>
                                                        {/* <BsX onClick={()=>{removeMetrics(index)}}/> */}
                                                    </div>
                                                    <p>{context?.formula}</p>
                                                </div>
                                            )
                                        })
                                    }
                            </div>
                            <label>{workspace.primaryPurpose.question}</label>
                            <p className={reviewStyle.reviewValue}>{workspace.primaryPurpose.answer}</p>
                        </div>
                    </div>
                </div> 
                : null
        }
        </>
    )
}

export default DevInProgress