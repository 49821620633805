import ReactDOM from "react-dom";
import { ModalHeader, Modal, ModalBody } from "reactstrap";
import styles from "./Modal.module.scss";

function DialogBody({ visible, toggle, style, bodyStyle, ...props }) {
    return <div className={styles.ModalDialog}>
        <Modal
            toggle={toggle}
            isOpen={visible}
            style={style}
            centered
            size={props.size ? props.size : "lg"}
        >
            {(props.title || props.subTitle) && <ModalHeader className={`${styles.header} ${props.className && props.className == 'member-popup' ? 'memHeader' : ''}`} toggle={toggle} style={{
                borderBottom: "none"
            }}>

                {props.title}
                <div className={styles.subTitle}>
                    {props.subTitle}
                </div>

            </ModalHeader>}
            {props.pbody && (props.pbody)}
            <ModalBody style={bodyStyle} className={`${styles.body} ${props.bodyClass ? props.bodyClass : ''}`}>
                {props.children}
            </ModalBody>
        </Modal>
    </div>
}

const ModalDialog = (props) => props.visible ? ReactDOM.createPortal(DialogBody(props), document.body) : null;

export default ModalDialog;