import { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom'
import styles from './IC.module.scss'
import highFive from './../../resources/images/highFive.svg'
import { BsArrowRight } from 'react-icons/bs';
import { RestS } from '../../rest/rest-service';
import Loader from '../../components/common/Loader';
import { IPWorkspace } from '../../interfaces/interfaces';

const IC=()=>{
    const [sideBarCompo, setSideBarCompo]:any = useOutletContext()
    const [ workspace, setWorkspace ] = useState<IPWorkspace>()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true);
    const [chartTitles, setChartTitles] = useState<string[]>([])
    const [ searchParams ] = useSearchParams()

    useEffect(()=>{
        const workspaceId = searchParams.get('id')
        RestS.getIPWorkspaceById(workspaceId)
        .then(res => {
            console.log(res)
            setWorkspace(res)
            setSideBarCompo({collapsed: true})
            RestS.getProposalCharts(workspaceId)
            .then(res => {
                // console.log(res);
                const charts = res;
                const title = charts.map(chart => chart.title)
                setChartTitles(title)
                setLoading(false);
            }).catch((error) => {
                // console.log(error);
                setLoading(false);
    
            })
        })
    }, [])
    
    if(!searchParams.get('id')) {
        return (
            <Navigate to='/'/>
        )
    }
    return(
        <>
        {
            workspace ? 
            <div className={styles.icCont}>
                <div className={styles.childCont1}>
                    <div>
                        <h1 className={styles.icheading}>High five!<br />Your workspace is all set </h1>
                    </div>
                    <div className={styles.imgCont}>
                        <img src={highFive}/>
                    </div>
                </div>
                <div className={styles.childCont1}>
                    <div className={styles.childCont1_2}>
                        <div>
                            <label>Name of Workspace: </label><p>{workspace.workspaceName}</p>
                        </div>
                        {/* <div>
                            <label>Tags: </label><p>{workspace.primaryPurpose.answer}</p>
                        </div> */}
                        <div>
                            <label>Refresh Frequency: </label><p>{workspace.refreshInterval}</p>
                        </div>
                        <div>
                            <label>Dataset: </label><p>{workspace.dataSet?.dataSetName}</p>
                        </div>
                        <div>
                            <label>Tables: </label>
                            <p>
                            {
                                workspace.dataSet.excelData.length ? 
                                    workspace.dataSet.excelData.map((data, index) => {
                                        if(index == 0)
                                            return `${data.fileName}`
                                        else return `, ${data.fileName} `
                                    })
                                : null
                            }
                            </p>
                        </div>
                        <div className={styles.chartTitles}>
                            <label>Metrics: </label><p>
                                {   loading ?
                                        <Loader color={"#fff"} />
                                        :
                                        chartTitles.map(met => {
                                            return (<li>{met}</li>)
                                            // if(index == 0)
                                            //     return `${met}`
                                            // else return `, ${met} `
                                        })
                                }
                            </p>
                        </div>
                    </div>
                    <div className={styles.childCont1_2}>
                        <p>{`Once the workspaces is integrated and live, make sure to 
                            refresh the following tables on a ${workspace.refreshInterval} basis starting from 22/08/2022 
                            using the Dataset API Customer Insights Warehouse:`}</p>
                        <ul>
                            {
                                workspace.dataSet.excelData.length ? 
                                    workspace.dataSet.excelData.map(data => {
                                        return <li>{data.fileName}</li>
                                    })
                                : null
                            }
                            {/* <li>Customers</li>
                            <li>Orders</li>
                            <li>Products</li>
                            <li>Payments</li>
                            <li>Stores</li> */}
                        </ul>
                    </div>
                    {/* <div className={styles.childCont1_2}>Finalize Workspace<BsArrowRight /></div> */}
                    <p className={styles.childCont1_2}>Disclaimer  : The data tables will be segregated for different customers on the basis of Customer ID.</p>
                </div>
            </div>
            : null
        }
        </>
    )
}

export default IC