import { useState, createContext } from "react";
import { Outlet } from "react-router-dom";
import { setLocalStorageOnLogin, updateIpUser } from "../../../components/common/functions/utilites";
import { IntegrationPartner } from "../../../interfaces/interfaces"



type ProfileContextType = {
    integrationPartner: IntegrationPartner;
    updateIntegrationPartner: (user: IntegrationPartner) => void;
}

export const ProfileContext = createContext<ProfileContextType | null>(null);

export const ProfileProvider = ({ children }) => {
    const [integrationPartner, setIntegrationPartner] = useState<IntegrationPartner>(null);

    const updateIntegrationPartner = (user: IntegrationPartner) => {
        const newIntegrationPartner = {
            ...user,
        }
        setIntegrationPartner(newIntegrationPartner);
        updateIpUser(user);
    }

    return <ProfileContext.Provider value={{ integrationPartner, updateIntegrationPartner }}>
        {children}
    </ProfileContext.Provider>

}

export default ProfileProvider;