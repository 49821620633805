import styles from './ChartInfo.module.scss'
import { BsX } from 'react-icons/bs'

const ChartInfo = ({ overview, action, toggle, visible, chartIndex }) => {

    // useOnClickOutside(childRef, ref, (e) => {console.log("asdhgasd"); toggle()});

    const getOddEvenId = () => {
        if (chartIndex % 2 == 0) {
            return styles.even
        } else return styles.odd
    }

    return (
        <>
            {
                visible ?
                    <div className={styles.main}>
                        <div className={`${getOddEvenId()} ${styles.body}`}>
                            {overview &&
                                <>
                                    <div>
                                        <h1>Overview:</h1>
                                        <BsX onClick={() => { toggle() }} />
                                    </div>
                                    <p>{overview}</p>
                                </>
                            }
                            {/* <h1 id={styles.title1}>What action should you take?</h1>
                            <p>{action}</p> */}
                        </div>
                    </div> :
                    null
            }
        </>
    )
}

export default ChartInfo