import {
  Chart,
  IDataSet,
  IPWorkspace,
  IPWorspacePagination,
} from "../interfaces/interfaces";
import axios from "axios";
import {
  clearLocalStoageOnLogout,
  getLocalJwt,
} from "../components/common/functions/utilites";
import API from "./api";
import { IntegrationPartner } from "../interfaces/interfaces";

export const JIGSAW_DEV_REST_LOCATION = `${process.env.REACT_APP_API_URL}`;

class RestService {
  // getAllTrendPivot = (reportVersionId: number, pivotIdentifier: string): Promise<any> => {
  //   return new Promise((resolve, reject) => {
  //     API.post('/Reports/getAllPivotTrendData', {
  //       reportVersionId,
  //       pivotIdentifier
  //     })
  //       .then(res => resolve(res.data))
  //       .catch(reject)
  //   })
  getIPWorkspace = (): Promise<IPWorkspace[]> => {
    return new Promise((resolve, reject) => {
      API.get(`${JIGSAW_DEV_REST_LOCATION}/api/ipWorkspace/get`, {})
        .then(async (res) => {
          // console.log(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getIPWorkspaceByDS = (dataSet: string): Promise<IPWorkspace[]> => {
    return new Promise((resolve, reject) => {
      API.get(`${JIGSAW_DEV_REST_LOCATION}/api/ipWorkspace/getByDS`, {
        params: { dataSet: dataSet },
      })
        .then(async (res) => {
          // console.log(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getIPWorkspaceByIP = (page: number): Promise<IPWorspacePagination> => {
    return new Promise((resolve, reject) => {
      API.get(`${JIGSAW_DEV_REST_LOCATION}/api/ipWorkspace/getByIP`, {
        params: {
          page: page,
        },
      })
        .then((res: any) => {
          resolve({
            data: res.data.data,
            count: res.data.count,
            pageCount: res.data.pageCount,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  addIPWorkspace = (IPWorkspace: IPWorkspace): Promise<any> => {
    return new Promise((resolve, reject) => {
      API.post(`${JIGSAW_DEV_REST_LOCATION}/api/ipWorkspace/add`, IPWorkspace)
        .then(async (res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  verifyJwt = (): Promise<boolean> => {
    let jwt = getLocalJwt();
    return new Promise((resolve, reject) => {
      API.post("/integrationpartner/verifyjwt", { jwt })
        .then((res) => {
          if (res.status == 200) {
            resolve(true);
          } else {
            clearLocalStoageOnLogout();
            resolve(false);
          }
        })
        .catch((error) => {
          clearLocalStoageOnLogout();
          resolve(false);
          reject(error);
        });
    });
  };

  uploadDataSet = (formData: any): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      API.post("/dataset/fileUpload", formData)
        .then((res) => {
          if (res.status == 200) {
            resolve(res.data.data);
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error.response.data);
        });
    });
  };

  getProfile = (): Promise<IntegrationPartner> => {
    return new Promise((resolve, reject) => {
      API.get("/integrationpartner/profile")
        .then((res) => {
          if (res.status == 200) {
            resolve(res.data.data);
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error.response.data);
        });
    });
  };

  updateProfile = (formData: FormData): Promise<IntegrationPartner> => {
    return new Promise((resolve, reject) => {
      API.put("/integrationpartner/profile", formData)
        .then((res) => {
          if (res.status == 200) {
            resolve(res.data.data);
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error.response.data);
        });
    });
  };

  checkPassword = (password: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      API.post("/integrationpartner/checkpassword", { password })
        .then((res) => {
          if (res.status == 200) {
            resolve(true);
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error.response.data);
        });
    });
  };

  changePassword = (values: any): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      API.post("/integrationpartner/updatepassword", values)
        .then((res) => {
          if (res.status == 200) {
            resolve(true);
          }
        })
        .catch((error) => {
          // console.log(error);
          reject(error.response.data);
        });
    });
  };
  updateDataSet = (id: string, formData: FormData): Promise<IDataSet> => {
    return new Promise((resolve, reject) => {
      API.put(`/dataset/${id}`, formData)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  };

  getDataSetById = (id: string): Promise<IDataSet> => {
    return new Promise((resolve, reject) => {
      API.get("/dataset/getById", { params: { id } })
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          // console.log(error)
          reject(error);
        });
    });
  };

  getProposalCharts = (ipWorkspace: string): Promise<Chart[]> => {
    return new Promise((resolve, reject) => {
      API.get("/proposalCharts/getByIP", { params: { ipWorkspace } })
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => {
          // console.log(error)
          reject(error);
        });
    });
  };

  updateProposalCharts = (charts: Chart[]): Promise<Chart[]> => {
    return new Promise((resolve, reject) => {
      API.post("/proposalCharts/update", charts)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => {
          // console.log(error)
          reject(error);
        });
    });
  };

  updateIpWorkspace = (
    workspace: IPWorkspace,
    emailBody?: any[]
  ): Promise<IPWorkspace> => {
    return new Promise((resolve, reject) => {
      emailBody = emailBody ? emailBody : [];
      const body = { workspace, emailBody };
      API.put("/ipWorkspace/update", body)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => {
          // console.log(error)
          reject(error);
        });
    });
  };

  lambdaTesting = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      const body = {
        user: "1",
        dataSet: "sampleRetail",
        method: "workspace",
        workspaceId: "",
      };
      axios
        .post(
          "https://9tr2rs0ttj.execute-api.ap-south-1.amazonaws.com/eda/",
          body
        )
        .then((res: any) => {
          resolve(res.data.body);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  deleteDataset = (id: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      API.delete(`/dataSet/${id}`)
        .then((res) => {
          // console.log(res)
        })
        .catch((error) => {
          // console.log(error)
          reject(error);
        });
    });
  };

  getIPWorkspaceById = (workspaceId): Promise<IPWorkspace> => {
    return new Promise((resolve, reject) => {
      API.get(`${JIGSAW_DEV_REST_LOCATION}/api/ipWorkspace/getById`, {
        params: { id: workspaceId },
      })
        .then(async (res) => {
          console.log(res);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
}

export let RestS = new RestService();
