// import { Filter } from 'react-bootstrap-icons'
import { Table } from 'reactstrap'
import styles from './dashboard.module.scss'
import { FaCheckCircle } from "react-icons/fa";
import { useEffect, useState } from 'react';
import { Filter } from "react-bootstrap-icons";
import { useLocation, useNavigate } from 'react-router-dom';
import { RestS } from '../../rest/rest-service';
import _ from 'lodash';
import { FaChevronRight as RightIcon, FaChevronLeft as LeftIcon } from 'react-icons/fa';
import noDataImage from '../../assets/images/noData.svg';
import Loader from '../../components/common/Loader';
import useAnalyticsEventTracker from '../../components/Layout/useAnalyticsEventTracker';
import ConfirmDialog from '../../components/common/confirmDialog/ConfirmDialog';
import confirmImage from './../../assets/images/ConfirmIcon.svg';
import useModal from '../../components/Hooks/useModal';
import { BsSearch } from "react-icons/bs";
import { getLocalUser } from '../../components/common/functions/utilites';

const DashBoard = () => {
    const navigate = useNavigate();
    const user = getLocalUser();
    const [sorted, setSorted] = useState(0)
    const [orderType, setOrderType] = useState(0)
    const location: any = useLocation()
    const [popupDisplay, setPopupDisplay] = useState(false)
    const [workspaces, setWorkspaces] = useState([]);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [pageNumberChunk, setPageNumberChunk] = useState(0);
    const [pageNumbers, setPageNumbers] = useState([]);
    const [pageNumberLimit, setpageNumberLimit] = useState(2);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(2);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const [loading, setLoading] = useState(true);
    const gaEventTracker = useAnalyticsEventTracker('dashboard');
    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        if (location.state && location.state?.showPopup) {
            setPopupDisplay(true)
            setTimeout(() => {
                setPopupDisplay(false)
                window.history.replaceState({}, document.title)
            }, 1500)
        }
        // setLoading(true);
        RestS.getIPWorkspaceByIP(1)
            .then(res => {
                setPageCount(res.pageCount);
                // if (res.pageCount > 2) {
                const newPageNumbers = Array.from({ length: res.pageCount }, (_, i) => i + 1);
                // console.log(newPageNumbers);
                // const chunkedPageNumbers = _.chunk(newPageNumbers, 2);
                // console.log("new page number", chunkedPageNumbers);
                setPageNumbers(newPageNumbers);
                // }
                setLoading(false);
                setWorkspaces(res.data);
            })
    }, [])


    useEffect(() => {
        RestS.getIPWorkspaceByIP(page)
            .then(res => {
                // setPageCount(res.pageCount);
                // // if (res.pageCount > 2) {
                // const newPageNumbers = Array.from({ length: res.pageCount }, (_, i) => i + 1);
                // console.log(newPageNumbers);
                // // const chunkedPageNumbers = _.chunk(newPageNumbers, 2);
                // // console.log("new page number", chunkedPageNumbers);
                // setPageNumbers(newPageNumbers);
                // }
                setWorkspaces(res.data);
            }).catch((error) => {
                // console.log(error);
            })
    }, [page])


    const sortWorkspace = (sortedNumber) => {
        const tempOrderType = sortedNumber != sorted ? 0 : orderType
        if (sortedNumber != sorted) {
            setOrderType(tempOrderType)
        }
        const sortFnctn = (column: string) => {
            let newWorkspaces = workspaces.sort((a, b) => {
                if (a[column].toLowerCase() < b[column].toLowerCase())
                    return tempOrderType ? 1 : -1
                else
                    return tempOrderType ? -1 : 1
            })
            setWorkspaces(newWorkspaces)
        }
        const getStatusName = (name: string) => {
            switch (name) {
                case 'active': return 'Integration Complete'
                case 'Changes Shared': return 'Working on your feedback'
                case 'inprogress': return 'Development in Progress'
                case 'ready': return 'Ready for Review'
                default: return name
            }
        }
        const sortStatusFnctn = () => {
            let newWorkspaces = workspaces.sort((a, b) => {
                const aTemp = getStatusName(a['status'])
                const bTemp = getStatusName(b['status'])
                if (aTemp.toLowerCase() < bTemp.toLowerCase())
                    return tempOrderType ? 1 : -1
                else
                    return tempOrderType ? -1 : 1
            })
            setWorkspaces(newWorkspaces)
        }
        switch (sortedNumber) {
            case 0: {
                sortFnctn('workspaceName')
                break;
            }
            case 1: {
                sortFnctn('refreshInterval')
                break;
            }
            case 2: {
                let newWorkspaces = workspaces.sort((a, b) => {
                    if (a.dataSet.dataSetName.toLowerCase() < b.dataSet.dataSetName.toLowerCase())
                        return tempOrderType ? 1 : -1
                    else
                        return tempOrderType ? -1 : 1
                })
                setWorkspaces(newWorkspaces)
                break;
            }
            case 3: {
                sortFnctn('productName')
                break;
            }
            case 4: {
                // console.log(tempOrderType)
                let newWorkspaces = workspaces.sort((a, b) => {
                    const date1 = new Date(a.updatedAt)
                    const date2 = new Date(b.updatedAt)
                    if (date1 < date2)
                        return tempOrderType ? 1 : -1
                    else
                        return tempOrderType ? -1 : 1
                })

                setWorkspaces(newWorkspaces)
                break;
            }
            case 5: {
                sortStatusFnctn()
                break;
            }
        }
        setSorted(sortedNumber)
        setOrderType(prev => {
            if (prev) return 0
            return 1
        })
    }

    const renderPageNumber = pageNumbers.map((pageNumber) => {
        if (pageNumber < maxPageNumberLimit + 1 && pageNumber > minPageNumberLimit) {
            return (
                <div onClick={() => setPage(pageNumber)} className={`${styles.pageItem} ${page == pageNumber ? styles.active : ''}`}>{pageNumber}</div>
            )
        } else {
            return null;
        }
    });

    const handleNextBtn = () => {
        setPage(page + 1);

        if (page + 1 > maxPageNumberLimit) {
            setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };

    const handlePrevBtn = () => {
        setPage(page - 1);

        if ((page - 1) % pageNumberLimit == 0) {
            setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
    }

    return (
        <>
            <div className={styles.dashContainer}>
                {loading ? <Loader color={"#fff"} /> :
                    <>
                        <div className={styles.dashContInner}>
                            <h1 className={styles.dashTitle}>Dashboard</h1>
                        </div>
                        {!loading && workspaces && workspaces.length > 0 ?
                            <>
                                <div className={styles.tableCont}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className={styles.reportTableSearchBar}>
                                            <input
                                                // className={activeInput ? '' : styles.InputLock}
                                                maxLength={35}
                                                placeholder="Search"
                                                value={searchValue}
                                                onChange={(e) => {
                                                    setSearchValue(e.target.value)
                                                }}
                                            />
                                            <BsSearch />
                                        </div>
                                        {/* <button onClick={() => { gaEventTracker("dashbord add workspace"); navigate('/createWorkspace') }} className={styles.addWsBtn}>Add Workspace</button> */}
                                    </div>
                                    <Table style={{ marginBottom: 0 }} responsive borderless>
                                        <thead className={styles.tableHeader}>
                                            <tr>
                                                <th onClick={() => sortWorkspace(0)}>
                                                    Workspace Name
                                                    <span className="ps-2">
                                                        <Filter className={`j-cursor ${(sorted == 0 && orderType == 0) ? "" : sorted != 0 ? "" : styles.rotate}`} />
                                                    </span>
                                                </th>
                                                <th onClick={() => sortWorkspace(1)}>
                                                    Refresh Frequency
                                                    <span className="ps-2">
                                                        <Filter className={`j-cursor ${(sorted == 1 && orderType == 0) ? "" : sorted != 1 ? "" : styles.rotate}`} />
                                                    </span>
                                                </th>
                                                <th onClick={() => sortWorkspace(2)}>
                                                    {user.isAdmin ? "Freelancer Name" : "Data set"}
                                                    <span className="ps-2">
                                                        <Filter className={`j-cursor ${(sorted == 2 && orderType == 0) ? "" : sorted != 2 ? "" : styles.rotate}`} />
                                                    </span>
                                                </th>
                                                <th onClick={() => sortWorkspace(3)}>
                                                    Product
                                                    <span className="ps-2">
                                                        <Filter className={`j-cursor ${(sorted == 3 && orderType == 0) ? "" : sorted != 3 ? "" : styles.rotate}`} />
                                                    </span>
                                                </th>
                                                <th onClick={() => sortWorkspace(4)}>
                                                    Modified Date
                                                    <span className="ps-2">
                                                        <Filter className={`j-cursor ${(sorted == 4 && orderType == 0) ? "" : sorted != 4 ? "" : styles.rotate}`} />
                                                    </span>
                                                </th>
                                                <th onClick={() => sortWorkspace(5)}>
                                                    Status
                                                    <span className="ps-2">
                                                        <Filter className={`j-cursor ${(sorted == 5 && orderType == 0) ? "" : sorted != 5 ? "" : styles.rotate}`} />
                                                    </span>
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody className={styles.tableBody}>
                                            {
                                                workspaces?.filter(e => { return (e.workspaceName.includes(searchValue)) })?.map(workS => {
                                                    const date = new Date(workS.updatedAt)
                                                    return (
                                                        <tr onClick={() => {
                                                            if (workS.status == 'inprogress') {
                                                                navigate(`/devinprogress?id=${workS._id}`)
                                                            } else if (workS.status == 'active' || workS.status == 'activeTesting') {
                                                                navigate(`/ic?id=${workS._id}`)
                                                            } else {
                                                                navigate(`/review?id=${workS._id}`, { state: { workspace: workS, showActionBtn: workS.status != "Changes Shared" } })
                                                            }
                                                        }}>
                                                            <td>{workS.workspaceName}</td>
                                                            <td>{workS.refreshInterval}</td>
                                                            <td>{user.isAdmin ? workS["dsName"] ? workS["dsName"] : `-` : workS.dataSet?.dataSetName}</td>
                                                            <td>{workS.productName}</td>
                                                            {/* <td>{`${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}, ${date.getFullYear()}`}</td> */}
                                                            <td>{`${date.getDate() < 10 ? '0' : ''}${date.getDate()}/${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}/${date.getFullYear()}`}</td>
                                                            {
                                                                workS.status == 'inprogress' ?
                                                                    <td className={styles.dip}>Development in progress</td> :
                                                                    workS.status == 'active' || workS.status == 'activeTesting' ?
                                                                        <td className={styles.ic}>Integration Completed</td> :
                                                                        workS.status == 'Changes Shared' ?
                                                                            <td className={styles.dip}>Working on your feedback</td> :
                                                                            <td id={styles.rfrId}><p className={styles.rfr}>Ready for Review</p></td>
                                                            }

                                                            <td></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </ Table>
                                    {pageNumbers.length > 0 ? <div className={styles.paginationFooter}>
                                        {page == pageNumbers[0] ? null : <div className={styles.paginationArrowIcon} onClick={() => handlePrevBtn()}>
                                            <LeftIcon />
                                        </div>}
                                        {pageCount > 0 ?
                                            <div className={styles.pageList}>
                                                {renderPageNumber}
                                            </div>
                                            : null}
                                        {page == pageNumbers[pageNumbers.length - 1] ? null : <div className={styles.paginationArrowIcon} onClick={() => handleNextBtn()}>
                                            <RightIcon />
                                        </div>}
                                    </div> : null}
                                </div>
                            </> :
                            null
                        }
                        {loading ? null : !workspaces || workspaces.length == 0 ?
                            <div className={styles.noDataContainer}>
                                <div className={styles.imgContainer}>
                                    <img src={noDataImage} />
                                </div>
                                <div className={styles.textContainer}>
                                    <div className={styles.title}>
                                        No worksace to show
                                    </div>
                                    <div className={styles.subtitle}>
                                        List of workspaces will appear here after you create one
                                    </div>
                                </div>


                                <button className={styles.btn} onClick={() => { gaEventTracker("dashbord request workspace"); navigate('/createworkspace') }}>
                                    Request a new workspace
                                </button>
                            </div> : null}
                    </>}

            </div>
            {/* <div className={styles.workspacePopup} style={{ display: popupDisplay ? 'flex' : 'none' }}>
                    <div>
                        <FaCheckCircle />
                        <h1>
                            Workspace request recieved
                        </h1>
                        <p>
                            We'll update you as soon as your <br />workspace is ready
                        </p>
                    </div>
                </div> */}
            <ConfirmDialog bodyStyle={{ padding: "2rem 0" }} showButton={false} visible={popupDisplay} toggle={() => {
                setPopupDisplay(prev => !prev)
            }} title="Workspace request recieved" subTitle={<p>We'll update you as soon as your <br />workspace is ready</p>}>

                <div className={`confirmIcon rounded-circle`}>
                    <img src={confirmImage} />
                </div>

            </ConfirmDialog>
        </>
    )
}

export default DashBoard
