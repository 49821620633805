import React from 'react';
import styles from './ConfirmButtons.module.scss';

type Props = {
    onClick: () => void,
    children: React.ReactNode,
    style?: React.CSSProperties,
    disabled?: boolean,
    loading?: boolean,
    [x: string]: any,
}

const CustomButton = ({ onClick, children, style, loading = false, disabled = false, ...props }: Props) => {

    return (
        <button onClick={onClick} style={style} disabled={disabled} className={`${styles.btn} ${loading ? styles.loading : ''}`} {...props}>
            <div className={styles.buttonText}>
                {children}
            </div>
        </button>
    );
}

export default CustomButton;