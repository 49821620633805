import styles from './HeroSection.module.scss';
import JigsawLogo from '../../../assets/images//White jigsaw logo.png';
import MeritoWhiteLogo from '../../../assets/images/MeritoWhiteLogo.svg';
import { useNavigate } from 'react-router-dom';


const HeroSection = () => {
    const navigate =  useNavigate()

    return (
        <div className={styles.main}>
            <div className={styles.detailsContainer}>
                <div className={styles.logo}>
                    <img onClick={()=>{navigate('/')}} style={{cursor: "pointer"}} src={MeritoWhiteLogo} />
                </div>

                <div className={styles.textContainer}>
                    <div className={styles.title}>
                        Build insightful <br></br>
                        data dashboards for your <br></br>
                        customers without any hassle
                    </div>
                    <div className={styles.subtitle}>
                        And it's free
                    </div>
                </div>
            </div>

        </div>
    );
}

export default HeroSection;