import styles from './Sidebar.module.scss';
import { TbPlugConnected as ApiIcon } from 'react-icons/tb'
import { ColumnsGap, FileBarGraph } from 'react-bootstrap-icons'
import { HiOutlineDocumentText as DocumentIcon } from 'react-icons/hi';
import { FiChevronLeft as LeftIcon, FiChevronRight as RightIcon } from 'react-icons/fi'
import { NavLink, useRoutes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { BsChevronLeft, BsViewStacked } from "react-icons/bs";
import { BsSearch } from 'react-icons/bs'

const menus = [
    {
        title: "Your Dashboards",
        icon: <ColumnsGap />,
        location: "/"
    },
    {
        title: "Order Dashboard",
        icon: <FileBarGraph />,
        location: "/createWorkspace",
        isLocked: true,
    },
    {
        title: "API Integration",
        icon: <ApiIcon />,
        location: "/apiintegration",
        isLocked: true,
    },
    // {
    //     title: "API Document",
    //     icon: <DocumentIcon />,
    //     location: '/apidocument'
    // },
]

const SideBar = (props: any) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const homeSideBar = [
        menus.map((item, index) => {
            return (
                <NavLink to={item.location} className={(data) => {
                    let navClass = `${styles.navLink}`;

                    if (data.isActive) {
                        navClass += ` ${styles.active}`
                    }
                    if (item.isLocked) {
                        navClass += ` ${styles.disable}`
                    }
                    return navClass
                }}>
                    <div className={styles.icon}>
                        <div>{item.icon}</div>
                    </div>
                    {isCollapsed ? null : <>
                        <div>
                            <div>{item.title}</div>
                            {item.isLocked &&
                                <div className={styles.commingSoon}>Coming Soon</div>}
                        </div>
                    </>}
                </NavLink>
            )
        })
    ]

    useEffect(() => {
        setIsCollapsed(props.sideBarCompo?.collapsed)
    }, [props.sideBarCompo?.collapsed])

    const reviewSideBar = [
        <div className={styles.reviewSide}>
            <NavLink className={styles.backBtn} style={{ width: isCollapsed ? 'auto' : '9rem' }} to={'/'}><BsChevronLeft />{isCollapsed ? '' : ' Back'}</NavLink>
            {
                isCollapsed ? null :
                    <>
                        <div className={styles.reviewSideBar}>
                            <div className={styles.metrics}>
                                <h2>Filter By Metrics</h2>
                                <div className={styles.metricsSearch}>
                                    <input type='text' placeholder="Search Metrics" value={props.metricsSearchValue} onChange={(e) => props.setMetricsSearchValue(e.target.value)} />
                                    <BsSearch />
                                </div>
                                <div className={styles.metricsElement}>
                                    {/* {
                                    charts?.length > 0 ?
                                        location.state.workspace?.metrics.map(data => {
                                            return (
                                                <div onClick={() => handleMetrics(data.name)}><div id={tempMetrics.includes(data.name) ? styles.selected : null} className={styles.boxInput}></div><p>{data.name}</p></div>
                                            )
                                        })
                                        : null
                                } */}
                                </div>
                            </div>

                            <hr />
                            {
                                props.sideBarCompo?.workspace ?
                                    <div className={styles.workspaceDetail}>
                                        <h2>Workspace:  <b>{props.sideBarCompo.workspace?.workspaceName}</b></h2>
                                        <h2>Product:  <b>{props.sideBarCompo.workspace?.productName}</b></h2>
                                        <h2>Refresh Interval:  <b>{props.sideBarCompo.workspace.refreshInterval}</b></h2>
                                        <h2>Dataset:  <b>{props.sideBarCompo.workspace?.dataSet.dataSetName}</b></h2>
                                        {/* <h2>Metrics:  <b>
                                        {
                                            charts?.map((chart, index) => {
                                                if (index == 0)
                                                    return chart.title
                                                else
                                                    return ', ' + chart.title
                                            })
                                        }
                                        {
                                            location.state.workspace ? location.state.workspace.metrics.map((metric, index) => {
                                                if (index == 0)
                                                    return metric.name
                                                else
                                                    return ', ' + metric.name
                                            }) : null
                                        }
                                    </b></h2> */}
                                    </div>
                                    : null
                            }
                        </div>
                    </>
            }
        </div>
    ]

    const renderSideBar = () => {
        switch (window.location.pathname) {
            case '/': return homeSideBar
            case '/createWorkspace':
            case '/createworkspace': return homeSideBar
            case '/devinprogress': return <NavLink style={{ width: isCollapsed ? 'auto' : '9rem' }} className={styles.backBtn} to={'/'}><BsChevronLeft />{isCollapsed ? '' : ' Back'}</NavLink>
            case '/ic': return <NavLink style={{ width: isCollapsed ? 'auto' : '9rem' }} className={styles.backBtn} to={'/'}><BsChevronLeft />{isCollapsed ? '' : ' Back'}</NavLink>
            case '/review': return reviewSideBar
            default: return <></>
        }
    }

    return (
        <div className={`${styles.sideBarMain} ${isCollapsed ? styles.collapsed : ''}`}>
            <div className={`${styles.sideBar}`}>
                <div className={`${styles.navContainer} ${isCollapsed ? styles.collapsed : ''}`}>
                    {renderSideBar()}
                </div>
            </div>
            {
                (!isCollapsed && window.location.pathname == '/devinprogress') ? <div className={styles.lastUpdateText}><p>Last update:</p> <span>{props.sideBarCompo?.lastUpdatedDate}</span></div> : null
            }
            <div id={isCollapsed ? styles.closePosition : styles.openPosition} className={styles.collapsibleContainer} onClick={() => setIsCollapsed(!isCollapsed)}>
                {isCollapsed ? <RightIcon /> : <LeftIcon />}
            </div>
        </div>

    );
}

export default SideBar;