import { useState, useRef } from 'react';
import { Field, Form } from 'react-final-form';
import styles from './UploadModal.module.scss';
import UploaderImage from '../../../../assets/images/UploaderIcon.png';
import OutlinedInput from '../../../../components/common/OutlinedInput/OutlineInput';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays'
import { RiDeleteBinFill } from 'react-icons/ri';
import { RestS } from '../../../../rest/rest-service';
import { toast } from 'react-toastify';
import { reqiredValidation } from '../../../../components/common/functions/formValidation';
import { useNavigate } from 'react-router-dom';
import useModal from '../../../../components/Hooks/useModal';
import { AiOutlineClose as CloseIcon } from 'react-icons/ai'

import { FORM_ERROR } from 'final-form';
import { Modal } from 'react-bootstrap';
import useAnalyticsEventTracker from '../../../../components/Layout/useAnalyticsEventTracker';
import { CommonError } from '../../../../interfaces/interfaces';

type uploadInitialValuesType = {
    dataSetName: string;
    files: File[],
}

let uploadInitialValues = {
    dataSetName: "",
    files: [],
}

type Props = {
    hideModal: Function
}

const UploadData = ({ hideModal }: Props) => {
    const [initialUpload, setInitialUpload] = useState(true);
    const inputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()
    const { toggle: modalToggle, visible: modalVisible } = useModal();
    const [errorMessage, setErrorMessage] = useState(null);
    const gaEventTracker = useAnalyticsEventTracker('Upload CSV Modal');

    const onSubmit = async (values) => {
        // console.log(values);
        let errors = {
            value: false,
            message: {},
        }
        let formData = new FormData();
        formData.append('dataSetName', values['dataSetName']);
        for (const file of values['files']) {
            formData.append('file', file);
        }
        setIsLoading(true);
        await RestS.uploadDataSet(formData).then((res) => {
            // console.log(res)
            setIsLoading(false);
            hideModal();
            gaEventTracker("Upload CSV - Done")
            navigate('/workspace', { state: { dataSet: res } })
        }).catch((error) => {
            setIsLoading(false);
            const errorData = error.errors as CommonError[]
            if (errorData && errorData.length > 0) {
                const parseError = errorData.reduce((prev, currVal) => {
                    prev[currVal.field] = currVal.message
                    return prev;
                }, {});

                errors = {
                    value: true,
                    message: parseError
                }
            } else {
                errors = {
                    value: true,
                    message: {
                        [FORM_ERROR]: "Something went wrong"
                    }
                }
            }
        })

        if (errors.value == true) {
            // console.log(errors);
            return errors.message
        }
    }

    const handleFileChange = (e: any, onChange, getFieldState) => {
        // console.log(e.target.files);
        if (e.target.files.length == 0) return;
        const field = getFieldState("files");
        const files = e.target.files as File[];
        // let error = false;
        // for (const file of files) {
        //     // var size = parseFloat(Number(file.size / ).toFixed(2));
        //     // console.log(size);
        //     console.log(file.size, 5 * 1024 * 1024)
        //     if (file.size > (5 * 1024 * 1024)) {
        //         setErrorMessage('Please upload a files less than 5mb');
        //         modalToggle();
        //         error = true;
        //         break;

        //     }
        //     // if (!/\.(xlsx|csv|xls)$/i.test(file.name)) {
        //     //     setErrorMessage('Please upload only xlsx, csv or xls files');
        //     //     modalToggle();
        //     //     error = true;
        //     //     break;
        //     // }
        // }
        // if (error) return;
        if (field && field.value && field.value.length > 0) {
            onChange("files", [...field['value'], ...e.target.files]);
        } else {
            onChange("files", [...e.target.files]);
        }
        setInitialUpload(false);
    }

    const checkValidExtension = (value) => {
        const extensions = ["xlsx", "csv", "xls"]
        if (value) {
            const extension = value['name'].toString().split(".");
            const isValid = extensions.includes(extension[extension.length - 1]);
            if (!isValid) {
                return `Invalid format. Please try again uploading xlsx or csv file`;
            }
            if (value.size > (5 * 1024 * 1024)) {
                return `Invalid file. Upload files less than 5mb`
            }
        }
    }

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.title}>
                    Upload your data here
                </div>

                <div className={styles.closeIcon} onClick={() => hideModal()}>
                    <CloseIcon />
                </div>

            </div>

            <Form initialValues={uploadInitialValues} onSubmit={(values) => onSubmit(values)} validate={values => {
                const errors = {};
                if (values.files.length == 0) {
                    errors['uploadFile'] = `Please upload file`;
                }
                return errors;
            }} mutators={{ ...arrayMutators }} render={({ handleSubmit, form: { change, getFieldState, resetFieldState }, pristine, hasValidationErrors, values }) => {
                return <form onSubmit={handleSubmit}>
                    <div className={styles.formContainer}>
                        <div className={styles.formSection}>
                            {!initialUpload ?
                                <>
                                    <Field name="dataSetName" validate={(value) => reqiredValidation(value, "Data set name")}>
                                        {props => {
                                            return <OutlinedInput
                                                maxLength={20} {...props.input}
                                                error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null}
                                                value={props.input.value} onChange={props.input.onChange} placeholder="Name your dataset" />
                                        }}
                                    </Field>
                                    <div className={styles.fileList}>
                                        <FieldArray name="files">
                                            {({ fields }) => fields.map((file, index) => {
                                                return <Field name={file} validate={checkValidExtension}>
                                                    {props => {
                                                        return <div onBlur={props.input.onBlur} className={styles.fileCard}>
                                                            <div className={styles.fileDetails}>
                                                                <div className={styles.fileName}>
                                                                    {`${props.input.value['name']}`}
                                                                </div>
                                                                <div className={styles.errorText}>
                                                                    {props.meta.error ? props.meta.error : null}
                                                                </div>
                                                            </div>
                                                            <div className={styles.iconContainer}>
                                                                <div onClick={() => fields.remove(index)}>
                                                                    <RiDeleteBinFill />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    }}
                                                </Field>

                                            })}
                                        </FieldArray>
                                    </div>


                                </>
                                : null}
                            <Field name='uploadFile'>
                                {props => {
                                    return (
                                        <>



                                            <div className={`${styles.intialFileUpload} ${!initialUpload ? styles.intialUploadDone : ''} ${props.meta.error && props.meta.touched ? styles.uploadError : ''}`} onClick={() => {
                                                inputRef.current.click()
                                            }}>
                                                <input ref={inputRef} onChange={(e: any) => {
                                                    handleFileChange(e, change, getFieldState);
                                                    props.input.onBlur(e);
                                                }} type="file" accept='.xls,.xlsx,.csv' multiple />

                                                <div>
                                                    <img src={UploaderImage} />
                                                </div>
                                                <div className={styles.uploadText}>
                                                    <div className={styles.title}>
                                                        Select a .xls or .csv file to upload
                                                    </div>
                                                    <div className={styles.subtitle}>
                                                        Upload file upto 5MB
                                                    </div>
                                                </div>
                                            </div>

                                            {props.meta.error && props.meta.touched ?

                                                <div className={styles.formError}>{props.meta.error}</div> : props.meta.submitError ?
                                                    <div className={styles.formError}>{props.meta.submitError}</div> : null}
                                        </>

                                    )
                                }}
                            </Field>

                        </div>

                        <div className={styles.btnContainer}>
                            <button
                                type="reset"
                                className={styles.cancelBtn}
                                onClick={(e) => {
                                    e.preventDefault();
                                    gaEventTracker("Upload CSV - cancel");
                                    hideModal()
                                }}
                                disabled={isLoading}
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                disabled={pristine || isLoading || hasValidationErrors}

                                className={`${styles.proceedBtn} ${isLoading ? styles.loading : ''}`}
                                onClick={handleSubmit}
                            // onClick={sampleSubmit}


                            >
                                <div className={styles.buttonText}>
                                    Done
                                </div>
                            </button>
                        </div>

                    </div>
                </form>


            }} />


        </div>
    );
}

export default UploadData;