import { ArrowsAngleContract } from "react-bootstrap-icons";
import { Modal, ModalBody } from "reactstrap";
// import PivotTableComponent from "../../chart/pivot";
import styles from "./ExpandView.module.scss";
import commonStyle from "../../../resources/styles/common.module.scss";
import { useEffect, useState } from "react";
import Lock from "../../../resources/images/lock.svg";
import ChartDataPiece from "../DataPeice/chartDataPiece";
import { Chart } from "../../interfaces/interfaces";
import GetChart from "../functions/getChart";
import PivotTableComponent from "../chart/pivot";

type Props = {
    isChartExpand: boolean;
    expandedChartData: any;
    toggleView: Function
};

function ExpandView({ isChartExpand, expandedChartData, toggleView }: Props) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <Modal
            toggle={() => toggleView()}
            isOpen={isChartExpand}
            centered
            size="lg"
        >
            {/* <button onClick={toImage}>image</button> */}
            <ModalBody className={styles.modalBodyClass}>
                <div className={`d-flex ${expandedChartData?.chartIdentifier.includes('table') ? 'pb-4' : 'pb-2'} ${styles.colorGrey}`}>
                    {/* <ArrowsAngleContract
                        onClick={() => {
                            toggleView();
                        }}
                        className="ms-auto"
                    /> */}
                    <div className={styles.iconContainer} onClick={() => {
                        toggleView();
                    }}>
                        <ArrowsAngleContract />
                    </div>

                </div>
                {
                    expandedChartData?.chartIdentifier.includes('table') ?
                        <PivotTableComponent tableData={expandedChartData} />
                        :
                        <GetChart chart={expandedChartData} />
                }
                {expandedChartData?.overview && expandedChartData?.action ?
                    <div className={styles.infoBody}>
                        <h1>Overview: </h1>
                        <p>{expandedChartData.overview}</p>
                        {/* <h1 id={styles.title1}>What action should you take?</h1>
                        <p>{expandedChartData.action}</p> */}
                    </div>
                    : null
                }
            </ModalBody>
        </Modal>
    );
}

export default ExpandView;
