import styles from './ProfileView.module.scss';
import { BiChevronLeft as BackButton } from 'react-icons/bi'
import { useContext } from 'react';
import { ProfileContext } from '../../context/ProfileContext';
import { Navigate, useNavigate } from 'react-router-dom';
import ProfileImage from '../profileImage/ProfileImage';



const ProfileView = () => {
    const { integrationPartner } = useContext(ProfileContext);
    const navigate = useNavigate();

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.backButton} onClick={() => navigate('/')}>
                    <BackButton />
                </div>
                <div className={styles.title}>
                    Back to Home
                </div>
            </div>

            <div className={styles.profileBody}>

                {integrationPartner &&
                    <>
                        <div className={styles.nameSection}>
                            <ProfileImage profileImage={integrationPartner.profileImage} fallback={integrationPartner.name} />
                            <div className={styles.name}>
                                {integrationPartner.name}
                            </div>
                            <div className={styles.companyName}>
                                {integrationPartner.companyName}
                            </div>
                        </div>

                        <div className={styles.otherDetailsSection}>
                            <div className={styles.detailsContainer}>
                                <div className={styles.label}>
                                    Email:
                                </div>
                                <div className={styles.value}>
                                    {integrationPartner.email}
                                </div>
                            </div>
                            <div className={styles.detailsContainer}>
                                <div className={styles.label}>
                                    Mobile No:
                                </div>
                                <div className={styles.value}>
                                    {integrationPartner.contactNumber}
                                </div>
                            </div>
                        </div>

                        <div className={styles.btnContainer}>
                            <button className={styles.outlined} onClick={() => navigate('/profile/password')}>Reset Password</button>
                            <button className={styles.filled} onClick={() => navigate('/profile/edit')}>Edit Profile</button>
                        </div>
                    </>}


            </div>



        </div>


    );
}

export default ProfileView;