import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import DashBoard from "../DashBoard/DashBoard";

type Props = {
    title: string;
}

const Home = ({ title }: Props) => {
    const [sideBarCompo, setSideBarCompo]: any = useOutletContext();

    useEffect(() => {
        document.title = title || "Merito"
        setSideBarCompo({ collapsed: false })
    }, [])


    return (
        <DashBoard />
    );
}

export default Home;