import { useEffect } from 'react'
import styles from './WelcomePage.module.scss';
import HeyImage from '../../../assets/images/greetImage.png';
import ApiIntegrationImg from '../../../assets/images/apiIntegration.png';
import ExcelIntegrationImg from '../../../assets/images/excelIntegration.png';
import UploadData from '../components/UploadData/UploadData';
import { useState } from 'react';
import ModalDialog from '../../../components/common/Modal';
import useAnalyticsEventTracker from '../../../components/Layout/useAnalyticsEventTracker';

type Props = {
    title: string;
}

const WelcomePage = ({ title }: Props) => {
    const [showModal, setShowModal] = useState(false);
    const gaEventTracker = useAnalyticsEventTracker('create workspace');

    const toggleUploadModal = (value: boolean) => {
        setShowModal(value);
    }

    useEffect(() => {
        document.title = title || "Merito"
    }, [])

    return (
        <>
            <div className={styles.main}>
                <div className={styles.topSection}>
                    <div className={styles.messageSection}>
                        <div className={styles.title}>
                            Welcome to Merito
                        </div>
                        <div className={styles.subTitle}>
                            <div className={styles.question}>What is a workspace</div>
                            <div className={styles.details}>
                                A Workspace is a collaborative dashboard of useful metrics for your customer’s activity on your product. Your customers can securely access this dashboard from their Merito account, which they can collaborate within their team directly from dashboard
                            </div>
                        </div>

                    </div>
                    <div className={styles.greetImageSection}>
                        <div className={styles.imageContainer}>
                            <img src={HeyImage} />
                        </div>
                    </div>
                </div>
                <div className={styles.bottomSection}>
                    <div className={styles.title}>
                        Share sample data set through
                    </div>

                    <div className={styles.uploadTypeSection}>
                        <div className={styles.grid}>
                            <div className={styles.imageContainer}>
                                <img src={ApiIntegrationImg} />
                            </div>
                            <div className={styles.textSection}>
                                <div className={styles.title}>
                                    API integration
                                </div>
                                <div className={styles.subtitle}>
                                    Integrate our secure API and push a sample dataset. We’ll then build an insightful Workspace custom built to your customers.
                                </div>
                            </div>
                        </div>
                        <div className={styles.grid}>
                            <div className={styles.imageContainer}>
                                <img src={ExcelIntegrationImg} />
                            </div>
                            <div className={styles.textSection}>
                                <div className={styles.title}>
                                    Upload data
                                </div>
                                <div className={styles.subtitle}>
                                    Upload and Excel sheets to push a sample dataset. We’ll build an insightful Workspace custom built to your customers. API integration will be needed eventually before the workspace goes live.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.buttonContainer}>
                        <button onClick={() => { gaEventTracker('build workspace'); toggleUploadModal(true); }} className={styles.buildWorkspaceBtn}>Build Workspace </button>
                    </div>
                </div>
            </div>
            <ModalDialog size="lg" visible={showModal}
            // toggle={() => setShowModal(!showModal)} 
            >
                <UploadData hideModal={() => setShowModal(false)} />
            </ModalDialog>
        </>
    );
}

export default WelcomePage;