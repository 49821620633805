export const reqiredValidation = (values, label) => {
  if (!values) {
    return `Required ${label}`;
  }
};

export const cutomEmailValidation = (values) => {
  let pattern =
    /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.in)(?!aol.com)(?!live.com)(?!outlook.com)(?!zoho.com)(?!icloud.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/gm;
  if (!values) {
    return `Required Email Address`;
  } else if (!pattern.test(values)) {
    return "Invalid format. Please use work email ID";
  }
};

export const mobileNumberValidation = (values) => {
  let pattern = /^[0-9]{10}/gm;
  if (!values) {
    return ``;
  } else if (!pattern.test(values)) {
    return "Enter a valid mobile number";
  }
};