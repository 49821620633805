import { IntegrationPartner } from "../../../interfaces/interfaces";

export const getLocalUser = (): IntegrationPartner => {
  let user: IntegrationPartner = JSON.parse(localStorage.getItem("ip_user"));
  return user;
};

export const getLocalJwt = () => {
  return localStorage.getItem("ip_token") || "1";
};

export const clearLocalStoageOnLogout = () => {
  return localStorage.clear();
};

export function setLocalStorageOnLogin(token, user: IntegrationPartner) {
  localStorage.setItem("ip_token", token);
  localStorage.setItem("ip_user", JSON.stringify(user));
}

export function updateIpUser(user: IntegrationPartner) {
  localStorage.setItem("ip_user", JSON.stringify(user));
}
