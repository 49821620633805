import ProfileProvider from "./context/ProfileContext"
import Profile from "./Profile"



const ProfileIndex = () => {


    return (
        <ProfileProvider>
            <Profile title="Profile" />
        </ProfileProvider>
    )
}

export default ProfileIndex;