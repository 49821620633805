import styles from './ReportHeader.module.scss';

type Props = {
    workspaceName: string;
    shareChanges: Function;
    finalizeWs: Function;
    showActionBtn: boolean;
    status: string;
    showFinalizeButton: boolean;
}

const ReportHeader = ({ workspaceName, shareChanges, finalizeWs, showActionBtn, status, showFinalizeButton }: Props) => {

    return (
        <>
            <div className={styles.header}>
                <div className={styles.leftContainer}>
                    <div className={styles.workspaceTitle}>
                        {workspaceName}
                    </div>
                </div>
                {
                    // showActionBtn && status != 'Changes Shared' ? 
                    <div className={styles.rightContainer}>
                        <button className={`${styles.outlinedBtn} ${styles.blue} ${!(showActionBtn && status != 'Changes Shared') ? styles.disable : ""}`} onClick={()=> 
                            {if((showActionBtn && status != 'Changes Shared'))  shareChanges()}}>
                            <div className={styles.btnContent}>
                                <div className={styles.btnText}>
                                    Share Changes
                                </div>
                            </div>
                        </button>
                        <button className={`${styles.outlinedBtn} ${(!(showActionBtn && status != 'Changes Shared')) || !showFinalizeButton ? styles.disable : ""}`} onClick={()=> {if((showActionBtn && status != 'Changes Shared'))  finalizeWs()}}>
                            <div className={styles.btnContent}>
                                <div className={styles.btnText}>
                                    Finalize Workspace
                                </div>
                            </div>
                        </button>

                    </div> 
                    // : null
                }
            </div>
        </>
    );
};

export default ReportHeader;
