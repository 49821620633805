import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styles from './stepper.module.scss'
import checkIcon from '../../../assets/images/CheckIcon.svg';
import './stepperOverRide.css'
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Workspace from '../Steps/Workspace';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { RestS } from '../../../rest/rest-service';

const steps = ['Name Workspace', 'Input Data', 'Customer context', 'Data context', 'Review and Submit'];
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 12,
    left: "calc(-50% + 9px)",
    right: "calc(50% + 8px)"
  },
}));

type Props = {
  title: string
}

export default function MainCreateWS({ title }: Props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [access, setAccess] = React.useState<any>([])
  const [done, setDone] = React.useState<any>([])
  const location: any = useLocation()
  const [disableIndex, setDisableIndex] = useState(5)
  const [dataSet, setDataSet] = useState(location.state?.dataSet)
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.setItem('submit', 'false')
    if (!location.state) {
      navigate('/')
    } else {
      setDataSet(location.state?.dataSet)
      window.history.replaceState({}, document.title)
    }

    const handleTabClose = event => {
      // console.log("tab close")
      event.preventDefault();
      window.history.replaceState({}, document.title)
      // if(dataSet)
      RestS.deleteDataset(dataSet._id)
      // axios.get(`${process.env.REACT_APP_API_URL}/api/charts`)
      // return (event.returnValue = 'Are you sure you want to exit?');
    };

    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.history.replaceState({}, document.title)
      const flag = localStorage.getItem('submit')
      // console.log(flag)
      if (dataSet && flag != 'true') {
        RestS.deleteDataset(dataSet._id)
      }
      // // axios.get(`${process.env.REACT_APP_API_URL}/api/charts`)
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  useEffect(() => {
    document.title = title || "Merito"
  }, [])

  const CustomStepIcon = (props: any) => {
    const { active, completed, icon } = props;
    let isCompleted = done.includes(icon - 1);
    return (
      <div style={{ height: '17px', width: '17px', overflow: 'none' }}>
        {
          active ?
            <div className={`${styles.stepLabels} ${styles.active}`}></div> :
            completed || isCompleted ?
              <img src={checkIcon} /> :
              <div className={`${styles.stepLabels} ${styles.upcomming}`}></div>
        }
      </div>
    );
  };

  const isStepOptional = (step: number) => {
    return step === 2 || step === 3;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    const tempCom = access
    if (!tempCom.includes(activeStep) || !tempCom.includes(activeStep + 1)) {
      tempCom.push(activeStep)
      tempCom.push(activeStep + 1)
    }
    setAccess(tempCom)

    const temp2 = done
    if (!temp2.includes(activeStep)) {
      temp2.push(activeStep)
    }
    setDone(temp2)

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleHyper = (step: any) => {
    if (access.includes(step) && step < disableIndex) {
      setActiveStep(step)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    const tempCom = access
    if (!tempCom.includes(activeStep) || !tempCom.includes(activeStep + 1)) {
      tempCom.push(activeStep)
      tempCom.push(activeStep + 1)
    }
    setAccess(tempCom)

    const temp2 = done
    if (!temp2.includes(activeStep)) {
      temp2.push(activeStep)
    }
    setDone(temp2)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const renderSwitch = (activeStep: number) => {
    return <Workspace step={activeStep} next={handleNext} back={handleBack} skip={handleSkip} edit={handleHyper} dataSet={dataSet} setDisableIndex={setDisableIndex} />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <div className={styles.stepperCont}>
        {activeStep === steps.length ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </>
        ) : (
          <>
            {
              activeStep + 1 == 1 ?
                <div className={`${styles.NameWorkspace} ${styles.formContainer} `}>
                  {renderSwitch(activeStep + 1)}
                </div> :
                activeStep + 1 == 5 ?
                  <div className={`${styles.reviewAndSubmit}`}>
                    {renderSwitch(activeStep + 1)}
                  </div>
                  : <div className={`${styles.stepperProcess} ${styles.formContainer} `}>
                    {renderSwitch(activeStep + 1)}
                  </div>
            }
            {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
            {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}
                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </Box> */}
          </>
        )}
        <Stepper className={styles.stepper} activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            //   if (isStepOptional(index)) {
            //     labelProps.optional = (
            //       <Typography variant="caption">Optional</Typography>
            //     );
            //   }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                {/* {
                    steps[activeStep] == label ?
                      <div className={`${styles.stepLabels} ${styles.active}`}></div>
                      : <div className={`${styles.stepLabels} ${styles.upcomming}`}></div>
                  } */}
                <StepLabel style={{ cursor: (access.includes(index) && index < disableIndex) ? 'pointer' : 'not-allowed' }} onClick={() => { handleHyper(index) }} StepIconComponent={CustomStepIcon} {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
    </Box>
  );
}