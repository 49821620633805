import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAnalyticsEventTracker from '../../../components/Layout/useAnalyticsEventTracker';
import styles from './steps.module.scss'

const Joi = require('joi-browser');

const schema = Joi.object({
    workspaceName: Joi.string().required().label("Workspace Name"),
    // shortDesc: Joi.string().required().label("Short Description")
});

const NameWorkspace = ({ next, getValues, register, setDisableIndex }: any) => {
    const [errors, setErrors] = useState<any>({})
    const [disable, setDisable] = useState<boolean>(true)
    const navigate = useNavigate()
    const gaEventTracker = useAnalyticsEventTracker('Workspace step 1');

    useEffect(() => {
        if (getValues().workspaceName != undefined && getValues().workspaceName != '')
            setDisable(false)
    }, [])

    const validate = (field: string) => {
        const { workspaceName } = getValues()
        const data = { workspaceName }
        const result = Joi.validate(data,
            schema, { abortEarly: false });
        const { error } = result;
        // console.log(error)
        if (error) {
            const errorData: any = {};
            for (let item of error.details) {
                const name = item.path[0];
                const message = item.message;
                if(name == field)
                    errorData[name] = message;
            }
            setDisableIndex(0)
            setDisable(true)
            setErrors(errorData);
        } else {
            setDisableIndex(5)
            setErrors({});
            setDisable(false)
        }
    }

    const handleClick = () => {
        if (!disable) {
            gaEventTracker("complete Create workspace - Step1", "step1")
            next()
        }
    }

    return (
        <div>
            <div>
                <span className={styles.title}>Name Workspace</span>
                <p className={styles.content}>Pick a name that will help your customers easily understand the theme of the dashboard. Don’t worry, you can change this later!</p>
                <input
                    id={(disable&&errors.workspaceName)?styles.invalid:''}
                    {...register("workspaceName", {
                        onChange: (e) => validate("workspaceName")
                    })}
                    maxLength='45'
                    placeholder='Sales channel efficiency, Geographic analysis of customers, User activity dashboard etc…'
                    onKeyDown={(e) => { if (e.keyCode == 13) handleClick() }}
                    className={styles.inputEle}></input>
                {errors.workspaceName ? <p style={{ marginBottom: '-20px'}} className={styles.errorMess}>Workspace Name is required</p>:null}
            </div>
            {/* <div >
                <span className={styles.title}>Description</span>
                <p className={styles.content}>Please provide us a description for your workspace</p>
                <input
                    id={(disable&&errors.shortDesc)?styles.invalid:''}
                    {...register("shortDesc", {
                        onChange: (e) => validate("shortDesc")
                    })}
                    maxLength='60'
                    placeholder='This is predictive analysis report'
                    onKeyDown={(e) => { if (e.keyCode == 13) handleClick() }}
                    className={styles.inputEle} />
                {errors.shortDesc ? <p style={{ marginBottom: '-20px'}} className={styles.errorMess}>Short Description is required</p>:null}
            </div> */}
            {/* <div style={{marginTop: '20px'}}>
                <span className={styles.title}>Long Description</span>
                <p className={styles.content}>Please provide us a long description for your workspace</p>
                <textarea
                    style={{maxHeight: "50px", minHeight: "50px", marginTop: '0px', wordBreak: "break-all"}}
                    {...register("longDesc")}
                    maxLength={200}
                    placeholder='This is predictive analysis report'
                    onKeyDown={(e) => { if (e.keyCode == 13) handleClick() }}
                    className={styles.inputEle} />
            </div> */}
            <div className={styles.buttonContSteps}>
                <div className={styles.buttonCont}><p onClick={() => { gaEventTracker("cancel Create workspace", "step1"); navigate('/') }}>Cancel</p></div>
                <div className={styles.buttonCont}><p className={styles.next} style={{ cursor: disable ? 'not-allowed' : 'pointer', opacity: disable ? '0.7' : '1' }} onClick={handleClick}>Next</p></div>
            </div>
        </div>
    )
}

export default NameWorkspace