import styles from './ProfileImage.module.scss';
import { AiFillEdit as EditIcon } from 'react-icons/ai';
import { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '../../../../components/Hooks/useOnClickOutsideRef';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';

type Props = {
    profileImage: any;
    fallback?: string;
    edit?: boolean;
    onChange?: Function;
    removeImage?: boolean;
    setRemoveImage?: Function;
}



const ProfileImage = ({ profileImage, fallback, onChange, edit }: Props) => {
    const [showEditMenu, setShowEditMenu] = useState(false);
    const outSideClickRef = useRef();
    const parentRef = useRef();
    const inputRef = useRef(null);
    useOnClickOutside(outSideClickRef, parentRef, () => setShowEditMenu(false));
    const [imageUrl, setImageUrl] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (profileImage instanceof File) {
            // console.log(profileImage);
            loadImage(profileImage).then((value) => {
                setImageUrl(value);
            })
        }
        setImageUrl(profileImage);
    }, [profileImage]);

    const loadImage = (file: Blob) =>
        new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () =>
                typeof reader.result === "string" ? resolve(reader.result) : reject(reader.result);
            reader.onerror = error => reject(error);
        });

    const onChangeFiles = (e) => {

        if (e.target.files[0]) {
            // console.log(e.target.files[0]);
            let file = e.target.files[0];
            var size = parseFloat(Number(file.size / (1024 * 1024)).toFixed(2));
            // console.log(size);
            if (size > 1) {
                setErrorMessage('Please upload a image less than 1mb');
                // modalToggle();
                return;
            }
            if (!/\.(jpe?g|png|gif)$/i.test(file.name)) {
                setErrorMessage('Please upload only jpg, jpeg, png images');
                // modalToggle();
                return;
            }
            onChange(e.target.files[0]);
        } else {
            // console.log("no files attached");
        }

        setShowEditMenu(false);
    }

    const renderName = (name: string) => {
        let userNameSplit = name.split(" ");
        if (userNameSplit.length > 1) {
            return `${userNameSplit[0].charAt(0)}${userNameSplit[1].charAt(0)}`
        } else {
            return `${userNameSplit[0].charAt(0)}${userNameSplit[0].charAt(1)}`.toUpperCase();
        }
    };


    return (
        <div className={styles.profileImageContainer}>
            <div className={`${styles.profileImage} ${!profileImage ? styles.noImage : ''}`}>
                {!imageUrl ?
                    <div className={styles.initialText}>
                        {renderName(fallback)}
                    </div>
                    : <img src={imageUrl} />}
                {edit ?
                    <>
                        <div ref={parentRef} className={styles.editIcon} onClick={() => {
                            setShowEditMenu(!showEditMenu);
                            if (errorMessage) {
                                setErrorMessage(null);
                            }
                        }}>
                            <EditIcon />
                        </div>
                        <div ref={outSideClickRef} className={`${styles.menu} ${showEditMenu ? styles.show : ''}`} >
                            <div className={styles.menuItem} onClick={() => {
                                onChange(null);
                                setShowEditMenu(!showEditMenu);
                            }}>
                                Remove Image
                            </div>
                            <div className={styles.menuItem} onClick={() => {
                                inputRef.current.click();
                                setShowEditMenu(!showEditMenu);
                            }}>
                                Replace Image
                            </div>
                        </div>
                        <input ref={inputRef} className={styles.fileInput} type="file" onChange={onChangeFiles} />
                    </>
                    : null}
                {/* <Modal centered dialogClassName={"confirmActionDialog"} show={modalVisible} onHide={() => {
                modalToggle();
                setErrorMessage(null);
            }} >
                <ConfirmDialogMessage success={false} title={errorMessage} subtitle={""} />
            </Modal> */}
            </div>
            {errorMessage ? <div className={styles.imageUploadError}>{errorMessage}</div> : null}
        </div>

    );
}

export default ProfileImage;