import styles from './ProfilePasswordUpdate.module.scss';
import { BiChevronLeft as BackButton } from 'react-icons/bi'
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CurrentPassword from './CurrentPassword/CurrentPassword';
import ChangePassword from './ChangePassword/ChangePassword';

enum PasswordUpdateScreens {
    currentPasswordScreen = "CURRENT_PASSWORD_SCREEN",
    changePasswordScreen = "CHANGE_PASSWORD_SCREEN"
}

const ProfilePasswordUpdate = () => {
    const navigate = useNavigate();
    const [screens, setScreens] = useState<PasswordUpdateScreens>(PasswordUpdateScreens.currentPasswordScreen);
    const [currentPassword, setCurrentPassword] = useState<string>(null);

    const changeToConfirmScreen = () => {
        setScreens(PasswordUpdateScreens.changePasswordScreen);
    }

    return (
        <div className={styles.main}>
            {screens == PasswordUpdateScreens.changePasswordScreen ?
                <ChangePassword currentPassword={currentPassword} setCurrentPassword={setCurrentPassword} />
                :
                <CurrentPassword currentPassword={currentPassword} setCurrentPassword={setCurrentPassword} changeToConfirmScreen={changeToConfirmScreen} />
            }
        </div>

    );
}

export default ProfilePasswordUpdate;