import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAnalyticsEventTracker from '../../../components/Layout/useAnalyticsEventTracker';
import styles from './steps.module.scss'

const CustomerContext = ({ register, getValues, next, back, skip, setValue }: any) => {
    const questions =
        [
            {
                question: 'What is the general profile of your customers?',
                placeholder: 'Manufacturing companies, D2C startups, freelancers etc',
            },
            {
                question: 'Who will view this Workspace within that company?',
                placeholder: 'Finance team, Sales, senior management etc',
            },
            {
                question: 'Are these customers from a particular geographical region?',
                placeholder: 'Maharashtra, India, Bangalore..',
            },
            {
                question: 'How are your customers currently fulfilling their data reporting needs?',
                placeholder: 'Excel sheets, in-house tech team, in-app dashboards',
            },
            {
                question: 'How do these customers reach out to you?',
                placeholder: 'Email, customer support chat…',
            }
        ]
    const navigate = useNavigate();
    const gaEventTracker = useAnalyticsEventTracker('Workspace step 3');

    useEffect(() => {
        questions.map((quest, index) => {
            setValue(`customerContext[${index}].question`, quest.question)
        })
    }, [questions])

    return (
        <div className={styles.formContInputData}>
            <div style={{height: '355px'}}>
                <span className={styles.title}>Customer Context</span>
                <p className={styles.content}>Tell us more about the user persona of your customer. This will help us make a more relevant Workspace for their requirements!</p>
                <div className={styles.innerInput}>
                    <div className={styles.innerInputDiv} style={{paddingRight: '5%'}}>
                    {
                        questions.slice(0,3).map((quest, index) => {
                            return (
                                <div style={{marginBottom: '10px', width: '100%'}}>    
                                    <label>{quest.question}</label>
                                    <input
                                        maxLength={500}
                                        {...register(`customerContext[${index}].answer`)}
                                        placeholder={quest.placeholder}
                                        onKeyDown={(e)=>{if(e.keyCode==13) next()}}
                                        className={styles.inputEle}>
                                    </input>
                                </div>
                            )
                        })
                    }
                    </div>
                    <div className={styles.innerInputDiv} style={{paddingRight: '5%'}}>
                    {
                        questions.slice(3,5).map((quest, index) => {
                            return (
                                <div style={{marginBottom: '15px', width: '100%'}}>    
                                    <label>{quest.question}</label>
                                    <input
                                        maxLength={500}
                                        {...register(`customerContext[${index+3}].answer`)}
                                        placeholder={quest.placeholder}
                                        onKeyDown={(e)=>{if(e.keyCode==13) next()}}
                                        className={styles.inputEle}>
                                    </input>
                                </div>
                            )
                        })
                    }
                    </div>
                </div>
            </div>
            <div className={styles.buttonContStepsparent}>
                <p id={styles.skipBtn} onClick={() => { gaEventTracker("skip Create workspace - Step3", "step3"); skip() }}>Skip</p>
                <div className={styles.buttonContSteps}>
                    <p onClick={() => { gaEventTracker("cancel Create workspace - Step3", "step3"); navigate('/') }}>Cancel</p>
                    <p onClick={() => { gaEventTracker("back Create workspace - Step3", "step3"); back() }}>Back</p>
                    <p className={styles.next} onClick={() => { gaEventTracker("complete Create workspace - Step3", "step3"); next() }}>Next</p>
                </div>
            </div>
        </div>
    )
}

export default CustomerContext