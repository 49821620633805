import { useEffect } from 'react'
import HeroSection from './heroSection/HeroSection';
import styles from './LandingPage.module.scss';
import MainLayout from './MainLayout';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { styled, withStyles } from "@mui/material/styles";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { FiArrowRight as RightIcon } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom';



const steps = ['Share a sample dataset through our secure API', 'Tell us more about this data and your customers', 'Review a dashboard custom built for your customers', 'Go live!'];

const CustomizedConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.root}`]: {
        marginLeft: 9
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 70,
        borderColor: "#596de7",
        borderRadius: 1
    }
}));

const CustomLabel = styled(StepLabel)(({ theme }) => ({
    [`&.${stepLabelClasses.root}`]: {
        padding: 0
    },
}))

type Props = {
    title: string;
}

const LandingPage = ({ title }: Props) => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = title || "Merito"
    }, [])


    const CustomStepIcon = (props: any) => {
        const { active, completed, icon } = props;
        return (
            <div style={{ height: '17px', width: '17px', overflow: 'none' }}>
                <div className={`${styles.stepLabels} ${styles.active}`}></div>
            </div>
        );
    };


    return (
        <MainLayout>
            <HeroSection />
            <div className={styles.rightPart}>
                <div className={styles.title}>
                    How to get started!
                </div>
                <Stepper className={styles.stepper} orientation="vertical" connector={<CustomizedConnector />} >
                    {steps.map((label, index) => {
                        const stepProps: { completed?: boolean } = {};
                        const labelProps: {
                            optional?: React.ReactNode;
                        } = {};
                        return (
                            <Step key={label} {...stepProps}>

                                <CustomLabel StepIconComponent={CustomStepIcon}  {...labelProps}>{label}</CustomLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <div>
                    <button className={styles.btn} onClick={() => navigate("/signin")}>
                        Get Started
                        <span>
                            <RightIcon />
                        </span>
                    </button>
                </div>


            </div>
        </MainLayout>
    );
}

export default LandingPage;