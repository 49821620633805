import React, { useState, useEffect } from 'react'
import HeroSection from '../LandingPage/heroSection/HeroSection';
import styles from './ResendVerificationEmail.module.scss';
import { Form, Field } from 'react-final-form';
import OutlinedInput from '../../components/common/OutlinedInput/OutlineInput';

import { HiArrowLeft as LeftArrow } from 'react-icons/hi';
import { cutomEmailValidation, mobileNumberValidation, reqiredValidation } from '../../components/common/functions/formValidation';
import { useNavigate } from 'react-router-dom';
import ConfirmPage from '../ConfirmPages/ConfirmPage';
import axios from 'axios';
import MainLayout from '../LandingPage/MainLayout';
import { CommonError } from '../../interfaces/interfaces';
import { FORM_ERROR } from 'final-form';
import useAnalyticsEventTracker from '../../components/Layout/useAnalyticsEventTracker';
import CustomButton from '../SignUp/buttons/ConfirmButton';


type Props = {
    title: string;
}

const ResendVerificationEmail = ({ title }: Props) => {
    const navigate = useNavigate();
    const [showConfirm, setShowConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const gaEventTracker = useAnalyticsEventTracker('resend verification email');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = title || "Merito"
    }, [])

    const onSubmit = async (values) => {
        // console.log(values);
        // setShowConfirm(true);
        let errors = {
            value: false,
            message: {},
        };
        setLoading(true);
        try {
            const data = await axios.post(`${process.env.REACT_APP_API_URL}/api/integrationpartner/resendverificationemail`, values);
            gaEventTracker("resend verify email success")
            if (data) {
                // console.log(data);
                setShowConfirm(true);
            }
            setLoading(false);
        } catch (error) {
            gaEventTracker("verify email error")
            // console.log(error);
            setLoading(false);
            const errorData = error.response?.data?.errors as CommonError[];
            if (errorData && errorData.length > 0) {
                const parseError = errorData.reduce((prev, currVal) => {
                    prev[currVal.field] = currVal.message
                    return prev;
                }, {});
                errors = {
                    value: true,
                    message: parseError
                }
            } else {
                errors = {
                    value: true,
                    message: {
                        [FORM_ERROR]: "Something went wrong"
                    }
                }
            }
        }


        if (errors.value == true) {
            // console.log(errors);
            return errors.message
        }
    }


    const RenderSubtitle = () => {
        return (
            <>

                <div>
                    Check your inbox
                </div>

                <div style={{ color: "#596DE7", cursor: "pointer" }} onClick={() => {
                    navigate('/signin')
                }}>
                    Sign in here
                </div>
            </>

        )
    }





    return (
        <MainLayout>

            <HeroSection />
            {showConfirm ?
                <ConfirmPage title='Verify your email ID to sign in' subtitle={<RenderSubtitle />} />
                : <div className={styles.rightPart}>
                    <div className={styles.backArrow} onClick={() => {
                        navigate('/signin')
                    }}>
                        <LeftArrow />
                    </div>
                    <div className={styles.signUpSection}>
                        <div className={styles.title} >Verify Email</div>

                        <Form
                            validateOnBlur={true}
                            onSubmit={(values) => onSubmit(values)} render={({ handleSubmit, pristine, invalid, visited, hasValidationErrors, submitError }) => (
                                <>
                                    <form onSubmit={handleSubmit}>
                                        <div className={styles.formSection}>
                                            <Field name='email' validate={cutomEmailValidation}>
                                                {props => {
                                                    return <OutlinedInput {...props.input}
                                                        error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null}
                                                        type='email'
                                                        value={props.input.value}
                                                        onChange={props.input.onChange} placeholder="Email Address" />
                                                }}
                                            </Field>
                                            {submitError && <div className={styles.formError}>
                                                {submitError}
                                            </div>}
                                            <CustomButton loading={loading} type="submit" disabled={pristine || hasValidationErrors} onClick={handleSubmit} >Verify Email</CustomButton>
                                        </div>
                                    </form>
                                </>
                            )} />
                    </div>

                    <div className={styles.bottomTitle} onClick={() => {
                        navigate('/signin')
                    }}>
                        Already have an account? <span>Sign In</span>
                    </div>
                </div>}

        </MainLayout>

    );
}

export default ResendVerificationEmail;