import { useEffect, useRef, useState } from 'react';
import styles from './steps.module.scss'
import { AiOutlineCloudUpload } from "react-icons/ai";
import { BsChevronDown, BsX, BsFillCaretRightFill, BsChevronRight } from "react-icons/bs";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
import CalendarReact from './calendar/calendar'
import { RestS } from '../../../rest/rest-service';
import { useNavigate } from 'react-router-dom';
import useModal from '../../../components/Hooks/useModal';
import { Modal } from 'react-bootstrap';
import useAnalyticsEventTracker from '../../../components/Layout/useAnalyticsEventTracker';

const Joi = require('joi-browser');
const schema = Joi.object({
    productName: Joi.string().required().label("Product Name"),
    refreshInterval: Joi.string().required().label("Frequency"),
    // dataSet: Joi.string().required().label("Data set")
});

const InputData = ({ dataSet, next, getValues, register, back, setValue, setDisableIndex }: any) => {
    const [errors, setErrors] = useState<any>({})
    const [dropDown, setDropDown] = useState<any>({
        refreshInterval: false,
        dataSet: false,
        weekly: false,
        monthly: false
    })
    const [files, setFiles] = useState(getValues().files ? getValues().files : [])
    // const [dataSets, setDataSets] = useState<any>([])
    const [disable, setDisable] = useState<any>({
        productName: true,
        refreshInterval: true
    })
    const navigate = useNavigate()
    const { toggle: modalToggle, visible: modalVisible } = useModal();
    const [errorMessage, setErrorMessage] = useState(null);
    const gaEventTracker = useAnalyticsEventTracker('Workspace step 2');

    useEffect(() => {
        if (getValues().productName != undefined && getValues().productName != '')
            setDisable(prev => { return { ...prev, productName: false } })
        if (getValues().refreshInterval != undefined && getValues().refreshInterval != '')
            setDisable(prev => { return { ...prev, refreshInterval: false } })
    }, [])

    const toggle = (item: string) => {
        setDropDown((prevState: any) => ({
            ...prevState,
            [item]: !prevState[item]
        }));
    }

    const validate = (errorDisplay:any = {
        productName: false,
        refreshInterval: false
    }) => {
        const { productName, refreshInterval, dataSet } = getValues()
        const data = { productName, refreshInterval }
        const result = Joi.validate(data,
            schema, { abortEarly: false });
        const { error } = result;
        if (error) {
            setDisable({
                refreshInterval: false,
                productName: false
            })
            const errorData: any = {};
            for (let item of error.details) {
                const name = item.path[0];
                const message = item.message;
                if(errorDisplay[name])
                    errorData[name] = message;
                setDisable(prev => { return { ...prev, [name]: true } })
            }
            if(errorData["productName"]) {
                setDisableIndex(1)
                setErrors(prev => {
                    return {
                        ...prev,
                        productName: "Product Name is required"
                    }
                });
            }
        } else {
            setDisableIndex(5)
            setDisable(prev => {
                return {
                    refreshInterval: false,
                    productName: false
                }
            })
            setErrors({})
        }
    }

    const setFrequencyTime = (e: any) => {
        setDisable(prev => { return { ...prev, refreshInterval: false } })
        toggle('refreshInterval')
        setValue('refreshInterval', 'Monthly')
        setValue('refreshFrequency', e)
    }

    const handleClick = () => {
        if (!(disable.refreshInterval || disable.productName)){
            gaEventTracker("complete Create workspace - Step2", "step2")
            next()
        }
    }

    const hiddenFileInput = useRef<any>(null);

    const handleClickInput = (event: any) => {
        hiddenFileInput.current.click();
        setErrorMessage(null);
    };

    const onFileChange = (e: any) => {
        const newfiles = e.target.files[0] as File;
        if (newfiles.size > (1024 * 1024)) {
            setErrorMessage('Please upload a files less than 1mb');
            modalToggle();
            return;
        }
        if (!/\.(png|jpg|jpeg|pdf)$/i.test(newfiles.name)) {
            setErrorMessage('Please upload only png , jpeg or pdf files');
            modalToggle();
            return;
        }
        setFiles((prev: any) => {
            const temp = prev.concat(newfiles)
            setValue('files', temp)
            return temp
        })
    }

    const removeFile = (i: number) => {
        const newFiles = getValues().files
        newFiles.splice(i, 1)
        setFiles(newFiles)
        setValue('files', newFiles)
    }

    // const handleClickDataset=(name: string)=> {
    //     let obj = getValues().dataSet
    //     if(!obj) obj = []
    //     if(obj.includes(name)){
    //         for(var i in obj) {
    //             if(obj[i]==name) 
    //                 obj.splice(i,1)
    //         }
    //         setValue('dataSet', obj)
    //         setDataSets(obj)
    //     }
    //     else { 
    //         obj.push(name)
    //         setValue('dataSet', obj)
    //         setDataSets(obj)
    //     }
    // }

    return (
        <div className={styles.formContInputData}>
            <div style={{ height: '355px' }}>
                <span className={styles.title}>Input Data</span>
                <p className={styles.content}>This is the source data that you will send us to build a sample Workspace and periodically refresh your customers’ live Workspaces</p>
                <div className={styles.innerInput}>
                    <div className={styles.innerInputDiv} style={{ paddingRight: '5%' }}>
                        <div style={{ marginBottom: '15px', width: '100%' }}>
                            <label>Select data set</label>
                            <div className={styles.selectedDataSet}>{dataSet.dataSetName}</div>
                            {/* <Dropdown isOpen={dropDown.dataSet} toggle={()=>{toggle('dataSet')}}>
                                <DropdownToggle onClick={()=>{}} style={{ marginTop: 0 }} className={`${styles.inputEle}`}>
                                    <div className={styles.dropDownToggleEle}>
                                        {getValues().dataSet?.length ? <p style={{color: 'black'}}>{getValues().dataSet?.map((data:any, index:number) => {
                                            if(index>0)
                                                return ', '+data
                                            return data
                                        })}</p> :<p>Select data set</p>}
                                        <BsChevronDown />
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu className={styles.inputDataDropDown}>
                                    <DropdownItem toggle={false} onClick={()=>handleClickDataset('Headers')} className={styles.flexStart}>
                                        <div className={dataSets?.includes('Headers') ? styles.filledRadio : styles.emptyRadio}></div>
                                        <p>Header</p>
                                    </DropdownItem>
                                    <DropdownItem toggle={false} onClick={()=>handleClickDataset('Action')} className={styles.flexStart}>
                                        <div className={dataSets?.includes('Action') ? styles.filledRadio : styles.emptyRadio}></div>
                                        <p>Action</p>
                                    </DropdownItem>
                                    <DropdownItem toggle={false} onClick={()=>handleClickDataset('Another Action')} className={styles.flexStart}>
                                        <div className={dataSets?.includes('Another Action') ? styles.filledRadio : styles.emptyRadio}></div>
                                        <p>Another Action</p>
                                    </DropdownItem>
                                    <DropdownItem toggle={false} onClick={()=>handleClickDataset('Another Header')} className={styles.flexStart}>
                                        <div className={dataSets?.includes('Another Header') ? styles.filledRadio : styles.emptyRadio}></div>
                                        <p>Another Header</p>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown> */}
                            {/* {errors.dataSet ? <p className={styles.errorMess}>{errors.dataSet}</p>:null} */}
                        </div>
                        <div style={{ marginBottom: '15px', width: '100%' }}>
                            <label>Product</label>
                            <input
                                id={(disable.productName&&errors.productName)?styles.invalid:''}
                                {...register("productName", {
                                    onChange: () => { validate({productName: true}) }
                                })}
                                maxLength='500'
                                placeholder='Share the name of the associated product'
                                onKeyDown={(e) => { if (e.keyCode == 13) handleClick() }}
                                className={styles.inputEle}>
                            </input>
                            {disable.productName ? <p className={styles.errorMess}>{errors.productName}</p> : null}
                        </div>
                        <div style={{ marginBottom: '15px', width: '100%' }}>
                            <label>Select frequency of data refresh</label>
                            <input style={{ display: 'none' }}     {...register("refreshInterval")}></input>
                            <Dropdown isOpen={dropDown.refreshInterval} toggle={() => { toggle('refreshInterval') }}>
                                <DropdownToggle style={{ marginTop: 0 }} className={styles.inputEle}>
                                    <div className={styles.dropDownToggleEle}>
                                        {getValues().refreshInterval == '' || !getValues().refreshInterval ? <p>Select frequency</p> :
                                            <h1>{getValues().refreshInterval}
                                                {getValues().refreshFrequency && (getValues().refreshInterval == 'Weekly' || getValues().frerefreshIntervalquency == 'Monthly') ?
                                                    `, ${getValues().refreshFrequency}` : null}
                                            </h1>}
                                        <BsChevronDown />
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu className={styles.inputDataDropDown}>
                                    <DropdownItem onClick={() => { setDisable(prev => { return { ...prev, refreshInterval: false } }); setValue('refreshInterval', 'Daily'); setValue('refreshFrequency', '') }}>Daily</DropdownItem>
                                    <Dropdown isOpen={dropDown.weekly} className={styles.frequencyDrop} toggle={() => toggle('weekly')}>
                                        <DropdownToggle className={styles.frequencyToggle}>
                                            <div className={styles.dropDownToggleEle}>
                                                <p>Weekly</p>
                                                <BsChevronRight />
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu className={styles.weekDropMenu}>
                                            {['Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday', 'Sunday'].map((item: string) => {
                                                return <DropdownItem onClick={() => {
                                                    setDisable(prev => { return { ...prev, refreshInterval: false } })
                                                    setValue('refreshFrequency', item);
                                                    toggle('refreshInterval');
                                                    setValue('refreshInterval', 'Weekly')
                                                }}>
                                                    {item}</DropdownItem>
                                            })}
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Dropdown isOpen={dropDown.monthly} className={styles.frequencyDrop} toggle={() => toggle('monthly')}>
                                        <DropdownToggle className={styles.frequencyToggle}>
                                            <div className={styles.dropDownToggleEle}>
                                                <p>Monthly</p>
                                                <BsChevronRight />
                                            </div>
                                        </DropdownToggle>
                                        <DropdownMenu className={styles.monthDropMenu}>
                                            <CalendarReact setFrequencyTime={setFrequencyTime} />
                                        </DropdownMenu>
                                    </Dropdown>
                                    <DropdownItem onClick={() => {
                                        setDisable(prev => { return { ...prev, refreshInterval: false } });
                                        setValue('refreshInterval', 'No Preference');
                                        setValue('refreshFrequency', '')
                                    }}>
                                        <p style={{margin: 0}}>No Preference</p></DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            {/* {disable.refreshInterval ? <p className={styles.errorMess}>Refresh Interval is required</p> : null} */}
                        </div>
                    </div>
                    <div className={styles.innerInputDiv}>
                        <label>Upload (optional)</label>
                        <div className={styles.uploadBox}>
                            <div className={styles.uploadedBox} id={styles.dropScroll} style={{ display: getValues().files?.length ? 'block' : 'none' }}>
                                {
                                    getValues().files?.map((file: any, index: number) => {
                                        return <div className={styles.uploaded}>
                                            <p>{file.name}</p>
                                            <BsX
                                                style={{ width: '14px', height: '14px', cursor: 'pointer' }}
                                                onClick={() => { removeFile(index) }}
                                            />
                                        </div>
                                    })
                                }
                            </div>
                            <input type="file"
                                ref={hiddenFileInput}
                                style={{ display: 'none' }}
                                onChange={onFileChange}
                                accept=".png,.jpg,.jpeg,.pdf"
                            />
                            <div onClick={handleClickInput} className={`${files.length > 4 ? styles.uploadDiagramSmall : styles.uploadDiagram} ${errorMessage ? styles.uploadError : ''}`}>
                                <AiOutlineCloudUpload style={{ width: '30px', height: '30px', color: 'grey' }} />
                                <p>Upload relevant ER diagrams, data dictionary & any other supporting documents </p>
                                <p>Support docs: png, jpeg, jpg, pdf</p>
                            </div>
                            {errorMessage ? <div className={styles.errorMess}>
                                {errorMessage}
                            </div> : null}
                        </div>
                    </div>
                </div>

            </div>
            <div className={styles.buttonContSteps}>
                <p onClick={() => { gaEventTracker("cancel Create workspace - Step2", "step2"); navigate('/') }}>Cancel</p>
                <p onClick={() => {
                    gaEventTracker("Back Create workspace - Step2", "step2");
                    back()
                }}>Back</p>
                <p className={`${(disable.refreshInterval || disable.productName) ? styles.disableNext : styles.enableNext} ${styles.next}`} 
                    onClick={handleClick}
                >Next</p>
            </div>
        </div>
    )
}

export default InputData