import { useEffect, useRef, useState } from 'react';
import styles from './steps.module.scss'
import { BsX } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import useAnalyticsEventTracker from '../../../components/Layout/useAnalyticsEventTracker';

const DataContext = ({ register, getValues, next, back, skip, setValue }: any) => {
    const [metrics, setMetrics] = useState(getValues().metrics ? getValues().metrics.length : 0)
    const [name, setName] = useState('')
    const [formula, setFormula] = useState('')
    const navigate = useNavigate()
    const [ error, setError ] = useState("")
    const ref = useRef(null);

    const questions =
        [
            {
                question: 'What is the primary purpose of this dashboard?',
                placeholder: 'monitor app activity, identify sales trends..',
            },
        ]

    useEffect(() => {
        questions.map((quest, index) => {
            setValue(`primaryPurpose.question`, quest.question)
        })
    }, [questions])
    const gaEventTracker = useAnalyticsEventTracker('Workspace step 4');

    function auto_grow(e: any) {
        setFormula(e.target.value)
        e.target.style.height = "5px";
        e.target.style.height = (e.target.scrollHeight) + "px";
    }

    const handleClick = () => {
        const tempName = name
        const tempFormula = formula
        const tempMetrics = metrics
        if (tempName != '' && tempFormula != '') {
            setValue(`metrics[${tempMetrics}].name`, tempName)
            setValue(`metrics[${tempMetrics}].formula`, tempFormula)
            setName('')
            setFormula('')
            setError('')
            setMetrics((prev: number) => prev + 1)
            ref.current.focus()
        }
        else if(tempName == '') {
            setError('Metrics Name is required')
        } else {
            setError('Metrics Formula is required')
        }
    }

    const removeMetrics = (i: number) => {
        const newData = getValues().metrics
        newData.splice(i, 1)
        setMetrics((prev: number) => prev - 1)
        setValue('metrics', newData)
    }

    return (
        <div className={styles.formContInputData}>
            <div style={{ height: '340px' }}>
                <span className={styles.title}>Data Context</span>
                <p className={styles.content}>Tell us what kind of dashboards and reports are your customers asking for!</p>
                <div className={styles.innerInput}>
                    <div className={styles.innerInputDiv} style={{ paddingRight: '5%' }}>
                        <div style={{ marginBottom: '15px', width: '100%' }}>
                            <label>List metrics that you would like to include in the workspace </label>
                            <div id={styles.dropScroll} className={styles.metricsOuter}>
                                {
                                    getValues().metrics?.map((context: any, index: number) => {
                                        return (
                                            <div className={styles.displayMetrics}>
                                                <div>
                                                    <p>{context.name}</p>
                                                    <BsX onClick={() => { removeMetrics(index) }} />
                                                </div>
                                                <p>{context.formula}</p>
                                            </div>
                                    )
                                })
                            }
                            <div id={error?styles.invalid:''} className={styles.metricsContainer}>
                                <input ref={ref} onKeyDown={(e)=>{if(e.keyCode==13) handleClick()}} maxLength={300} value={name} onChange={(e)=>setName(e.target.value)} className={styles.inputEle} placeholder='Name this metric'></input>
                                <textarea onKeyDown={(e)=>{if(e.keyCode==13) {e.preventDefault();handleClick()}}} maxLength={500} value={formula} onChange={(e)=>auto_grow(e)} placeholder='How do we calculate this metric'></textarea>
                                {error ? <p className={styles.errorMess}>{error}</p> : null}
                                </div>
                                <p onClick={() => { handleClick() }} className={styles.addAnother}>+ Add another metrics</p>
                            </div>

                            {/* <input
                            {...register(`answers[${index}].answer`)}
                            placeholder={quest.placeholder}
                            className={styles.inputEle}>
                        </input> */}
                        </div>
                    </div>
                    <div className={styles.innerInputDiv} style={{ paddingRight: '5%' }}>
                        {
                            questions.map((quest, index) => {
                                return (
                                    <div style={{ marginBottom: '15px', width: '100%' }}>
                                        <label>{quest.question}</label>
                                        <input
                                            maxLength={500}
                                            {...register(`primaryPurpose.answer`)}
                                            placeholder={quest.placeholder}
                                            onKeyDown={(e) => { if (e.keyCode == 13) {handleClick(); next()} }}
                                            className={styles.inputEle}>
                                        </input>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className={styles.buttonContStepsparent}>
                <p id={styles.skipBtn} onClick={() => { gaEventTracker("skip Create workspace - Step4", "step4"); skip() }}>Skip</p>
                <div className={styles.buttonContSteps}>
                    <p onClick={() => { gaEventTracker("cancel Create workspace - Step4", "step4"); navigate('/') }}>Cancel</p>
                    <p onClick={() => { gaEventTracker("back Create workspace - Step4", "step4"); handleClick(); back() }}>Back</p>
                    <p className={styles.next} onClick={() => { gaEventTracker("complete Create workspace - Step4", "step4"); handleClick(); next() }}>Next</p>
                </div>
            </div>
            <div className={styles.dataContextDisclaimer}>
                Please make sure the dataset contains all the data needed to calculate these metrics!
            </div>
        </div>
    )
}

export default DataContext