// export const Colors = ["#596DE7", "#FBBD00", "#4270C1", "#E97B30", "#A3A3A3"];
// export const Colors = ["#002333", "#003F5C", "#53A6C4", "#665191", "#A05195","#D45087","#F95D6A","#FC8434","#FFAA00","#8F9B00"];
export const Colors = [
  "#003F5C",
  "#FFAA00",
  "#F95D6A",
  "#665191",
  "#53A6C4",
  "#FC8434",
  "#002333",
  "#8F9B00",
  "#D45087",
  "#A05195",
];
