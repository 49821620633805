import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import { BarChart, } from "../../interfaces/interfaces";
import convertToInternationalCurrencySystem, { sumArrays } from "../functions/utilities";
import { htmlLegendPlugin } from "./ChartPlugins/HtmlLegendPlugin";
import { Colors } from "./_constants";



ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);



type Props = {
  chart: BarChart,
  lengendLineStyle?: any,
  hoverLabel?: any,
  dates?: any,
  multi?: boolean,
  analyse?: boolean
};

function LineChartWrap({ chart, lengendLineStyle = {}, hoverLabel = {}, dates = [], multi = false, analyse = false }: Props) {

  const monthNames: string[] = [
    "January", "Jan",
    "February", "Feb",
    "March", "Mar",
    "April", "Apr",
    "May", "May",
    "June", "Jun",
    "July", "Jul",
    "August", "Aug",
    "September", "Sep",
    "October", "Oct",
    "November", "Nov",
    "December", "Dec"
  ];
  // useEffect(()=>{
  //   console.log(chart)
  // }, [chart])

  const hoverElement = (context: any) => {
    // Tooltip Element
    if (context.tooltip.dataPoints) {
      let tooltipEl = document.getElementById('chartjs-tooltip');
      let labelNeed = context.tooltip.dataPoints[0].dataset.label;
      let dataIndex = context.tooltip.dataPoints[0].dataIndex;
      let datasetForHover = hoverLabel[labelNeed] ? hoverLabel[labelNeed] : chart.config['hoverLabel'][labelNeed]
      // console.log(datasetForHover)
      let finalData = []
      let finalLabel = []
      for (let i in datasetForHover) {
        finalLabel.push(i)
        finalData.push(datasetForHover[i][dataIndex])
      }
      // console.log(finalLabel, finalData)
      let str = ''
      const finalString = () => {
        finalLabel.map((label, i) => {
          str += `<p style="margin: 0px 0px 10px;">${label} <b>- ${convertToInternationalCurrencySystem(finalData[i])}</b></p>`
        })
      }
      // finalString()
      let datesTitle = ''
      let temp = new Date(dates[dataIndex] ? dates[dataIndex] : chart.config['dates'][dataIndex])
      let str1 = temp.toLocaleString('default', { month: 'long' }) + ' ' + temp.getDate() + ', ' + (temp.getHours() < 10 ? `0${temp.getHours()}` : temp.getHours()) + ':' + (temp.getMinutes() < 10 ? `0${temp.getMinutes()}` : temp.getMinutes());
      datesTitle += `<div style="color: #808080; text-align: center; padding: 10px 0px 10px; width: 100%"><p>${str1}</p></div>`
      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.style.zIndex = '100000';
        tooltipEl.innerHTML = '<div style="background-color: rgba(30, 30, 30, 0.88); font-size: 10px; color: white; padding: 0px 20px 5px; font-family: Poppins; border-radius: 5px;"></div>';
        document.body.appendChild(tooltipEl);
      }
      // console.log(tooltipEl)

      // Hide if no tooltip
      const tooltipModel = context.tooltip;
      // console.log(tooltipModel)
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
      }

      let tableRoot = tooltipEl.querySelector('div');
      finalString()
      tableRoot.innerHTML = datesTitle + str
      // console.log(tableRoot)

      const position = context.chart.canvas.getBoundingClientRect();
      // const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

      // Display, position, and set styles for font
      tooltipEl.style.opacity = '1';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
      tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
      // tooltipEl.style.font = bodyFont.string;
      tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
      tooltipEl.style.pointerEvents = 'none';
    }
  }

  const legendRef = useRef(null);
  let showLegend = chart.datasets.length > 1
  // let is100per = false;
  let is100per = chart.type.toString().includes("100");
  let isStacked = chart.type.toString().includes("stacked");

  let stackDataSetSum = []
  if (is100per) {
    stackDataSetSum = sumArrays(...chart.datasets.map((data) => data.data))
  }

  const options = {
    responsive: true,
    maintainAspectRatio: dates.length ? false : true,
    parsing: {
      yAxisKey: 'percentage',
      xAxisKey: 'percentage'
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
        labels: {
          boxWidth: 20,
          usePointStyle: true,
          pointStyle: "rectRounded",
          padding: 10,
        },
      },
      title: {
        display: false,
      },
      htmlLegend: {
        containerID: legendRef,
        showLegend
      },
      tooltip: {
        enabled: !multi && (chart.config ? !chart.config['multi'] : true),
        external: function (context) {
          // console.log('multi', multi)
          if (multi || (chart.config ? chart.config['multi'] : false))
            hoverElement(context)
        },
        callbacks: {
          title: function (context) {
            if (dates[context[0].dataIndex] || chart.config['dates']) {
              let temp = new Date(dates[context[0].dataIndex] ? dates[context[0].dataIndex] : chart.config['dates'][context[0].dataIndex])
              if (monthNames.includes(dates[context[0].dataIndex])) {
                return dates[context[0].dataIndex]
              }
              let str1 = temp.toLocaleString('default', { month: 'long' }) + ' ' + temp.getDate() + ', ' + (temp.getHours() < 10 ? `0${temp.getHours()}` : temp.getHours()) + ':' + (temp.getMinutes() < 10 ? `0${temp.getMinutes()}` : temp.getMinutes());
              return str1
            } else return (context[0].label)
          },
          label: function (context) {
            let label = '';
            if (context.parsed.y !== null) {
              if (is100per) {
                label += context.raw.data;
                label += ` (${convertToInternationalCurrencySystem(context.raw.percentage.toFixed(2))}%)`;
              } else {
                label += convertToInternationalCurrencySystem(context.parsed.y)
              }
            }
            return label;
          }
        }
      }
    },
    scales: {
      x: {
        title: {
          display: chart.config?.xAxisLabel ? true : false,
          text: chart.config?.xAxisLabel,
          color: '#808080',
          font: {
            family: 'Poppins',
            size: 12,
            weight: 600,
            lineHeight: 1.2,
          },
          padding: {top: 10, left: 0, right: 0, bottom: 0}
        },
        grid: {
          display: false,
        },
        stacked: isStacked,
      },
      y: {
        title: {
          display: chart.config?.yAxisLabel ? true : false,
          // text: "This is the label of y axis, ther label of y axis",
          text: chart.config?.yAxisLabel,
          color: '#808080',
          font: {
            family: 'Poppins',
            size: 12,
            weight: 600,
            lineHeight: 1.2,
          },
          padding: {top: 0, left: 0, right: 0, bottom: 10}
        },
        grid: {
          display: analyse ? true : false,
        },
        ticks: {
          // Abbreviate the millions
          callback: function (value, index, values) {
            return convertToInternationalCurrencySystem(value);
          }
        }
      }
    },
  };

  let datasets = chart.datasets.map((e, index) => {
    let i = index % 10;

    let stackData = []
    if (is100per) {
      e.data.forEach((d, di) => {
        let map = {
          data: d,
          percentage: ((Number(d) * 100) / stackDataSetSum[di])
        }
        stackData.push(map)
      })
    }
    else {
      stackData = e.data
    }
    return {
      label: e.label,
      data: stackData,
      borderColor: Colors[i],
      backgroundColor: Colors[i],
    }
  })

  let data = {
    labels: chart.label,
    datasets: datasets
  };


  return <>
    <div className="d-flex" style={{ maxWidth: "100%", justifyContent: "center", alignItems: "center" }}>
      <div className="" style={{ width: showLegend && lengendLineStyle.chartWidth ? lengendLineStyle.chartWidth : showLegend ? "73%" : "100%", maxHeight: data.labels.length ? '20rem' : 'max-content' }}>
        <Line options={options as any} data={data} plugins={[htmlLegendPlugin]} />
      </div>
      {showLegend &&
        <div
          ref={legendRef}
          style={{ width: lengendLineStyle.width ? lengendLineStyle.width : "27%", maxHeight: "220px", paddingLeft: lengendLineStyle.paddingLeft ? lengendLineStyle.paddingLeft : "10px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
        </div>
      }
    </div>
  </>
}

export default LineChartWrap;
