import GetChart from "../functions/getChart"
import styles from './styles.module.scss'
import { useEffect, useRef, useState } from "react"
import { BsCheck2, BsXCircle, BsArrowsAngleExpand as ExpandIcon } from "react-icons/bs";
import useAnalyticsEventTracker from "../Layout/useAnalyticsEventTracker";
import useModal from "../Hooks/useModal";
import ChartInfo from "../common/chartInfo/ChartInfo";
import { AiOutlineInfoCircle } from "react-icons/ai";
import PivotTableComponent from "../chart/pivot";

type Props = {
    chart: any,
    setExpandedChartData: any,
    toggleView: Function,
    setChartStatus?: Function,
    index?: number,
    workspaceStatus?: string
}

const ChartDataPiece = ({ chart, setExpandedChartData, toggleView, setChartStatus, index, workspaceStatus }: Props) => {
    const [status, setStatus] = useState(chart.status);
    const gaEventTracker = useAnalyticsEventTracker('DataPiece');
    const [showRejectedInputField, setShowRejectedInputField] = useState(false);
    const [rejectingError, setRejectingError] = useState(false)
    const [order, setOrder] = useState(chart.suggestion)
    const [showChat, setShowChat] = useState(false)
    const chartRef = useRef(null)
    const parentRef = useRef(null)
    const handleClick = (value, field) => {
        switch (field) {
            case 'status': {
                setStatus(prev => {
                    if (prev == value) {
                        setChartStatus(index, "Not Decided", field)
                        setShowRejectedInputField(false)
                        return "Not Decided"
                    } else {
                        setChartStatus(index, value, field)
                        if (value == "Rejected") {
                            setShowRejectedInputField(true)
                            return prev
                        } else {
                            return value
                        }
                    }
                })
                break;
            }
            case 'suggestion': {
                setStatus("Rejected")
                setChartStatus(index, value, field)
                break;
            }
        }

    }

    const handleChange = (value) => {
        setOrder(value)
        if (value == '') {
            setRejectingError(true)
        } else {
            setRejectingError(false)
        }
    }

    const handleRejectClick = () => {
        if (!(rejectingError || (order == ''))) {
            setShowRejectedInputField(false);
            handleClick(order, 'suggestion');
        }
    }

    useEffect(() => {
        // console.log(chart)
        const listener = (e) => {
            if (parentRef.current?.contains(e.target)) {
                return;
            } else if (chartRef.current && !chartRef.current?.contains(e.target)) {
                setShowChat(false)
            }
        }

        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);

        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [chart])

    return (
        <div className={`col-lg-6 col-md-12 pb-4 ${styles.dataPieceOuter}`} style={{ background: 'transparent' }}>
            {chart.overview || chart.action ? <div ref={chartRef}><ChartInfo overview={chart.overview} action={chart.action} toggle={() => setShowChat(prev => !prev)} visible={showChat} chartIndex={index} /></div> : null}
            {/* <div ref={chartRef}><ChartInfo overview={chart.overview} action={chart.action} toggle={()=>setShowChat(prev => !prev)} visible={showChat} chartIndex={index} /></div> */}
            <div className={`${styles.card} ${status == "Accepted" ? styles.checkBorder : status == "Rejected" ? styles.rejectBorder : status == "Changes" ? styles.suggestionBorder : styles.noneBorder}`} >
                <div className={`${styles.cardTitle} d-flex justify-content-between`}>
                    <div style={{ display: 'flex', gap: '10px', paddingRight: '5px' }}>
                        {chart.chartIdentifier.includes('table') ? chart.name : chart.title}
                        {chart.overview || chart.action ? <div ref={parentRef}><AiOutlineInfoCircle onClick={() => setShowChat(prev => !prev)} className={styles.eye} /></div> : null}
                        {/* <div ref={parentRef}><AiOutlineInfoCircle onClick={()=>setShowChat(prev => !prev)} className={styles.eye} /></div> */}
                    </div>
                    <div className={styles.iconContainer} onClick={() => { setExpandedChartData(chart); toggleView() }}>
                        <ExpandIcon />
                    </div>
                </div>
                <div className={styles.timePeriod}>
                    Data Period: {chart.config?.timePeriod}
                </div>
                {chart && <div className={`${styles.chartBlock} my-auto`}>
                    {
                        chart.chartIdentifier.includes('table') ?
                            <PivotTableComponent tableData={chart} />
                            :
                            <GetChart chart={chart} />
                    }
                </div>}
                {showRejectedInputField ?
                    <div className={styles.suggestionBox}>
                        <textarea value={order} onChange={(e => handleChange(e.target.value))} className={styles.inputField} placeholder="Reason for rejecting the chart"></textarea>
                        {rejectingError ? <p>Reason is required for rejecting chart</p> : null}
                        <div>
                            <p className={`${styles.suggestionButton}`} onClick={() => { handleClick("Not Decided", "status"); setShowRejectedInputField(false) }}>Cancel</p>
                            <p className={`${styles.suggestionButton} ${rejectingError || (order == '') ? styles.disable : ''}`} onClick={() => { handleRejectClick() }}>Reject Chart</p>
                        </div>
                    </div>
                    : <div></div>}
                {
                    workspaceStatus != "Changes Shared" ?
                        <>
                            <div className={styles.actionBtns}>
                                <div>
                                    <BsXCircle onClick={() => { gaEventTracker("DataPiece reject "); handleClick('Rejected', 'status') }} style={{ display: status == "Not Decided" || status == "Rejected" ? 'block' : 'none' }} className={styles.cross} />
                                    <BsCheck2 onClick={() => { gaEventTracker("DataPiece accepted "); handleClick('Accepted', 'status') }} style={{ display: status == "Not Decided" || status == "Accepted" ? 'block' : 'none' }} className={styles.check} />
                                </div>
                            </div>
                        </>
                        : null
                }
            </div >
        </div >
    )
}

export default ChartDataPiece