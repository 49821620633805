import { useForm, FormProvider } from "react-hook-form";
import InputData from "./InputData";
import NameWorkspace from "./NameWorkspace";
import './dropDownOverride.css'
import CustomerContext from "./CustomterContext";
import DataContext from "./DataContext";
import { useEffect } from "react";
import ReviewNSubmit from "./ReviewNSubmit";
import { IPWorkspace } from "../../../interfaces/interfaces";
import { RestS } from "../../../rest/rest-service";

export default function Workspace(props: any) {
    const methods = useForm();
    const onSubmit = (data: any) =>{ 
        // console.log(methods.getValues())
    };

    // useEffect(()=>{
    //   console.log(props.dataSet)
    // })

  // useEffect(()=>{
  //   const dataSet: any = "6319a3294a587c6167d2c8e2"
  //   const workspace: any = "63198f1c1e26be58f04630b5"
    // const works: IPWorkspace = {
  //     workspaceName: "Testint1223",
  //     productName: "productNa3me",
  //     refreshInterval: "Monthly2",
  //     dataSet: dataSet,
  //     integrationPartner: workspace
  // }
    // RestS.addIPWorkspace(works)
    // RestS.getIPWorkspace()
    // RestS.getIPWorkspaceByDS("6319a3294a587c6167d2c8e2")
    // RestS.getIPWorkspaceByIP("63198f1c1e26be58f04630b5")
  // }, [])
  
  const compo=()=>{
    switch(props.step){
      case 1: 
        return <NameWorkspace setDisableIndex={props.setDisableIndex} next={props.next} register={methods.register} getValues={methods.getValues}/>
      case 2:
        return <InputData setDisableIndex={props.setDisableIndex} dataSet={props.dataSet} next={props.next} setValue={methods.setValue} register={methods.register} getValues={methods.getValues} back={props.back}/>
      case 3:
        return <CustomerContext next={props.next} setValue={methods.setValue} register={methods.register} getValues={methods.getValues} back={props.back} skip={props.skip}/>
      case 4:
        return <DataContext next={props.next} setValue={methods.setValue} register={methods.register} getValues={methods.getValues} back={props.back} skip={props.skip}/>
      case 5: 
        return <ReviewNSubmit submit={props.submit} setSubmit={props.setSubmit} dataSet={props.dataSet} next={props.next} register={methods.register} getValues={methods.getValues} back={props.back} edit={props.edit}/>
      default:
        return null
    }
  }

  return (
    <FormProvider {...methods} >
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {compo()}
        {/* { props.step == 4 ? <input type="submit" /> : null }   */}
        {/* <input type="submit" /> */}
        {/* <button onClick={()=>props.back()}>Back</button> */}
      </form>
    </FormProvider>
  );
}