import HeroSection from '../LandingPage/heroSection/HeroSection';
import styles from './SignIn.module.scss';
import { HiArrowLeft as LeftArrow } from 'react-icons/hi';
import { Field, Form } from 'react-final-form';
import OutlinedInput from '../../components/common/OutlinedInput/OutlineInput';
import CustomButton from '../SignUp/buttons/ConfirmButton';
import { cutomEmailValidation, reqiredValidation } from '../../components/common/functions/formValidation';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify'
import { setLocalStorageOnLogin } from '../../components/common/functions/utilites';
import { useEffect, useState } from 'react';
import { RestS } from '../../rest/rest-service';
import useAuth from '../../components/Hooks/useAuth';
import useModal from '../../components/Hooks/useModal';
import ModalDialog from '../../components/common/Modal';
// import { Modal } from 'react-boots../../components/common/confirmDialog/ConfirmDialog'
import MainLayout from '../LandingPage/MainLayout';
import { FORM_ERROR } from 'final-form';
import { CommonError } from '../../interfaces/interfaces';
import useAnalyticsEventTracker from '../../components/Layout/useAnalyticsEventTracker';

type Props = {
    title: string;
}

const SignIn = ({ title }) => {
    const navigate = useNavigate();
    const { toggle: modalToggle, visible: modalVisible } = useModal();
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const [auth, setAuth] = useState(useAuth());
    const gaEventTracker = useAnalyticsEventTracker('signin');
    const [searchParams] = useSearchParams()

    useEffect(() => {
        document.title = title || "Merito"
    }, [])

    useEffect(() => {
        if (auth) {
            RestS.verifyJwt().then((res) => {
                setAuth(res);
            }).catch(error => {
                // console.log(error);
            });
        }
    }, []);

    const onSubmit = async (values) => {
        let errors = {
            value: false,
            message: {},
        };
        setLoading(true);
        try {
            const data = await axios.post(`${process.env.REACT_APP_API_URL}/api/integrationpartner/signin`, values).then((res) => res.data);
            if (data) {
                const { data: user, token } = data;

                setLocalStorageOnLogin(token, user);
                gaEventTracker('signin success')
                const redirectUrl = searchParams.get('redirect_uri')
                if (redirectUrl) {
                    window.location.href = redirectUrl
                    return;
                }
                navigate("/");
            }
            setLoading(false);
        } catch (error) {
            gaEventTracker('signin fail')
            // console.log(error);
            setLoading(false);
            const errorData = error.response?.data?.errors as CommonError[];
            if (errorData && errorData.length > 0) {
                const parseError = errorData.reduce((prev, currVal) => {
                    if (currVal.field) {
                        prev[currVal.field] = currVal.message
                    } else {
                        prev['email'] = ' '
                        prev['password'] = ' '
                        prev[FORM_ERROR] = currVal.message;
                    }
                    return prev;
                }, {});

                errors = {
                    value: true,
                    message: parseError
                }
            } else {
                errors = {
                    value: true,
                    message: {
                        [FORM_ERROR]: "Something went wrong"
                    }
                }
            }
        }

        if (errors.value == true) {
            // console.log(errors);
            return errors.message
        }
    }



    return (
        <>
            {auth ?
                <Navigate to="/" replace />
                :
                <MainLayout>

                    <HeroSection />

                    <div className={styles.rightPart}>


                        <div className={styles.signInSection}>
                            <div className={styles.title}>Sign in</div>

                            <Form onSubmit={(values) => onSubmit(values)} render={({ handleSubmit, pristine, invalid, hasValidationErrors, submitError }) => {
                                return <>
                                    <form onSubmit={handleSubmit}>
                                        <div className={styles.formSection}>
                                            <Field name='email' validate={cutomEmailValidation}>
                                                {props => {
                                                    // console.log(props.meta.submitError);
                                                    return <OutlinedInput {...props.input}
                                                        error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null} type='email' value={props.input.value} onChange={props.input.onChange} placeholder="Email Address" />
                                                }}
                                            </Field>

                                            <Field name='password' validate={(values) => reqiredValidation(values, "Password")}>
                                                {props => {
                                                    return (
                                                        <div className={styles.flexField}>
                                                            <OutlinedInput {...props.input} name={props.input.name}
                                                                error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null} type='password' value={props.input.value} onChange={props.input.onChange} placeholder="Password" />
                                                            <div className={styles.forgotPassword} >

                                                                <div style={{ display: "inline-block" }} onClick={() => {
                                                                    gaEventTracker('forgotpassword')
                                                                    navigate('/resetpasswordrequest')
                                                                }}>
                                                                    Forgot Password
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }}
                                            </Field>
                                            {submitError && <div className={styles.formError}>
                                                {submitError}
                                            </div>}
                                            <CustomButton type="submit" loading={loading} disabled={pristine || hasValidationErrors} onClick={handleSubmit}>Sign In</CustomButton>
                                        </div>
                                    </form>

                                </>

                            }} />
                        </div>

                        <div className={styles.bottomTitle}>

                            <div>
                                Don't have an account? <span onClick={() => {
                                    navigate('/signup')
                                }}>Sign Up</span>
                            </div>
                            <div className={styles.resendVerification} onClick={() => {
                                navigate('/verifyemail')
                            }}>
                                Verify Email
                            </div>
                        </div>
                    </div>


                </MainLayout>
            }

            {/* <ModalDialog bodyStyle={{ backgroundColor: "#f0f2f8", borderRadius: "10px", width: "366px" }} visible={modalVisible} toggle={() => {
                modalToggle();
                setErrorMessage(null);
            }}>
                <ConfirmDialogMessage success={false} title={errorMessage} subtitle={""} />
            </ModalDialog> */}


        </>

    );
}

export default SignIn;