import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import OutlinedInput from "../../components/common/OutlinedInput/OutlineInput";
import ConfirmPage from "../ConfirmPages/ConfirmPage";
import HeroSection from "../LandingPage/heroSection/HeroSection";
import CustomButton from "../SignUp/buttons/ConfirmButton";
import styles from './ResetPassword.module.scss';
import axios from 'axios';
import { RestS } from "../../rest/rest-service";
import useAuth from "../../components/Hooks/useAuth";
import useModal from "../../components/Hooks/useModal";
import { Modal } from "react-bootstrap";
import MainLayout from "../LandingPage/MainLayout"
import { FORM_ERROR } from "final-form";
import { CommonError } from "../../interfaces/interfaces";

type Props = {
    title: string;
}

const ResetPassword = ({ title }: Props) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const { userId, token } = useParams();
    const navigate = useNavigate();
    const { toggle: modalToggle, visible: modalVisible } = useModal();
    const [errorMessage, setErrorMessage] = useState(null);

    const [auth, setAuth] = useState(useAuth());

    useEffect(() => {
        document.title = title || "Merito"
        if (auth) {
            RestS.verifyJwt().then((res) => {
                setAuth(res);
            }).catch(error => {
                // console.log(error);
            });
        }
    }, []);

    const onSubmit = async (values) => {
        // console.log(values);
        if (!userId && !token) {
            toast.error("Something went wrong. Try Again")
        }

        let errors = {
            value: false,
            message: {},
        };

        try {
            const data = await axios.post(`${process.env.REACT_APP_API_URL}/api/integrationpartner/resetpassword`, { ...values, userId, token }).then((res) => res.data);
            if (data) {
                // console.log(data);
                setShowConfirm(true);
            }
        } catch (error) {
            // console.log(error);
            const errorData = error.response?.data?.errors as CommonError[];
            if (errorData && errorData.length > 0) {
                const parseError = errorData.reduce((prev, currVal) => {
                    let curField = currVal.field ? currVal.field : FORM_ERROR;
                    prev[curField] = currVal.message
                    return prev;
                }, {});

                errors = {
                    value: true,
                    message: parseError
                }
            } else {
                errors = {
                    value: true,
                    message: {
                        [FORM_ERROR]: "Something went wrong"
                    }
                }
            }
        }

        if (errors.value == true) {
            // console.log(errors);
            return errors.message
        }
    }

    const renderSubtitle = () => {
        return (
            <>

                <div>
                    If you are not redirected automatically
                </div>

                <div style={{ color: "#596DE7", cursor: "pointer" }} onClick={() => {
                    navigate('/signin')
                }}>
                    Sign in here
                </div>
            </>

        )
    }



    return (
        <>
            {auth ?
                <Navigate to="/" replace />
                :

                <MainLayout>
                    <HeroSection />
                    {showConfirm ?

                        <ConfirmPage title={"Password Changed Successfully"} subtitle={renderSubtitle()} />
                        :
                        <div className={styles.rightPart}>
                            <div className={styles.resetPasswordSection}>
                                <div className={styles.title} >Reset Password</div>

                                <Form
                                    validate={values => {
                                        const errors = {};
                                        if (!values.password) {
                                            errors['password'] = 'Required Password';
                                        }
                                        if (values.password && (values.password.length < 4 || values.password.length > 30)) {
                                            errors['password'] = 'Password should consist of minimum 4 characters and maximum 30 characters';
                                        }
                                        if (!values.confirmPassword) {
                                            errors['confirmPassword'] = 'Required Confirm Password';
                                        } else if (values.confirmPassword !== values.password) {
                                            // console.log(values.confirmPassword, values.password)
                                            errors['confirmPassword'] = 'Password confirm does not match'
                                        }

                                        return errors;
                                    }} onSubmit={values => onSubmit(values)} render={({ handleSubmit, pristine, submitError, hasValidationErrors }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className={styles.formSection}>
                                                <Field name='password'>
                                                    {props => {
                                                        return (
                                                            <OutlinedInput {...props.input} name={props.input.name}
                                                                error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null}
                                                                type='password' value={props.input.value} onChange={props.input.onChange} placeholder="Password" />
                                                        )
                                                    }}
                                                </Field>

                                                <Field name='confirmPassword'>
                                                    {props => {
                                                        return <OutlinedInput  {...props.input}
                                                            error={props.meta.error && props.meta.touched ? props.meta.error : props.meta.submitError ? props.meta.submitError : null}
                                                            type='password' value={props.input.value} onChange={props.input.onChange} placeholder="Confirm Password" />
                                                    }}
                                                </Field>
                                                {submitError && <div className={styles.formError}>
                                                    {submitError}
                                                </div>}
                                                <CustomButton type="submit" disabled={pristine || hasValidationErrors} onClick={handleSubmit} >Reset Password</CustomButton>

                                            </div>

                                        </form>
                                    )} />
                            </div>
                        </div>

                    }

                </MainLayout>
            }
        </>

    );

}

export default ResetPassword;